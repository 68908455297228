import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { AuthService } from '../../../services/auth/auth.service';
import { LogoService } from '../../../services/logo/logo.service';
import { GenerateUrlService } from '../../../services/generate-url/generate-url.service';
import { IImage } from '../../../ematic-core-ui/models/image';
import { constants } from '../../../strings/constants';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  @ViewChild('resetPasswordForm', { static: true }) form: NgForm;

  email: string;
  loading: boolean;
  loginState: string = constants.states.LOGIN;
  sent: boolean;

  get platformLogo(): IImage {
    return this.logoService.platformLogo('color');
  }

  get platformText(): string {
    return this.logoService.platformText;
  }

  get corporateUrl(): string {
    return this.generateUrlService.corporateUrl;
  }

  constructor(
    private authService: AuthService,
    private logoService: LogoService,
    private generateUrlService: GenerateUrlService) { }

  ngOnInit() {
    this.loading = false;
    this.sent = false;
  }

  async onResetPassword() {
    try {
      if (!this.form.valid) {
        this.authService.toastr.error(constants.errors.forgot_password.email_invalid, constants.errors.error);
        return;
      }
      this.loading = true;
      await this.authService.postAsync({ email: this.email }, '/forgot-password');
      this.loading = false;
      this.sent = true;
    } catch (error) {
      this.authService.toastr.error(error.message || constants.errors.forgot_password.sending_email, constants.errors.error);
      this.loading = false;
      this.sent = false;
    }
  }
}
