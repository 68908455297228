import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { AsyncApi } from '../../ematic-core-ui/api/async-api';
import { UIProject } from '../../ematic-core-ui/models/ui-project';
import { CacheService } from '../../ematic-core-ui/util/cache.service';
import { AuthService } from '../auth/auth.service';
import { UtilService } from '../../util/util.service';
import { PlatformService } from '../../ematic-core-ui/services/platform/platform.service';

@Injectable()
export class ProductService extends AsyncApi {
  cache: CacheService;
  auth: AuthService;
  utilService: UtilService;

  constructor(
    router: Router,
    http: HttpClient,
    toastr: ToastrService,
    platformService: PlatformService,
    cacheService: CacheService,
    authService: AuthService,
    utilService: UtilService) {
    super(http, router, toastr, '/products', platformService, UIProject.PLATFORM_DASHBOARD);
    this.cache = cacheService;
    this.auth = authService;
    this.utilService = utilService;
  }
}
