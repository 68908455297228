import { Injectable } from '@angular/core';

import { IPlatform, PlatformDomain, PlatformOriginId } from '../../models/platform';
import { constants } from '../../strings/constants';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  private _platform: IPlatform;

  get platformName(): string {
    return this._platform.platformName;
  }

  get platformOriginId(): PlatformOriginId {
    return this._platform.platformOriginId;
  }

  get platformDomain(): PlatformDomain {
    return this._platform.platformDomain;
  }

  get platforms() {
    return <IPlatform[]>constants.PLATFORMS;
  }

  get isPlatformDashboard(): boolean {
    return !!this._platform;
  }

  get loaderUrl(): string {
    return `app/ematic-core-ui/assets/img/platform-loader/${ this.platformOriginId }.gif`;
  }

  get loaderText(): string {
    return `${ this.platformName } Logo`;
  }

  constructor() {
    this.setPlatform();
  }

  setPlatform(): void {
    const url = window.location.origin;
    this._platform = this.platforms.find(({ platformOriginId }) => platformOriginId === this.getPlatformOriginId(url));
  }

  private getPlatformOriginId(url: string): PlatformOriginId {
    if (url.includes('4202')) {
      // Set to 'ematicsolutions' if it's Platform UI localhost
      return 'ematicsolutions';
    } else if (!url.includes('admin')) {
      // Set platform if it's not Admin Tool
      // TODO: Replace with regex
      return <PlatformOriginId>url.substring(url.indexOf('.') + 1, url.lastIndexOf('.'));
    }
  }
}
