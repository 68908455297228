import { Component, Input, OnInit } from '@angular/core';

import { PlatformService } from '../../services/platform/platform.service';

@Component({
  selector: 'es-loader',
  templateUrl: './es-loader.component.html',
  styleUrls: [ './es-loader.component.scss' ]
})
export class EsLoaderComponent {
  @Input() isRelative: boolean;
  @Input() small: boolean;

  get isPlatformDashboard(): boolean {
    return this.platformService.isPlatformDashboard;
  }

  get loaderUrl(): string {
    return this.platformService.loaderUrl;
  }

  get loaderText(): string {
    return this.platformService.loaderText;
  }

  constructor(private platformService: PlatformService) {
    this.isRelative = false;
    this.small = false;
  }
}
