import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AsyncApi } from '../../ematic-core-ui/api/async-api';
import { UIProject } from '../../ematic-core-ui/models/ui-project';
import {
  ICMCreateAccountParams,
  ICMCreateUserParams,
  ICMCountry,
  ICMTimezone,
  ICMGetClientsParams,
  ICMClientModel,
  ICMListModel
} from '../../models/campaign-monitor';
import { IEspAuthParams, ICompleteOAuthParams } from '../../models/esp-params';
import { IEmailPerformanceSubmitData } from '../../models/esp-auth';
import { IntegrationsService } from '../integrations/integrations.service';
import { constants } from '../../strings/constants';
import { environment } from 'environments/environment';
import { PlatformService } from '../../ematic-core-ui/services/platform/platform.service';
import { PlatformOriginId } from '../../ematic-core-ui/models/platform';

@Injectable()
export class CampaignMonitorService extends AsyncApi {
  private _oAuthCode: string;
  clients: any = [];
  lists: any = [];
  espKey: string;
  refreshToken: string;

  listModel: ICMListModel;
  clientModel: ICMClientModel;

  get platformOriginId(): PlatformOriginId {
    return this.platformService.platformOriginId;
  }

  constructor(router: Router,
              http: HttpClient,
              toastr: ToastrService,
              platformService: PlatformService,
              private integrationsService: IntegrationsService) {
    super(http, router, toastr, '/campaign-monitor', platformService, UIProject.PLATFORM_DASHBOARD);
  }

  createAccountAsync(createAccountParams: ICMCreateAccountParams): Promise<string> {
    return this.postAsync(createAccountParams, '/create-account');
  }

  createUserAsync(createUserParams: ICMCreateUserParams): Promise<any> {
    return this.postAsync(createUserParams, '/create-user');
  }

  getCountries(): Observable<ICMCountry[]> {
    return this.get(null, '/get-countries').pipe(
      map(countries => countries.map((country: string, index: number) => ({ id: index, name: country })))
    );
  }

  getTimezones(): Observable<ICMTimezone[]> {
    return this.get(null, '/get-timezones').pipe(
      map(timezones => timezones.map((timezone: string, index: number) => ({ id: index, text: timezone })))
    );
  }

  getClients(getClientsParams: ICMGetClientsParams): Promise<any> {
    return this.postAsync(getClientsParams, `/get-clients/`);
  }

  connect(): void {
    const cm = environment.campaignMonitor;
    const redirect = environment.redirectURI;
    const scope = encodeURIComponent('ViewReports,ViewSubscribersInReports,ManageLists,ImportSubscribers,CreateCampaigns,'
      + 'SendCampaigns,ManageTemplates');
    window.location.href = cm.authorizeURI +
      `?type=web_server&client_id=${ cm.clientId }&redirect_uri=${ redirect }&scope=${ scope }&state=campaignmonitor|${ this.platformOriginId }`;
  }

  getClientsParams(oAuth: boolean): ICMGetClientsParams {
    return <ICMGetClientsParams>{
      espKey: this.espKey,
      oAuth
    };
  }

  getListsParams(clientId: string, oAuth: boolean): IEspAuthParams {
    return <IEspAuthParams>{
      espType: constants.esps.campaignMonitor.type,
      espKey: this.espKey,
      clientId,
      oAuth
    };
  }

  getCompleteOAuthParams(code: string): ICompleteOAuthParams {
    this.espKey = null;
    const cm = environment.campaignMonitor;

    return <ICompleteOAuthParams>{
      espType: constants.esps.campaignMonitor.type,
      clientId: cm.clientId,
      redirectURI: environment.redirectURI,
      code
    };
  }

  getSubmitOAuthParams(instanceId: string, update: boolean): IEmailPerformanceSubmitData {
    return this.integrationsService.getSubmitOAuthESPParams(
      instanceId,
      constants.esps.campaignMonitor.type,
      this.espKey,
      this.listModel.ListID,
      this.refreshToken,
      true,
      update,
      this.clientModel.ClientID);
  }

  setOAuthCode(code: string): void {
    this._oAuthCode = code;
  }

  getOAuthCode(): string {
    const result = this._oAuthCode;
    this.removeOAuthCode();
    return result;
  }

  isOAuthInProgress(): boolean {
    return !!this._oAuthCode;
  }

  removeOAuthCode(): void {
    this._oAuthCode = '';
  }
}
