import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AsyncApi } from '../../ematic-core-ui/api/async-api';
import { IEsMenuItem } from '../../ematic-core-ui/components/es-menu/es-menu-item';
import { EsModalComponent } from '../../ematic-core-ui/components/es-modal/es-modal.component';
import { UserService } from '../user/user.service';
import { AuthService } from '../auth/auth.service';
import { UtilService } from '../../util/util.service';
import { SharedEventsService } from '../../util/shared-events.service';
import { OrganizationService } from '../organization/organization.service';
import { InstanceSelectService } from '../instance-select/instance-select.service';
import { IAccount } from '../../models/account';
import { IUser } from '../../models/user';

import { constants as coreConstants } from '../../ematic-core-ui/strings/constants';
import { constants } from '../../strings/constants';

interface ISettingsMenuItem extends IEsMenuItem {
  isPermitted: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class InternalService {
  isDeletingAccount: boolean;

  constructor(
    private router: Router,
    private authService: AuthService,
    private utilService: UtilService,
    private sharedEventsService: SharedEventsService,
    private organizationService: OrganizationService,
    private instanceSelectService: InstanceSelectService) {
    this.isDeletingAccount = false;
  }

  getSettingsMenu(user: IUser, deleteModal: EsModalComponent, rightMenu = false): IEsMenuItem[] {
    const self = this;
    const settingsArray: ISettingsMenuItem[] = [{
      text: constants.adminTool.text,
      action: () => self.redirectToAdminTool(),
      isPermitted: !UserService.isCustomer(user),
      iconClass: 'icon-external-link'
    }, {
      text: constants.notification.text,
      action: () => self.toggleNotifications(),
      isPermitted: window.innerWidth <= coreConstants.MEDIA_POINTS.SMALL_MOBILE_BREAKPOINT
    }, {
      text: 'Access Rights',
      action: () => self.route(constants.states.ACCESS_RIGHTS, rightMenu),
      isPermitted: !user.allInstancesActive && (user.isSuperAdmin || user.hasAccessRightPermission)
    }, {
      text: 'Delete Account',
      action: () => deleteModal.open(),
      isPermitted: user.customer?.hasAllPermissions
    }, {
      text: 'Help Center',
      action: () => self.route(constants.states.HELP_CENTER, rightMenu),
      isPermitted: true
    }, {
      text: 'Customer Support',
      action: () => self.route(constants.states.CUSTOMER_SUPPORT, rightMenu),
      isPermitted: user.isSuperAdmin
    }, {
      text: 'User Profile',
      action: () => self.route(constants.states.USER_PROFILE, rightMenu),
      isPermitted: !user.isSuperAdmin
    }, {
      text: 'Billing and Subscriptions',
      action: () => self.route(constants.states.BILLING, rightMenu),
      isPermitted: !user.allInstancesActive && (user.isSuperAdmin || user.hasPaymentOptionsPermission)
    }, {
      text: 'Logout',
      action: () => self.onLogout(rightMenu),
      isPermitted: true
    }];

    return settingsArray.filter(item => item.isPermitted);
  }

  toggleNotifications(): void {
    this.sharedEventsService.broadcast(constants.events.TOGGLE_NOTIFICATIONS);
    this.sharedEventsService.broadcast(constants.events.TOGGLE_RIGHT_MENU);
  }

  redirectToAdminTool(): void {
    const { accessToken } = this.authService.getUser();
    const url = this.utilService.getAdminToolBaseURL();
    window.open(AsyncApi.appendQuery(url, { token: accessToken }), '_blank');
  }

  route(newRoute: string, rightMenu: boolean) {
    this.router.navigate([newRoute]);
    if (rightMenu && window.innerWidth < coreConstants.MEDIA_POINTS.TABLET_BREAKPOINT) {
      this.sharedEventsService.broadcast(constants.events.TOGGLE_RIGHT_MENU);
    }
  }

  onLogout(rightMenu: boolean) {
    this.authService.logout();
    this.route(constants.states.LOGIN, rightMenu);
    this.instanceSelectService.currentInstanceIndex = 0;
  }

  async onDeleteAccount(deleteModal: EsModalComponent) {
    const { user } = this.authService.getUser();
    const currentOrganization = this.utilService.getCurrentOrganization(user);
    this.isDeletingAccount = true;
    this.organizationService.deleteOrganization(currentOrganization._id, () => {
      deleteModal.closable = true;
      deleteModal.close();
      this.organizationService.toastr.success(constants.messages.organization_deleted, constants.messages.success);
      this.authService.setUser(<IAccount>{});
      this.router.navigate([constants.states.LOGIN]);
      this.instanceSelectService.currentInstanceIndex = 0;
      this.isDeletingAccount = false;
      localStorage.clear();
    }, () => {
      this.isDeletingAccount = false;
      deleteModal.closable = true;
      deleteModal.close();
      this.organizationService.toastr.error(constants.errors.error_deleting_account, constants.errors.error);
      this.sharedEventsService.broadcast(constants.events.ACCOUNT_DELETION_ERROR);
    });
  }
}
