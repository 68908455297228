import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';

declare var gapi: any;

@Injectable()
export class GapiService {
  private loaded = false;

  constructor() { }

  private async loadClient(): Promise<any> {
    return await new Promise(resolve => {
      gapi.load('client', resolve);
    }).catch((error) => {
      throw error;
    });
  }

  private initClient(): Promise<any> {
    return gapi.client.init({
      client_id: environment.googleClientId,
      scope: 'profile email openid'
    });
  }

  async loadGapi(onSuccess) {
    if (this.loaded) {
      return onSuccess();
    }

    try {
      await this.loadClient();
    } catch (error) {
      throw new Error('gapi is not defined');
    }

    try {
      await this.initClient();
    } catch (error) {
      throw error;
    }

    this.loaded = true;
    onSuccess();
  }
}
