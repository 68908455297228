export const constants = {
  MESSAGES: {
    NEW_VERSION_RELEASED: `The new version of the app is released.
    Please use refresh button, click on the notification or press F5 to reload it.
    Please save all your work before reloading.`,
    NOTICE: 'Notice'
  },
  ERRORS: {
    USERS: {
      INVALID_EMAIL_DOMAIN: 'Cannot be ematicsolutions.com/elixusagency.com email'
    }
  },
  HEADERS: {
    ADMIN_VERSION: 'X-Admin-Version',
    PLATFORM_VERSION: 'X-Platform-Version'
  },
  DATE_FORMAT: {
    YEAR_ONLY: 'YYYY',
    MONTH: 'YYYY/MM',
    GLOBAL: 'YYYY/MM/DD',
    GLOBAL_DASH: 'YYYY-MM-DD',
    DATETIME: 'YYYY/MM/DD HH:mm',
    CHART_AXIS: 'MM/DD h:mm a',
    PRETTY: 'MMMM Do, YYYY',
    WEEK: 'GGGG/WW',
    QUARTER: 'Q/YYYY',
    YEAR_WEEK: 'YYYY-WW'
  },
  LEFT_MENU_WIDTH: 280,
  HEADER_HEIGHT: 60,
  MARGIN_SIZE: 16,
  MEDIA_POINTS: {
    SMALL_MOBILE_BREAKPOINT: 480,
    MOBILE_BREAKPOINT: 600,
    MOBILE_BREAKPOINT_2: 720,
    TABLET_BREAKPOINT: 960,
    LARGE_SCREEN: 1200,
    SXGA: 1280,
    WXGA: 1366,
    HD_PLUS_DISPLAY: 1600,
    FULL_HD: 1920
  },
  PLATFORMS: [
    {
      platformName: 'Amphoria',
      platformOriginId: 'amphoria',
      platformDomain: 'amphoria.io'
    },
    {
      platformName: 'Elixus Agency',
      platformOriginId: 'elixusagency',
      platformDomain: 'elixusagency.com'
    },
    {
      platformName: 'Ematic Solutions',
      platformOriginId: 'ematicsolutions',
      platformDomain: 'ematicsolutions.com'
    },
    {
      platformName: 'Hi-iQ',
      platformOriginId: 'hiiq',
      platformDomain: 'hiiq.ai'
    }
  ]
};
