import { Component, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';

declare let window: any;

@Component({
  selector: 'app-external',
  templateUrl: './external.component.html',
  styleUrls: ['./external.component.scss']
})
export class ExternalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    if (environment.devEnvironment) {
      // window.Intercom('shutdown');
    }
  }
}
