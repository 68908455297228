import { Injectable } from '@angular/core';

import { PlatformService } from '../../ematic-core-ui/services/platform/platform.service';
import { PlatformOriginId } from '../../ematic-core-ui/models/platform';
import { constants } from '../../strings/constants';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GenerateUrlService {

  get platformOriginId(): PlatformOriginId {
    return this.platformService.platformOriginId;
  }

  get baseUrl(): string {
    return environment.baseUrl[this.platformOriginId];
  }

  get corporateUrl(): string {
    return constants.urls.corporate_site[this.platformOriginId];
  }

  constructor(private platformService: PlatformService) { }
}
