import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { AsyncApi } from '../../ematic-core-ui/api/async-api';
import { UIProject } from '../../ematic-core-ui/models/ui-project';
import { IInstance } from '../../models/instance';
import { IEmailPerformanceSubmitData } from '../../models/esp-auth';
import { IEspData } from '../../models/esp';
import { IAccountInfo, IGaState, IInstanceGaStatus } from '../../models/integrations';
import { constants } from '../../strings/constants';
import { PlatformService } from '../../ematic-core-ui/services/platform/platform.service';

@Injectable()
export class IntegrationsService extends AsyncApi {

  constructor(
    router: Router,
    http: HttpClient,
    toastr: ToastrService,
    platformService: PlatformService) {
    super(http, router, toastr, '/analytics', platformService, UIProject.PLATFORM_DASHBOARD);
  }

  async getGaStatusAsync(instance): Promise<IGaState> {
    try {
      const params = {
        ematicInstanceId: instance.ematicInstanceId,
        ematicInstanceIds: instance.ematicInstanceIds || [instance.ematicInstanceId]
      };
      return await this.postAsync(params, '/ga-status');
    } catch (error) {
      throw error;
    }
  }

  async getAccountInfo(instance: IInstance): Promise<IAccountInfo> {
    try {
      const params = {
        ematicInstanceIds: instance.ematicInstanceIds || [instance.ematicInstanceId]
      };
      return await this.postAsync(params, '/get-account-info');
    } catch (error) {
      throw error;
    }
  }

  async getMediums(params) {
    try {
      return await this.postAsync(params, '/get-ga-mediums');
    } catch (error) {
      throw error;
    }
  }

  storeAuthCode({ authResult, instanceId }) {
    return this.post({ authResult, instanceId });
  }

  updateAccountInfo(params) {
    return this.putAsync(params);
  }

  async updateAccountInfoData(params) {
    try {
      return await this.postAsync(params, '/update-account-info');
    } catch (error) {
      throw error;
    }
  }

  getSubmitOAuthESPParams(instanceId: string, espType: string, espKey: string, listId: string, refreshToken?: string,
    oAuth?: boolean, updateOAuth?: boolean, clientId?: string): IEmailPerformanceSubmitData {
    const params: IEmailPerformanceSubmitData = {
      instanceId,

      espType,
      espKey,
      listId,

      oAuth: oAuth || false
    };

    if (espType === constants.esps.campaignMonitor.type) {
      params.clientId = clientId;
      params.refreshToken = refreshToken;
      params.updateOAuth = updateOAuth;
    }

    return params;
  }

  getSubmitManualEspParams(instanceId: string, espData: IEspData): IEmailPerformanceSubmitData {
    const params = <IEmailPerformanceSubmitData>{
      instanceId,
      espType: espData.type
    };
    espData.fields.forEach(field => params[field.key] = field.value);
    params.espKey = params['key'];
    return params;
  }

  getAdditionalMediums(allMediums: string[]): string[] {
    const defaultMediums = ['email', 'edm', 'mail'];
    return allMediums.filter((medium: string) => {
      return defaultMediums.indexOf(medium.toLowerCase()) === -1;
    });
  }

  async getInstanceGaStatus(instance: IInstance): Promise<IInstanceGaStatus> {
    try {
      const { isActive, generating } = await this.getGaStatusAsync(instance);
      return {
        ematicInstanceId: instance.ematicInstanceId,
        isGaActive: isActive,
        isGaGenerating: generating
      };
    } catch (error) {
      return {
        ematicInstanceId: instance.ematicInstanceId,
        isGaActive: false,
        isGaGenerating: false
      };
    }
  }
}
