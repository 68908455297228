import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { AsyncApi } from '../../ematic-core-ui/api/async-api';
import { UIProject } from '../../ematic-core-ui/models/ui-project';
import { IBillingAddressParams, IOrganization } from '../../models/organization';
import { IPaymentInfoParams, IPaymentInfo } from '../../models/payment-info';
import { PlatformService } from '../../ematic-core-ui/services/platform/platform.service';

@Injectable()
export class PaymentService extends AsyncApi {

  constructor(
    router: Router,
    http: HttpClient,
    toastr: ToastrService,
    platformService: PlatformService) {
    super(http, router, toastr, '/payments', platformService, UIProject.PLATFORM_DASHBOARD);
  }

  getPaymentInfo(organizationId: string): Promise<IPaymentInfo> {
    return this.getAsync(null, `/${organizationId}`);
  }

  addPaymentInfoAsync(addPaymentInfoParams: IPaymentInfoParams): Promise<any> {
    return this.postAsync(addPaymentInfoParams);
  }

  addBillingAddress(addBillingAddressParams: IBillingAddressParams): Promise<IOrganization> {
    return this.postAsync(addBillingAddressParams, '/billing-address');
  }

  async removePaymentInfoAsync(organizationId: string): Promise<void> {
    await this.deleteAsync(`/${organizationId}`);
  }
}
