import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import { Observable, of } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';

import { UserService } from '../../../services/user/user.service';
import { AuthService } from '../../../services/auth/auth.service';
import { CacheService } from '../../../ematic-core-ui/util/cache.service';
import { CommonUtil } from '../../../ematic-core-ui/util/common-util';
import { fadeIn } from '../../../ematic-core-ui/util/animations';
import { IImage } from '../../../ematic-core-ui/models/image';
import { IUser } from '../../../models/user';
import { IAccount } from '../../../models/account';
import { ICountry } from '../../../models/country';
import { GoogleAnalyticsService } from '../../../services/google/google-analytics.service';
import { LogoService } from '../../../services/logo/logo.service';
import { GenerateUrlService } from '../../../services/generate-url/generate-url.service';
import { PlatformService } from '../../../ematic-core-ui/services/platform/platform.service';
import { PlatformOriginId } from '../../../ematic-core-ui/models/platform';
import { constants } from '../../../strings/constants';
import { messages } from '../../../strings/messages';

@Component({
  selector: 'register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    fadeIn
  ]
})
export class RegisterComponent implements OnInit {

  @ViewChild('form') form: NgForm;
  onTimezonesQuery: () => Observable<any>;
  onCountriesQuery: () => Observable<any>;

  countryDisplayWith: (item: ICountry) => string;

  timezone: any;
  terms: boolean;
  user: IUser;
  loading: boolean;
  tokenLoading: boolean;
  company: string;
  onboarding = false;

  countries: ICountry[] = [];
  country: ICountry = <ICountry>{};
  phone: string;

  passwordInvalidMessage: string;
  loginState: string = constants.states.LOGIN;

  get platformName(): string {
    return this.platformService.platformName;
  }

  get platformOriginId(): PlatformOriginId {
    return this.platformService.platformOriginId;
  }

  get platformLogo(): IImage {
    return this.logoService.platformLogo('color');
  }

  get platformText(): string {
    return this.logoService.platformText;
  }

  get corporateUrl(): string {
    return this.generateUrlService.corporateUrl;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private cacheService: CacheService,
    private userService: UserService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private logoService: LogoService,
    private generateUrlService: GenerateUrlService,
    private platformService: PlatformService) {
    this.user = <IUser>{};
    this.passwordInvalidMessage = messages.VALIDATOR.PASSWORD_INVALID;
  }

  ngOnInit() {
    this.loading = false;
    this.authService.setUser(<IAccount>{});
    this.loadData();
  }

  loadData() {
    this.tokenLoading = true;
    this.route.params.pipe(
      switchMap((params: Params) => this.authService.validate(params['inviteToken']))
    ).subscribe({
      next: async result => {
        try {
          const countryCode = await this.loadGeoData();
          await this.loadCountries(countryCode);

          if (result.user) {
            this.user = <IUser>{ ...result.user, ...this.user };
          } else {
            this.onboarding = true;
          }
          this.tokenLoading = false;
        } catch (error) {
          this.handleLoadingDataError(error);
        }
      },
      error: (error) => {
        this.handleLoadingDataError(error);
      }
    });

    this.onTimezonesQuery = CommonUtil.getTimezones.bind(CommonUtil);
    this.onCountriesQuery = () => of(this.countries).pipe(delay(10));

    this.countryDisplayWith = (item: ICountry) => item.phoneCode ? `${ item.name } (${ item.phoneCode })` : item.name;
  }

  async loadGeoData() {
    let countryCode = null;
    try {
      const data = await this.userService.queryIpData();
      this.user.country = {};
      if (data) {
        this.user.country = { name: data.country_name, state: data.region_name, city: data.city };
        this.timezone = CommonUtil.getClientTimezone(data.time_zone);
        countryCode = data.country_code || null;
      }
    } catch (error) {
      throw new Error(constants.errors.registration.geolocation_unavailable);
    }
    return countryCode;
  }

  async loadCountries(countryCode) {
    this.countries = await this.userService.queryCountries();
    this.country = this.countries.find(item => item.id === countryCode);
  }

  sendGaEvent() {
    const action = this.onboarding ? 'Onboarding registration completed' : 'Non-onboarding registration completed';
    const label = this.onboarding ? constants.gaEvent.labels.onboarding : constants.gaEvent.labels.accessRights;
    this.googleAnalyticsService.sendEvent(constants.gaEvent.categories.submit, action, label);
  }

  register() {
    if (this.user.password !== this.user.confirm) {
      this.authService.toastr.error(constants.errors.password_mismatch, constants.errors.error);
    } else {
      const phoneCode = this.country ? this.country.phoneCode : '';
      this.loading = true;
      this.user.timezone = this.timezone.id;
      this.user.acceptedTerms = this.terms ? '1.0' : '';
      this.user.phone = this.phone ? `${ phoneCode }${ this.phone }` : '';
      this.user.country = this.country;
      this.route.params.pipe(
        switchMap(
          (params: Params) => this.authService.submitRegistration(
            this.user, params['inviteToken'],
            this.company,
            this.platformOriginId
          )
        )
      ).subscribe({
        next: async (userResponse: any) => {
          await this.authService.setUser(userResponse);
          this.cacheService.clearAll();
          this.authService.toastr.success(constants.messages.registration_successful, constants.messages.success);
          this.sendGaEvent();
          this.router.navigate([constants.states.DASHBOARD]);
        },
        error: error => {
          this.authService.toastr.error(error?.error?.message
            ? error.error.message
            : constants.errors.registration.unsuccessful,
            constants.errors.error
          );
          this.loading = false;
        }
      });
    }
  }

  handleLoadingDataError(error): void {
    this.tokenLoading = false;
    this.userService.toastr.error(error?.message || constants.errors.error_occurred, constants.errors.error);
    this.router.navigate(['/login']);
  }
}
