import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { ToastrService } from 'ngx-toastr';

import { UIProject } from '../../ematic-core-ui/models/ui-project';
import { AsyncApi } from '../../ematic-core-ui/api/async-api';
import { PlatformService } from '../../ematic-core-ui/services/platform/platform.service';
import { EspAuthService } from '../esp-auth/esp-auth.service';
import { IInstance } from '../../models/instance';

@Injectable()
export class OptimonkService extends AsyncApi {

  public apiKey: string;

  constructor(
    router: Router,
    http: HttpClient,
    toastr: ToastrService,
    platformService: PlatformService,
    private espAuthService: EspAuthService) {
    super(http, router, toastr, '/optimonk', platformService, UIProject.PLATFORM_DASHBOARD);
  }

  async saveAccount(instanceId: string): Promise<IInstance> {
    if (this.apiKey) {
      try {
        const instance = await this.espAuthService.saveOptiMonkAccount({
          apiKey: this.apiKey,
          instanceId: instanceId
        });
        this.clearAPIKey();
        return instance;
      } catch (error) {
        this.clearAPIKey();
        this.espAuthService.toastr.error(error.message, error.name);
      }
    }
  }

  private clearAPIKey(): void {
    this.apiKey = null;
  }
}
