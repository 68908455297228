import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SharedEventsService {
  // Observable sharedEvent source
  private _sharedEventSource = new Subject<string>();
  // Observable sharedEvent stream
  sharedEvent$ = this._sharedEventSource.asObservable();
  // service command
  broadcast(string) {
    this._sharedEventSource.next(string);
  }

}
