import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

import { UIProject } from '../../ematic-core-ui/models/ui-project';
import { AsyncApi } from '../../ematic-core-ui/api/async-api';
import { IEmailPerformanceSubmitData } from '../../models/esp-auth';
import { ICompleteOAuthParams, IEspAuthParams } from '../../models/esp-params';
import { IFacebookSaveAccount } from '../../models/facebook-oauth';
import { IOptiMonkSaveAccount } from '../../models/optimonk';
import { PlatformService } from '../../ematic-core-ui/services/platform/platform.service';

@Injectable({
  providedIn: 'root'
})
export class EspAuthService extends AsyncApi {

  constructor(
    router: Router,
    http: HttpClient,
    toastr: ToastrService,
    platformService: PlatformService) {
    super(http, router, toastr, '/esp-auth', platformService, UIProject.PLATFORM_DASHBOARD);
  }

  async getLists(getListsParams: IEspAuthParams) {
    return await this.postAsync(getListsParams, `/get-lists`);
  }

  async submitEspData(submitData: IEmailPerformanceSubmitData) {
    return await this.postAsync(submitData, '/submit');
  }

  async completeOAuth(completeOAuthParams: ICompleteOAuthParams) {
    return await this.postAsync(completeOAuthParams, '/complete-oauth');
  }

  async saveFacebookAccount(saveFacebookAccountParams: IFacebookSaveAccount) {
    return await this.postAsync(saveFacebookAccountParams, '/save-facebook-account');
  }

  async saveOptiMonkAccount(saveOptiMonkAccountParams: IOptiMonkSaveAccount) {
    return await this.postAsync(saveOptiMonkAccountParams, '/save-optimonk-account');
  }
}
