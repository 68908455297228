export const messages = {
  DASHBOARD: {
    NOTIFICATIONS: {
      ACROSS_ALL: 'Dashboard tab is disabled while \'Across All Instances\' is selected.'
      // TODO: Uncomment when ROI ready (EMATIC-2805)
      // ACROSS_ALL: 'ROI Dashboard tab is disabled while \'Across All Instances\' is selected.'
    }
  },
  TREND_ANALYSIS: {
    FACEBOOK: {
      OLD_DATA_NOT_AVAILABLE: 'Facebook data older than three years are not available.'
    }
  },
  MY_PRODUCTS: {
    dataInfo: 'You are currently seeing last 30 days data vs previous 30 days data',
    noData: 'There is no data available for this period.'
  },
  HI_IQ: {
    CAMPAIGNS: {
      NO_CAMPAIGN_DATA: `Engaged and non-engaged data for this
      campaign wasn't received in the last Hi-iQ run. Next sync estimated within 24 hours.`,
      CAMPAIGN_BEFORE_FIRST_RUN: 'No Hi-iQ data. Campaign has been sent before Hi-iQ\'s first run.'
    },
    NOTIFICATIONS: {
      ACROSS_ALL: 'Hi-iQ Run Stats, Rehab Attempts and Breakdown tab are disabled while \'Across All Instances\' is selected.'
    }
  },
  BYE_IQ: {
    NOTIFICATIONS: {
      ACROSS_ALL: 'Bye-iQ Experiment Data tab is disabled while \'Across All Instances\' is selected.'
    }
  },
  RETRY_IQ: {
    NOTIFICATIONS: {
      ACROSS_ALL: 'Retry-iQ AB Test Data tab is disabled while \'Across All Instances\' is selected.'
    }
  },
  ESPS: {
    CAMPAIGN_MONITOR: {
      REDIRECT_TO_LOGIN: `Just sent a campaign? Great! Don't forget to check its performance here on the Ematic Dashboard.
        *These are not the same as your Campaign Monitor credentials. Contact our Customer Success Team for initial setup.`
    }
  },
  VALIDATOR: {
    PASSWORD_INVALID: 'Password must contain lower case, upper case, number and minimum 8 characters.',
    ALLOWED_VALUES: {
      HI_IQ_ACCOUNT_SETTINGS: {
        PERCENTAGE: 'Value must be between 0 and 100.',
        ENGAGED_CRITERIA: 'Value must be between 0 and 1080.'
      }
    }
  }
};
