import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AsyncApi } from '../../../ematic-core-ui/api/async-api';
import { AuthService } from '../../../services/auth/auth.service';
import { GapiService } from '../../../services/google/gapi.service';
import { CacheService } from '../../../ematic-core-ui/util/cache.service';
import { PermissionsService } from '../../../services/auth/permissions.service';
import { InstanceSelectService } from '../../../services/instance-select/instance-select.service';
import { OnboardService } from '../../../services/onboard/onboard.service';
import { LogoService } from '../../../services/logo/logo.service';
import { GenerateUrlService } from '../../../services/generate-url/generate-url.service';
import { IImage } from '../../../ematic-core-ui/models/image';

import { constants } from '../../../strings/constants';
import { messages } from '../../../strings/messages';

declare var gapi: any;

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  username: string;
  password: string;
  loading: boolean;
  gapiLoaded = false;
  redirectedFromCM: boolean;
  cmRedirectMessage = messages.ESPS.CAMPAIGN_MONITOR.REDIRECT_TO_LOGIN;

  get platformLogo(): IImage {
    return this.logoService.platformLogo('color');
  }

  get platformText(): string {
    return this.logoService.platformText;
  }

  get corporateUrl(): string {
    return this.generateUrlService.corporateUrl;
  }

  constructor(private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private gapiService: GapiService,
    private cacheService: CacheService,
    private zone: NgZone,
    private permissionsService: PermissionsService,
    private instanceSelectService: InstanceSelectService,
    private onboardService: OnboardService,
    private logoService: LogoService,
    private generateUrlService: GenerateUrlService) { }

  ngOnInit() {
    const routeParams = this.route.params;
    this.redirectedFromCM = routeParams && routeParams['value'] && routeParams['value']['external-url'] === 'cm';
    this.loading = false;

    setTimeout(async () => {
      await this.loadGAAPI();
    }, 300);
  }

  async loadGAAPI() {
    try {
      await this.gapiService.loadGapi(() => {
        this.attachSignin(document.getElementById('google-button'));
        this.gapiLoaded = true;
      });
    } catch (error) {
      if (error.error === constants.errors.analytics.cookies_error
          && error.details === constants.errors.analytics.cookies_details) {
        this.authService.toastr.warning(constants.errors.analytics.ga_disabled);
      } else if (error.message === 'gapi is not defined') {
        this.authService.toastr.warning(constants.errors.analytics.gapi_undefined);
      } else {
        this.authService.toastr.error(constants.errors.analytics.generic, constants.errors.error);
      }
      this.gapiLoaded = false;
    }
  }

  attachSignin(element) {
    gapi.auth2.getAuthInstance().attachClickHandler(element, {}, googleUser => {
      this.zone.run(() => {
        this.onLogin('google', googleUser.getBasicProfile().getEmail(), googleUser.getAuthResponse().id_token);
      });
    });
  }

  getLoginParams(type: string, email: string, idToken: string) {
    const params: any = { rememberMe: true };
    if (type === 'regular') {
      params.email = this.username;
      params.password = this.password;
    } else {
      params.email = email;
      params.password = idToken;
      params.googleLogin = true;
    }
    return params;
  }

  async onLogin(type: string, email?: string, idToken?: string) {
    try {
      const params = this.getLoginParams(type, email, idToken);
      if (!params.email || !params.password) {
        this.authService.toastr.error(constants.errors.missing_email_password, constants.errors.error);
      } else {
        this.loading = true;
        const loginData = await this.authService.postAsync(params, '/login', true);
        await this.authService.setUser(loginData);
        localStorage.setItem(constants.localStorageKeys.showGaModal, 'true');
        const { user } = this.authService.getUser();
        this.cacheService.clearAll();
        this.onboardService.loadCurrentState();
        let state;
        if (AsyncApi.redirectTo) {
          state = AsyncApi.redirectTo;
          delete AsyncApi.redirectTo;
        } else {
          state = this.permissionsService.getRouteForPermission(user);
        }
        this.router.navigate([state]);
        this.instanceSelectService.updateCurrentInstanceIndex();
      }
    } catch (error) {
      const errorName = ['User', 'Organization'].indexOf(error.name) !== -1 ? `${error.name} ${constants.errors.error}` : error.name;
      this.authService.toastr.error(error.message, errorName);
      this.loading = false;
    }
  }
}
