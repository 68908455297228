import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// components
import { LoginComponent } from './views/external/login/login.component';
import { UnauthorizedComponent } from './views/external/unauthorized/unauthorized.component';
import { PageNotFoundComponent } from './views/external/page-not-found/page-not-found.component';
import { ForgotPasswordComponent } from './views/external/forgot-password/forgot-password.component';
import { RegisterComponent } from './views/external/register/register.component';
import { ResetPasswordComponent } from './views/external/reset-password/reset-password.component';
import { CreateAccountComponent } from './views/external/create-account/create-account.component';

import { AuthGuard } from './services/auth/auth-guard.service';
import { constants } from './strings/constants';

import { environment } from '../environments/environment';

const appRoutes: Routes = [
   {
    path: '',
    redirectTo: constants.states.DASHBOARD,
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./views/internal/internal.module').then(m => m.InternalModule),
    canLoad: [AuthGuard]
  },
  { path: 'login',                 component: LoginComponent           },
  { path: 'login/:external-url',   component: LoginComponent           },
  { path: 'unauthorized',          component: UnauthorizedComponent    },
  { path: 'forgot-password',       component: ForgotPasswordComponent  },
  { path: 'register/:inviteToken', component: RegisterComponent        },
  { path: 'reset/:resetToken',     component: ResetPasswordComponent   },
  { path: 'create-account',        component: CreateAccountComponent   },
  { path: '**',                    component: PageNotFoundComponent    } // has to be last route, otherwise it would mask below routes
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { useHash: environment.useHash })
  ],
  exports: [
    RouterModule
  ],
  providers: [
    AuthGuard
  ]
})
export class AppRoutingModule {}
