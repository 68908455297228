<div class="loader-center hexagon-loader"
     [class.small]="small"
     [style.position]="isRelative ? 'relative' : 'absolute'">
  <ng-container *ngIf="!isPlatformDashboard">
    <img class="logo loader-center" src="app/ematic-core-ui/assets/img/ematic-icon.svg" alt="Ematic Logo">
    <svg class="hexagon-container" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 129.78 150.37">
      <path class="hexagon-base"
            d="M-1665.43,90.94V35.83a15.09,15.09,0,0,1,6.78-12.59l48.22-31.83a15.09,15.09,0,0,1,16-.38L-1547,19.13a15.09,15.09,0,0,1,7.39,13V90.94a15.09,15.09,0,0,1-7.21,12.87l-47.8,29.24a15.09,15.09,0,0,1-15.75,0l-47.8-29.24A15.09,15.09,0,0,1-1665.43,90.94Z"
            transform="translate(1667.43 13.09)"/>
      <path class="hexagon-line-animation"
            d="M-1665.43,90.94V35.83a15.09,15.09,0,0,1,6.78-12.59l48.22-31.83a15.09,15.09,0,0,1,16-.38L-1547,19.13a15.09,15.09,0,0,1,7.39,13V90.94a15.09,15.09,0,0,1-7.21,12.87l-47.8,29.24a15.09,15.09,0,0,1-15.75,0l-47.8-29.24A15.09,15.09,0,0,1-1665.43,90.94Z"
            transform="translate(1667.43 13.09)"/>
    </svg>
  </ng-container>
  <ng-container *ngIf="isPlatformDashboard">
    <img class="loader-center" [src]="loaderUrl" [alt]="loaderText">
  </ng-container>
</div>
