import { Injectable, SimpleChanges } from '@angular/core';

import * as moment from 'moment';
import { isEqual, mapKeys } from 'lodash-es';

import { EsRange } from '../../ematic-core-ui/components/es-range-picker/es-range';
import { CommonUtil } from '../../ematic-core-ui/util/common-util';
import { IEsDataTableColumn } from '../../ematic-core-ui/components/es-data-table/es-data-table-column';
import { IHiIqCampaignsDataFilter } from '../../components/hi-iq-campaigns-data-filter/hi-iq-campaigns-data-filter.component';
import { IBaseReportParams, IDailyDataParams, IHiIqReportParams, IMyProductDataParams, IReportParams } from '../../models/report-params';
import { AuthService } from '../auth/auth.service';
import { UtilService } from '../../util/util.service';
import { ReportCacheService } from './report-cache.service';

import { constants } from '../../strings/constants';

export interface ISetDefaultDatesParams {
  start?: string;
  end?: string;
}

@Injectable()
export class ReportUtil {
  constructor(
    private authService: AuthService,
    private utilService: UtilService,
    private reportCacheService: ReportCacheService
  ) {
  }

  getInstanceIds() {
    const user = this.authService.getUser().user;
    const instance = this.utilService.getCurrentInstance(user);

    return instance._id === 'all' ? instance.ematicInstanceIds : [instance.ematicInstanceId];
  }

  getRequestParams(ematicInstanceIds: string[], type: string, dateRange: EsRange): IReportParams | IHiIqReportParams {
    return <IReportParams>{
      start: this.utilService.getRequestTimestamp(dateRange.startDate, true),
      end: this.utilService.getRequestTimestamp(dateRange.endDate, false),
      ematicInstanceIds: ematicInstanceIds,
      productType: type
    };
  }

  /**
   * Hi-IQ CAMPAIGNS methods
   */

  getCampaignDataValues(item: any) {
    const labels = {
      total: ['Sent', 'Open', 'Clicks'],
      hiIq: ['Hi-iQ Sent', 'Hi-iQ Open', 'Hi-iQ Click'],
      control: ['CG Sent', 'CG Open', 'CG Click']
    };

    let total: any[], engaged: any[], nonEngaged: any[];

    total = [{
      label: 'Total',
      campaignsData: [
        this.getCampaignDataValueArray(item, 'total', labels.total, 'total'),
        this.getCampaignDataValueArray(item, 'hiiq', labels.hiIq, 'total'),
        this.getCampaignDataValueArray(item, 'control', labels.control, 'total')
      ]
    }];

    engaged = [{
      label: 'Engaged',
      campaignsData: [
        this.getCampaignDataValueArray(item, 'total', labels.total, 'engaged'),
        this.getCampaignDataValueArray(item, 'hiiq', labels.hiIq, 'engaged'),
        this.getCampaignDataValueArray(item, 'control', labels.control, 'engaged')
      ]
    }];

    nonEngaged = [{
      label: 'Non Engaged',
      campaignsData: [
        this.getCampaignDataValueArray(item, 'total', labels.total, 'nonengaged'),
        this.getCampaignDataValueArray(item, 'hiiq', labels.hiIq, 'nonengaged'),
        this.getCampaignDataValueArray(item, 'control', labels.control, 'nonengaged')
      ]
    }];

    return [...total, ...engaged, ...nonEngaged];
  }

  getGaDataValues(data): any[][] {
    return [
      [{ value: data.revenue || 0, label: 'Revenue' }],
      [{ value: data.transactions || 0, label: 'Conversions' }]
    ];
  }

  getCampaignDataValueArray(data, prop, labels, actualValueType) {
    return {
      labels,
      sends: {
        value: data[prop][actualValueType].send
      },
      opens: {
        value: data[prop][actualValueType].open,
        percent: CommonUtil.formatPercentage(data[prop][actualValueType].openRate)
      },
      clicks: {
        value: data[prop][actualValueType].click,
        percent: CommonUtil.formatPercentage(data[prop][actualValueType].clickRate)
      }
    };
  }

  formatHiIqCampaignData(data: any, isGaActive: boolean, allInstancesSelected: boolean, formatForExport?: boolean): any[] {
    return data.map(item => {
      const dateLabel = moment(item.sendTime).format(constants.dateFormat.DATETIME);
      const gaAggregateData = (item.gaData && item.gaData.aggregate) ? item.gaData.aggregate : {};

      return {
        labels: { title: item.title, subtitles: [dateLabel], hiIqTargetCorrect: item['hiiq_target_correct'] },
        values: {
          data: {
            [constants.hiIqCampaignsData.all.id]: this.getCampaignDataValues(item),
            [constants.hiIqCampaignsData.used.id]: this.getCampaignDataValues(item['hiiq_used']),
            [constants.hiIqCampaignsData.unused.id]: this.getCampaignDataValues(item['hiiq_unused'])
          },
          gaData: formatForExport ? this.getGaDataValues(gaAggregateData) : []
        },
        type: item.type || '',
        sentBeforeHiIqStartDate: item.sentBeforeHiiQStartDate || false,
        revenue: !isGaActive ? null : gaAggregateData.revenue || '0',
        currency: item.currency || '',
        transactions: !isGaActive ? null : gaAggregateData.transactions || '0',
        instanceName: allInstancesSelected ? item.instance : null
      };
    });
  }

  formatHiIqCampaignExportData(data: any, isGaActive: boolean, allInstancesSelected: boolean) {
    return (<any>this.formatHiIqCampaignData(data, isGaActive, allInstancesSelected, true).map(item => {
      const filters: IHiIqCampaignsDataFilter[] = Object.values(constants.hiIqCampaignsData);
      const results = [];

      filters.forEach(filter => {
        const result = {};

        if (allInstancesSelected) {
          result['Instance'] = item.instanceName;
        }

        result['Title'] = item.labels.title;
        result['Date'] = item.labels.subtitles[0];

        if (isGaActive) {
          item.values.gaData.map((value: any) => {
            const currency = item.currency ? item.currency : '';
            result[value[0].label] = currency + ' ' + CommonUtil.formatNumber(value[0].value, 2);
          });
        }

        result['Filter'] = filter.text;

        item.values.data[filter.id].flatMap((value: any) => {
          value.campaignsData.map((campaign: any) => {
            result[`${ value.label } ${ campaign.labels[0] }`] = campaign.sends.value;
            result[`${ value.label } ${ campaign.labels[1] }`] = campaign.opens.value;
            result[`${ value.label } ${ campaign.labels[1] } Rate`] = campaign.opens.percent;
            result[`${ value.label } ${ campaign.labels[2] }`] = campaign.clicks.value;
            result[`${ value.label } ${ campaign.labels[2] } Rate`] = campaign.clicks.percent;
          });
        });

        results.push(result);
      });

      return results;
    })).flatMap(result => result);
  }

  /**
   * Hi-IQ REHAB methods
   */

  formatHiIqRehabData(data: any): any[] {
    let stats;
    let control;
    let lift;
    let liftOpens;
    let liftClicks;

    let returnValue = [];

    return data.map(item => {
      stats = (item.stats && !item.stats.message) ? item.stats : {};
      control = (item.control_stats && !item.control_stats.message) ? item.control_stats : {};
      lift = item.lift;

      returnValue = [
        [
          { value: CommonUtil.formatNumber(item.rehab_attempts) || 0, label: 'Hi-iQ Rehab' },
          { value: CommonUtil.formatNumber(stats.sends) || 0, label: 'Sends' },
          { value: CommonUtil.formatNumber(stats.opens) || 0, label: 'Opens' },
          { value: CommonUtil.formatNumber(stats.clicks) || 0, label: 'Clicks' },
          { value: CommonUtil.formatPercentage(stats.openRate) || 0, label: 'Open Rate' },
          { value: CommonUtil.formatPercentage(stats.clickRate) || 0, label: 'Click Rate' }
        ],
        [
          { value: CommonUtil.formatNumber(item.rehab_attempts) || 0, label: 'Control Rehab' },
          { value: CommonUtil.formatNumber(control.sends) || 0, label: 'Sends' },
          { value: CommonUtil.formatNumber(control.opens) || 0, label: 'Opens' },
          { value: CommonUtil.formatNumber(control.clicks) || 0, label: 'Clicks' },
          { value: CommonUtil.formatPercentage(control.openRate) || 0, label: 'Open Rate' },
          { value: CommonUtil.formatPercentage(control.clickRate) || 0, label: 'Click Rate' }
        ]
      ];

      if (lift) {
        liftOpens = typeof lift.opens === 'number' && lift.opens !== constants.notAvailable
          ? CommonUtil.formatPercentage(lift.opens)
          : lift.opens;
        liftClicks = typeof lift.clicks === 'number' && lift.clicks !== constants.notAvailable
          ? CommonUtil.formatPercentage(lift.clicks)
          : lift.clicks;

        returnValue = [
          ...returnValue,
          [
            { value: liftOpens || 0, label: 'Opens', exportLabel: 'Lift in Opens' },
            { value: liftClicks || 0, label: 'Clicks', exportLabel: 'Lift in Clicks' }
          ]
        ];
      }

      return returnValue;
    });
  }

  /**
   * Clear report cache check methods
   */

  shouldClearReportCache(changes: SimpleChanges, cacheName: string): boolean {
    const dateChange = changes.dateRange && changes.dateRange.currentValue
      && !this.reportCacheService.getCache(cacheName, changes.dateRange.currentValue);

    const organizationChange = changes.reportParams && changes.reportParams.previousValue && changes.reportParams.currentValue
      && changes.reportParams.previousValue.organizationId !== changes.reportParams.currentValue.organizationId;

    const instanceChange = changes.reportParams && changes.reportParams.previousValue && changes.reportParams.currentValue
      && isEqual(changes.reportParams.previousValue.instanceIds, changes.reportParams.currentValue.instanceIds);

    return dateChange || organizationChange || instanceChange;
  }

  formatEmaticStartDate(date: string, dataPeriod: string) {
    return moment(date, dataPeriod === 'week' ? constants.dateFormat.WEEK
      : constants.dateFormat.GLOBAL_DASH).format(constants.dateFormat.GLOBAL_DASH);
  }

  getDateFormat(dataPeriod: string) {
    if (dataPeriod === 'week' || dataPeriod === 'weekly') {
      return constants.dateFormat.YEAR_WEEK;
    } else if (dataPeriod === 'month' || dataPeriod === 'monthly') {
      return constants.dateFormat.YEAR_MONTH;
    } else if (dataPeriod === 'quarter' || dataPeriod === 'quarterly') {
      return constants.dateFormat.YEAR_QUARTER;
    } else {
      return constants.dateFormat.YEAR_ONLY;
    }
  }

  formatDateSimple(dataPeriod: string, date: string) {
    const isMonth = dataPeriod === 'month' || dataPeriod === 'monthly';
    const isWeek = dataPeriod === 'week' || dataPeriod === 'weekly';
    const isYear = dataPeriod === 'year' || dataPeriod === 'yearly';
    const isQuarter = dataPeriod === 'quarter' || dataPeriod === 'quarterly';
    const dateFormat = this.getDateFormat(dataPeriod);

    if (!moment(date, dateFormat).isValid()) {
      return date;
    }

    if (isYear && date) {
      return date.length && date.length === 4 ? date : moment(date).format(constants.dateFormat.YEAR_ONLY);
    }

    if (isQuarter) {
      return this.utilService.getDateFromPeriod(date, true);
    }

    if (isMonth || isWeek) {
      return this.utilService.getDateFromPeriod(date);
    }

    return moment(date).format(constants.dateFormat.GLOBAL);
  }

  formatProductStartDates(dataPeriod, esdDate) {
    if (!esdDate) {
      return null;
    }

    if (dataPeriod === 'quarter') {
      return `Q${ moment(esdDate, constants.dateFormat.GLOBAL_DASH).format(constants.dateFormat.QUARTER) }`;
    } else if (dataPeriod === 'week') {
      return moment(esdDate, constants.dateFormat.GLOBAL_DASH).format(constants.dateFormat.WEEK);
    } else if (dataPeriod === 'month') {
      return moment(esdDate, constants.dateFormat.GLOBAL_DASH).format(constants.dateFormat.MONTH);
    } else if (dataPeriod === 'year') {
      return moment(esdDate, constants.dateFormat.GLOBAL_DASH).format(constants.dateFormat.YEAR_ONLY);
    } else {
      return moment(esdDate, constants.dateFormat.GLOBAL_DASH).format(constants.dateFormat.GLOBAL);
    }

  }

  isPercentage(value) {
    return value > 0 && value < 1;
  }

  getRangeLabel(date1: string, date2: string) {
    return `${ date1 } to ${ date2 }`;
  }

  getLocalDateRange({ start, end }: ISetDefaultDatesParams) {
    const dateRange = new EsRange();
    dateRange.startDate = start ? moment(start).startOf('day').toDate() : moment().startOf('year').toDate();
    dateRange.endDate = end ? moment(end).startOf('day').toDate() : moment().startOf('day').toDate();
    return dateRange;
  }

  getStartDatesParams(dateRange: EsRange, ematicInstanceIds: string[]): IBaseReportParams {
    return {
      start: this.utilService.getRequestTimestamp(dateRange.startDate, true),
      end: this.utilService.getRequestTimestamp(dateRange.endDate, false),
      ematicInstanceIds
    };
  }

  getDailyDataParams(dateRange: EsRange,
                     ematicInstanceIds: string[],
                     ematicProductIds: string[],
                     period: string,
                     showAllCampaigns: boolean,
                     source?: string,
                     destination?: string): IDailyDataParams {
    return {
      ematicInstanceIds,
      ematicProductIds,
      start: this.utilService.getRequestTimestamp(dateRange.startDate, true),
      end: this.utilService.getRequestTimestamp(dateRange.endDate, false),
      period: (period === 'day') ? '' : period,
      showAllCampaigns,
      source,
      destination
    };
  }

  getMyProductData(currentDateRange: EsRange,
                   previousDateRange: EsRange,
                   ematicInstanceIds: string[],
                   ematicProductIds: string[],
                   source?: string,
                   destination?: string): IMyProductDataParams {
    return {
      ematicInstanceIds,
      ematicProductIds,
      currentDateRange: {
        start: this.utilService.getRequestTimestamp(currentDateRange.startDate, true),
        end: this.utilService.getRequestTimestamp(currentDateRange.endDate, false)
      },
      previousDateRange: {
        start: this.utilService.getRequestTimestamp(previousDateRange.startDate, true),
        end: this.utilService.getRequestTimestamp(previousDateRange.endDate, false)
      },
      source,
      destination
    };
  }

  getProductRoiBaseParams(ematicInstanceId: string): IBaseReportParams {
    const dateRange = new EsRange();
    dateRange.startDate = moment().subtract(1, 'month').toDate();
    dateRange.endDate = new Date();
    return this.getStartDatesParams(dateRange, [ematicInstanceId]);
  }

  mergeData(data1, data2, isCustomer?, dataPeriod?) {
    const result = [];
    const predictedData = [];
    const isMonthOrYearPeriod = dataPeriod === 'month' || dataPeriod === 'year';

    if (dataPeriod) {
      const regularDataKeyMap = {
        period: 'date'
      };
      const formatedKeyPredictedData = [];
      const nextPredictedData = data2[this.utilService.getPredictedDataKey(dataPeriod)],
        currentPredictedData = data2['currentTotal'];
      if (currentPredictedData && nextPredictedData) {
        if (isMonthOrYearPeriod) {
          nextPredictedData.forEach(item => {
            item['predictionData'] = true;
            item.retired = null;
          });
        } else {
          nextPredictedData['predictionData'] = true;
          nextPredictedData.retired = null;
        }
        currentPredictedData['predictionData'] = true;
        currentPredictedData.retired = null;
        let getNextPredictedData;
        if (dataPeriod === 'year' && isCustomer) {
          getNextPredictedData = [nextPredictedData[0]];
        } else if (isMonthOrYearPeriod) {
          getNextPredictedData = nextPredictedData;
        } else {
          getNextPredictedData = [nextPredictedData];
        }

        predictedData.push(currentPredictedData, ...getNextPredictedData);
        predictedData.forEach(item => {
          formatedKeyPredictedData.unshift(mapKeys(item, (value, key) => {
            return regularDataKeyMap[key] || key;
          }));
        });
        result.push(...formatedKeyPredictedData);
      }

      for (let index = 0; index < data1.length; index++) {
        result.push(data1[index] || {});
      }
    } else {
      const length = (data1.length > data2.length) ? data2.length : data1.length;
      const diff = Math.abs(data1.length - data2.length);
      const offset1 = (data1.length > data2.length) ? diff : 0;
      const offset2 = diff - offset1;
      for (let index = 0; index < length; index++) {
        result.push(data1[index + offset1] || {}, data2[index + offset2] || {});
      }
    }

    return result;
  }

  getClass(rowIndex: number, record: any[], columns: IEsDataTableColumn[]): string | null {
    const formatter = columns.find(c => c.field === 'date' || c.field === 'period').formatter;
    const formattedDate = formatter(record);

    const isMonthPeriod = record['period'] === 'month' || columns.every(column => column['period'] === 'month');
    return record.hasOwnProperty('predictionData')
      ? 'predicted'
      : (formattedDate === moment().format(constants.dateFormat.MONTH) && isMonthPeriod
      || formattedDate === moment().format(constants.dateFormat.YEAR_ONLY)
      || formattedDate === moment().format(constants.dateFormat.WEEK)
      || formattedDate === moment().format(constants.dateFormat.GLOBAL)
      || formattedDate === `Q${ moment().format(constants.dateFormat.QUARTER) }`
        ? 'data-table-highlight-row' : null);
  }

  getMetricProperty(columns: any, property: string) {
    return columns.find(item => item.value === property);
  }
}
