<es-dropdown-field #container
                   [displayText]="displayText"
                   [showErrorMessage]="showErrorMessage"
                   [errorMessage]="errorMessage"
                   [showDropdownIcon]="showDropdownIcon"
                   [showClearIcon]="showClearIcon"
                   [compact]="compact"
                   [hasTransparentColors]="hasTransparentColors"
                   [isRounded]="isRounded"
                   (clicked)="onDropdownSelect()"
                   (cleared)="onClear()">
</es-dropdown-field>
<es-dropdown-container
  [arrowPosition]="dropdownAlignment"
  [pointerBottom]="pointerBottom"
  (cancelClick)="onDropdownClose()"
  esDropdown
  [dropdownAlignment]="dropdownAlignment"
  [disableTopDropdownAlignment]="disableTopDropdownAlignment"
  [containerElement]="container.element"
  (hide)="onItemsHidden()"
  (pointerBottom)="onPointerBottom($event)"
  [closable]="closable"
  [isRounded]="isRounded"
  *ngIf="_itemsVisible">
  <div class="es-grouped-list-picker-container">
    <es-grouped-list *ngIf="!isLoading"
                     [rootGroupName]="rootGroupName"
                     [item]="innerValue"
                     [items]="items"
                     (groupSelect)="onGroupSelect($event)"
                     (animationEnd)="onAnimationEnd()"
                     (selected)="onItemSelect($event)">
    </es-grouped-list>
    <es-loader *ngIf="isLoading"></es-loader>
  </div>
</es-dropdown-container>
