import { Component, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';

import { MailChimpService } from './services/mailchimp/mailchimp.service';
import { CampaignMonitorService } from './services/campaign-monitor/campaign-monitor.service';
import { FacebookOAuthService } from './services/facebook-oauth/facebook-oauth.service';
import { PlatformService } from './ematic-core-ui/services/platform/platform.service';
import { getQueryStringValue } from './util/utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  subscription: Subscription;

  constructor(
    private router: Router,
    private mailChimpService: MailChimpService,
    private campaignMonitorService: CampaignMonitorService,
    private facebookOAuthService: FacebookOAuthService,
    private titleService: Title,
    private platformService: PlatformService,
    @Inject(DOCUMENT) private document: any
  ) {
    const queryString = window.location.search;
    const url = window.location.href;

    if (queryString.includes('state=mailchimp')) {
      const code = getQueryStringValue('code');
      this.mailChimpService.setOAuthCode(code);
    }

    // removing FaceBook oAuth redirect that is having # sign
    if (queryString.includes('state=facebook') && url.includes('#')) {
      const code = getQueryStringValue('code');
      this.facebookOAuthService.setOAuthCode(code);
      this.router.navigate([]);
    }

    if (queryString.includes('state=campaignmonitor')) {
      const code = getQueryStringValue('code');
      this.campaignMonitorService.setOAuthCode(code);
    }

    const legacySavedUserData = localStorage.getItem('user');
    if (legacySavedUserData) {
      localStorage.removeItem('user');
    }

    this.setFavIcon();
    this.titleService.setTitle(`${ this.platformService.platformName } Dashboard`);
  }

  setFavIcon(): void {
    let link = this.document.querySelector('link[rel~=\'icon\']');
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = `assets/img/platform/${ this.platformService.platformOriginId }/favicon.png`;
  }
}
