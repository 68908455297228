import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { PlatformOriginId } from '../../ematic-core-ui/models/platform';
import { PlatformService } from '../../ematic-core-ui/services/platform/platform.service';
import { ICompleteOAuthParams } from '../../models/esp-params';
import { constants } from '../../strings/constants';

@Injectable({
  providedIn: 'root'
})
export class FacebookOAuthService {

  oAuthCode: string;

  get platformOriginId(): PlatformOriginId {
    return this.platformService.platformOriginId;
  }

  constructor(private platformService: PlatformService) {
    this.oAuthCode = null;
  }

  setOAuthCode(code: string) {
    this.oAuthCode = code;
  }

  getOAuthCode() {
    const code = this.oAuthCode;
    this.oAuthCode = null;
    return code;
  }

  connect() {
    const facebook = environment.facebook;
    const scope = 'public_profile,email,ads_management,ads_read,pages_manage_ads,read_insights,pages_show_list,business_management,instagram_basic,instagram_manage_insights';
    window.location.href = facebook.authorizeURI +
      `?client_id=${ facebook.clientId }&response_type=code&scope=${ scope }&state=facebook|${ this.platformOriginId }&redirect_uri=${ environment.redirectURI }`;
  }

  getCompleteOAuthParams(code: string) {
    const fb = environment.facebook;

    return <ICompleteOAuthParams>{
      espType: constants.products.FACEBOOK_AD.value,
      clientId: fb.clientId,
      redirectURI: environment.redirectURI,
      code
    };
  }
}
