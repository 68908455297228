<div class="es-image-slider" [ngStyle]="{'height': sliderImageHeight + 'px'}">
  <div class="es-image-slider-container">
    <div class="es-image-slider-main" [ngStyle]="{'height': sliderImageHeight + 'px'}" #slider>
      <div *ngIf="data.length"
           class="es-image-slider-main-inner"
           [class.es-image-slider-width-main-pagination]="paginationShow"
           [style.width]="imageParentDivWidth + 'px'"
           [style.margin-left]="leftPos + 'px'"
           [style.transition]="effectStyle"
           (touchstart)="swipe($event, 'start')"
           (touchend)="swipe($event, 'end')">

        <div *ngFor="let item of data"
             [class.image-action]="imageAction"
             [style.width]="sliderImageWidth + 'px'"
             [style.margin-left]="imageMargin + 'px'"
             [style.margin-right]="imageMargin + 'px'"
             class="es-slider-image"
             [class.es-slider-hide-box-shadow]="hideBoxShadow"
             (click)="imageOnClick(item)"
             (mouseenter)="imageMouseEnterHandler()"
             (mouseleave)="imageAutoSlide()">
          <img class="image"
               [style.padding]="imagePadding + 'px'"
               [class.ratio]="imageRatio"
               [srcset]="item.imageUrl + ' 1x, ' + item.imageUrlRetina + ' 2x'"
               [alt]="item.alt || item.title || ''"
               [title]="item.title">
          <div class="caption" *ngIf="item.title && hasCaption">{{ item.title }}</div>
        </div>
      </div>
      <div class="es-image-slider-error" *ngIf="!data.length">
        No images to display.
      </div>
      <a *ngIf="showArrowButton && data.length > 1"
         [class.disable]="sliderPrevDisable"
         class="prev prev-icon" (click)="prev()"
         (mouseenter)="imageMouseEnterHandler()"
         (mouseleave)="imageAutoSlide()">
        <i class="icon-right-wide"></i>
      </a>
      <a *ngIf="showArrowButton && data.length > 1"
         [class.disable]="sliderNextDisable"
         class="next next-icon"
         (click)="next()"
         (mouseenter)="imageMouseEnterHandler()"
         (mouseleave)="imageAutoSlide()">
        <i class="icon-right-wide"></i>
      </a>
    </div>
    <div *ngIf="data.length && paginationShow" class="es-slider-pagination">
      {{visibleImageIndex + 1}} of{{totalImages}}
    </div>
  </div>
</div>
