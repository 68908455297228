import { Injectable } from '@angular/core';

import { constants } from '../../strings/constants';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class OnboardService {
  states: any;
  currentState: any;

  constructor(
    private authService: AuthService) {

    // TODO adjust when Hi-iQ onboarding flow is implemented
    this.states = [
      {
        id: 1, route: constants.states.DASHBOARD
      },
      {
        id: 10, route: constants.states.DASHBOARD
      }
    ];

    this.loadCurrentState();
  }

  loadCurrentState() {
    const { user } = this.authService.getUser();
    const currentStateId = (this.authService.isOnboardUser()) ? user.customer.onboard.currentStateId : null;
    this.currentState = this.states.find((item: any) => item.id === currentStateId) || {};
  }

  getState() {
    return this.currentState;
  }

  setState(state) {
    this.currentState = state;
  }
}
