import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { PlatformDomain, PlatformOriginId } from '../../ematic-core-ui/models/platform';
import { PlatformService } from '../../ematic-core-ui/services/platform/platform.service';

@Injectable({
  providedIn: 'root'
})
export class SupportEmailService {

  get platformOriginId(): PlatformOriginId {
    return this.platformService.platformOriginId;
  }

  get platformDomain(): PlatformDomain {
    return this.platformService.platformDomain;
  }

  get supportTeamEmail(): string  {
    return `mailto:${ environment.emails[this.platformOriginId].supportTeam }`;
  }

  get supportTeamEmailDisplayText(): string {
    if (environment.production) {
      return `support@${ this.platformDomain }`;
    } else {
      // Dev & Staging environments use ematicsolutions for all tenants
      return 'support@ematicsolutions.com';
    }
  }

  constructor(private platformService: PlatformService) {}
}
