import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { ToastrService } from 'ngx-toastr';

import { UIProject } from '../../ematic-core-ui/models/ui-project';
import { AsyncApi } from '../../ematic-core-ui/api/async-api';
import { PlatformService } from '../../ematic-core-ui/services/platform/platform.service';
import { PlatformOriginId } from '../../ematic-core-ui/models/platform';

import { environment } from 'environments/environment';

@Injectable()
export class GetResponseService extends AsyncApi {

  get platformOriginId(): PlatformOriginId {
    return this.platformService.platformOriginId;
  }

  constructor(
    router: Router,
    http: HttpClient,
    toastr: ToastrService,
    platformService: PlatformService) {
    super(http, router, toastr, '/get-response', platformService, UIProject.PLATFORM_DASHBOARD);
  }

  connect() {
    const gr = environment.getResponse;
    window.location.href = gr.authorizeURI + `?response_type=code&client_id=${ gr.clientId }&state=getresponse|` + this.platformOriginId;
  }
}
