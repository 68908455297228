import { Injectable } from '@angular/core';

import { difference, union, uniqBy, values } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '../auth/auth.service';
import { UtilService } from '../../util/util.service';
import { MartechService } from '../martech/martech.service';

import { IMartechConnection } from '../../models/martech-connection';
import { IMartech } from '../../models/martech';
import { IUser } from '../../models/user';

import { constants } from '../../strings/constants';
import { IExtendedMartechConnection } from '../../components/martech-connection-table/martech-connection-table.component';

export interface SelectedMartechs {
  [key: string]: IMartech;
}

export interface ConnectedMartechs {
  [key: string]: IMartech[];
}

@Injectable()
export class EcosystemService {
  currentInstanceConnections: IMartechConnection[];
  extendedCurrentInstanceConnections: IExtendedMartechConnection[];
  selectedMartechsByCategory: SelectedMartechs;
  lastSelectedMartechsByChannel: SelectedMartechs;
  connectedMartechsByCategory: ConnectedMartechs;
  prevSelectedChannel: string;
  selectedChannel: string;

  isConnecting: boolean;
  isLoading: boolean;
  isPageChanged: boolean;
  isEcosystemPageDisabled: boolean;
  showInstanceConnections: boolean;

  martechEcosystemCategories = constants.martech.ecosystem_categories;

  constructor(public toastr: ToastrService,
    private authService: AuthService,
    private utilService: UtilService,
    private martechService: MartechService) {
    this.currentInstanceConnections = [];
    this.extendedCurrentInstanceConnections = [];
    this.selectedMartechsByCategory = {};
    this.lastSelectedMartechsByChannel = {};
    this.connectedMartechsByCategory = {};
    this.prevSelectedChannel = '';
    this.selectedChannel = '';
    this.isConnecting = false;
    this.isLoading = false;
    this.isPageChanged = false;
    this.isEcosystemPageDisabled = false;
    this.showInstanceConnections = false;
  }

  loadCurrentInstanceConnections(): void {
    this.currentInstanceConnections = this.utilService.getCurrentInstance(this.authService.getUser().user).connections;
    this.currentInstanceConnections.forEach(connection =>
      connection.martechs.forEach(martech => martech.abbr = this.utilService.getMartechAbbreviation(martech.name))
    );
    this.extendConnections();
    const connectedMartechs = uniqBy(union(...this.currentInstanceConnections.map(connection => connection.martechs)), '_id');

    this.connectedMartechsByCategory = {};
    values(this.martechEcosystemCategories)
      .map(category => category.key)
      .forEach(key =>
        this.connectedMartechsByCategory[key] = connectedMartechs.filter(martech => martech.category === key)
      );
  }

  extendConnections(): void {
    this.extendedCurrentInstanceConnections = [];
    this.currentInstanceConnections.forEach(connection => {
      this.extendedCurrentInstanceConnections.push(<IExtendedMartechConnection>{
        martechs: connection.martechs,
        isChecked: false
      });
    });
  }

  setSelectedMartechsByCategoryToDefault(): void {
    this.selectedMartechsByCategory = {};
    values(this.martechEcosystemCategories).map(category => category.key).forEach(key =>
      this.selectedMartechsByCategory[key] = this.martechService.getDefaultMartech()
    );
  }

  saveSelectedMessagingPlatformByChannel(): void {
    const messaging = this.selectedMartechsByCategory[this.martechEcosystemCategories.messaging.key];
    if (messaging._id) {
      this.lastSelectedMartechsByChannel[messaging.subCategory] = messaging;
    }
  }

  onChannelSelect(channel: string): void {
    this.lastSelectedMartechsByChannel[this.prevSelectedChannel] =
      this.selectedMartechsByCategory[this.martechEcosystemCategories.messaging.key];
    this.prevSelectedChannel = channel;

    this.selectedMartechsByCategory[this.martechEcosystemCategories.messaging.key] = this.lastSelectedMartechsByChannel[channel] ||
      this.martechService.getDefaultMartech();
  }

  async insertConnnection(martechIds: string[]): Promise<void> {
    const currentUser = this.authService.getUser().user;
    const currentInstance = this.utilService.getCurrentInstance(currentUser);
    if (currentInstance.connections.some(connection =>
      !difference(connection.martechs.map(martech => martech._id), martechIds).length
    )) {
      throw new Error(constants.errors.ecosystem.connection_exists);
    }
    const instanceConnections = await this.martechService.insertConnection(currentInstance._id, martechIds);
    await this.updateInstanceConnections(currentUser, currentInstance._id, instanceConnections);
    this.showInstanceConnections = true;
  }

  async deleteConnections(connections: IMartechConnection[]): Promise<void> {
    const currentUser = this.authService.getUser().user;
    const currentInstanceId = this.utilService.getCurrentInstance(currentUser)._id;
    const instanceConnections = await this.martechService.deleteConnections(currentInstanceId, connections);
    await this.updateInstanceConnections(currentUser, currentInstanceId, instanceConnections);
    this.showInstanceConnections = !!this.currentInstanceConnections.length;
  }

  private async updateInstanceConnections(currentUser: IUser, currentInstanceId: string,
    instanceConnections: IMartechConnection[]): Promise<void> {
    await this.martechService.updateInstanceConnections(currentUser, currentInstanceId, instanceConnections);
    // basically, if everything is OK, the data will remain the same, but I prefer loading fresh data
    this.loadCurrentInstanceConnections();
  }
}
