import { Injectable } from '@angular/core';
import { EsRange } from '../../ematic-core-ui/components/es-range-picker/es-range';

const cache: any = {};

@Injectable()
export class ReportCacheService {
  constructor() {}

  stringifyDateRange(dateRange: EsRange): string {
    return `${dateRange.startDate.toISOString()}~${dateRange.endDate.toISOString()}`;
  }

  clearCache(cacheName: string): void {
    delete cache[cacheName];
  }

  clearAllCache(): void {
    // tslint:disable
    for (const c in cache) {
      if (cache.hasOwnProperty(c)) {
        this.clearCache(c);
      }
    }
    // tslint:enable
  }

  clearAllCacheExcept(cacheNames: string[]) {
    for (const c in cache) {
      if (cache.hasOwnProperty(c) && cacheNames.indexOf(c) !== -1) {
        this.clearCache(c);
      }
    }
  }

  clearCacheWithNameStartingWith(keyword: string) {
    for (const c in cache) {
      if (cache.hasOwnProperty(c) && c.startsWith(keyword)) {
        this.clearCache(c);
      }
    }
  }

  hasCachedData(): boolean {
    return Object.keys(cache).length > 0;
  }

  setCache(cacheName: string, data: any, dateRange: EsRange, forceSet?: boolean): void {
    const dateString = this.stringifyDateRange(dateRange);

    if (cache[cacheName] && cache[cacheName]['dateRange'] === dateString && !forceSet) {
      return;
    }

    if (!cache[cacheName] || (cache[cacheName] && cache[cacheName]['dateRange'] !== dateString) || forceSet) {
      cache[cacheName] = {
        dateRange: dateString,
        data
      };
    }
  }

  getCache(cacheName: string, dateRange: EsRange, shouldIgnoreDate: boolean = false): any {
    if (shouldIgnoreDate && cache[cacheName]) {
      return cache[cacheName]['data'];
    }

    if (!dateRange) {
      return false;
    }

    const dateString = this.stringifyDateRange(dateRange);
    if (!cache[cacheName] || (cache[cacheName]['dateRange'] !== dateString)) {
      return false;
    }

    if (cache[cacheName] && cache[cacheName]['dateRange'] === dateString) {
      return cache[cacheName]['data'];
    }
  }

  async updateCache(cacheName: string, dateRange: EsRange, getData: Function, ...params): Promise<any> {
    let data = this.getCache(cacheName, dateRange, dateRange === null);
    const hasNoData = !data;

    try {
      if (hasNoData) {
        data = await getData(...params);
      }
    } catch (error) {
      data = {};
    }

    if (dateRange && hasNoData) {
      this.setCache(cacheName, data, dateRange, true);
    }

    return data;
  }
}
