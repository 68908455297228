<app-external>
  <div content-container>
    <div class="number">401</div>
    <div class="text">You are not authorized to view this page!</div>
    <es-button
      [type]="'primary'"
      [text]="'Log in again'"
      [isRounded]="true"
      (clicked)="logout()">
    </es-button>
  </div>
</app-external>
