import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';

import { PermissionsService } from './permissions.service';
import { AuthService } from './auth.service';
import { MailChimpService } from '../mailchimp/mailchimp.service';

import { constants } from '../../strings/constants';

@Injectable()
export class PermissionsGuard implements CanActivateChild {

  constructor(private permissionsService: PermissionsService,
              private router: Router,
              private authService: AuthService,
              private mailChimpService: MailChimpService) {
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkPermission(state.url);
  }

  checkPermission(url: string) {
    if (this.permissionsService.hasPermission(url)) {
      return true;
    } else {
      const user = this.authService.getUser().user;
      if (!user) {
        this.router.navigate(['unauthorized']);
      } else if (this.mailChimpService.isOAuthInProgress()) {
        this.router.navigate([constants.states.ESP_OAUTH]);
      } else if (user.isBillingOnly) {
        this.router.navigate([constants.states.BILLING]);
      } else if (this.permissionsService.hasPermission(constants.states.DASHBOARD)) {
        this.router.navigate([constants.states.DASHBOARD]);
      } else {
        const state = this.permissionsService.getRouteForPermission(user);
        this.router.navigate([state ?? constants.states.LOGIN]);
      }

      return false;
    }
  }
}
