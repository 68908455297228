<app-external>
  <ng-container content-container>
    <div class="forgot-password-container">
      <a class="logo-link" [attr.href]="corporateUrl" target="_blank">
        <img class="ematic-logo"
             [srcset]="platformLogo.imageDpr1 + ' 1x, ' + platformLogo.imageDpr2 + ' 2x'"
             [alt]="platformText">
      </a>
      <div class="content-header" [hidden]="sent">
        <span class="content-title">
          Reset Password
        </span>
        <p class="content-subtitle">
          Enter your email address below and we will send you instructions on how to reset your password.
        </p>
      </div>
      <form #resetPasswordForm="ngForm" class="form" [hidden]="sent">
        <es-text-field class="forgot-password-input"
                       type="text"
                       placeholder="Email *"
                       name="email"
                       [isRounded]="true"
                       [autofocus]="true"
                       [(ngModel)]="email"
                       emailValidator
                       nonEmaticEmailValidator
                       (keyup.enter)="onResetPassword()">
        </es-text-field>
        <es-button
          class="btn-block"
          text="Send reset instructions"
          [type]="'primary'"
          [loading]="loading"
          [disabled]="!resetPasswordForm.form.valid || !resetPasswordForm.form.dirty"
          [isRounded]="true"
          (clicked)="onResetPassword()">
        </es-button>
        <p class="corporate-link">Return to
          <a routerLink="{{loginState}}" class="sign-up-link" routerLinkActive="active">Sign in</a>.
        </p>
      </form>
      <form [hidden]="!sent">
        <h4 class="title-external">Reset password email sent.</h4>
        <p>Please check your inbox and follow instructions on how to change your password.</p>
      </form>
    </div>
  </ng-container>
</app-external>
