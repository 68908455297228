import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { AsyncApi } from '../../ematic-core-ui/api/async-api';
import { UIProject } from '../../ematic-core-ui/models/ui-project';
import { CacheService } from '../../ematic-core-ui/util/cache.service';
import { PlatformService } from '../../ematic-core-ui/services/platform/platform.service';
import { AuthService } from '../auth/auth.service';
import { UtilService } from '../../util/util.service';

import {
  IBaseReportParams,
  ICdpSourcesAndDestinationsParams,
  IDailyDataParams,
  IHiIqReportParams,
  IMyProductDataParams
} from '../../models/report-params';
import { IStartDates } from '../../models/start-dates';
import { ICdpSourcesAndDestinations, IDailyData, IHiIqRunStats } from '../../models/report-data';

@Injectable()
export class ReportService extends AsyncApi {
  cache: CacheService;
  auth: AuthService;
  utilService: UtilService;

  constructor(
    router: Router,
    http: HttpClient,
    toastr: ToastrService,
    platformService: PlatformService,
    cacheService: CacheService,
    authService: AuthService,
    utilService: UtilService) {
    super(http, router, toastr, '/reports', platformService, UIProject.PLATFORM_DASHBOARD);
    this.cache = cacheService;
    this.auth = authService;
    this.utilService = utilService;
  }

  async getTemplate(params, templateName): Promise<any> {
    try {
      return await this.postAsync(params, `/retry-iq/template/${ templateName }`);
    } catch (error) {
      throw error;
    }
  }

  async getByeIqTemplate(params, experimentId, treatmentId): Promise<any> {
    try {
      return await this.postAsync(params, `/bye-iq/experiment/${ experimentId }/treatment/${ treatmentId }/overlay`);
    } catch (error) {
      throw error;
    }
  }

  async getStartDates(params: IBaseReportParams, instanceId?: string): Promise<IStartDates> {
    try {
      const currentInstanceId = instanceId ?? this.utilService.getCurrentInstance(this.auth.getUser().user).ematicInstanceId;
      const path = '/products/start-dates';
      const cachedData = this.cache.get(currentInstanceId, path);
      if (cachedData) {
        return cachedData;
      }
      const data = await this.postAsync(params, path);
      this.cache.set(currentInstanceId, path, data);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getHiIqCampaignData(params: IHiIqReportParams): Promise<any> {
    return await this.postAsync(params, '/hi-iq/campaigns');
  }

  async getHiIqRunStats(params: IHiIqReportParams): Promise<IHiIqRunStats> {
    return await this.postAsync(params, '/hi-iq/run-stats');
  }

  async getProductDailyData(params: IDailyDataParams): Promise<IDailyData> {
    return await this.postAsync(params, `/product/daily-data`);
  }

  async getMyProductData(params: IMyProductDataParams): Promise<IDailyData> {
    return await this.postAsync(params, `/my-product-data`);
  }

  // TODO: Uncomment to get ROI Data (EMATIC-2805)
  // async getProductRoiData(params: IProductRoiParams): Promise<IRoiData> {
  //   return await this.postAsync(params, '/product/roi');
  // }

  async getCdpSourcesAndDestinations(params: ICdpSourcesAndDestinationsParams): Promise<ICdpSourcesAndDestinations> {
    return await this.getAsync(params, '/cdp/sources-and-destinations');
  }
}
