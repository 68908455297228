export const constants = {
  products: {
    HI_IQ: {
      value: 'hi-iq',
      text: 'Hi-iQ'
    },
    BYE_IQ: {
      value: 'bye-iq',
      text: 'Bye-iQ'
    },
    RETRY_IQ: {
      value: 'retry-iq',
      text: 'Retry-iQ'
    },
    EMAIL_PERFORMANCE: {
      value: 'email-performance',
      text: 'Email Performance'
    },
    SMS_PERFORMANCE: {
      value: 'sms-performance',
      text: 'SMS Performance'
    },
    PUSH_PERFORMANCE: {
      value: 'push-performance',
      text: 'Push Performance'
    },
    WEB_PUSH_PERFORMANCE: {
      value: 'web-push-performance',
      text: 'Web Push Performance'
    },
    HISTORIC: {
      value: 'historic',
      text: 'Historic'
    },
    FACEBOOK_AD: {
      value: 'facebook-ad',
      text: 'Facebook Ad'
    },
    E_COMMERCE: {
      value: 'e-commerce',
      text: 'E-Commerce'
    },
    CAPTURE: {
      value: 'capture',
      text: 'Capture'
    },
    CDP: {
      value: 'cdp',
      text: 'CDP'
    },
    ATTRIBUTION: {
      value: 'attribution',
      text: 'Attribution'
    }
  },
  derivedProducts: {
    RUN_STATS: {
      value: 'run-stats',
      text: 'Hi-iQ'
    }
  },
  permissions: {
    'admin': {
      value: 'admin',
      text: 'Admin'
    },
    'hi-iq': {
      value: 'hi-iq',
      text: 'Hi-iQ'
    },
    'bye-iq': {
      value: 'bye-iq',
      text: 'Bye-iQ'
    },
    'retry-iq': {
      value: 'retry-iq',
      text: 'Retry-iQ'
    },
    // New permissions
    'marketplace': {
      value: 'marketplace',
      text: 'Marketplace'
    },
    'trend-analysis': {
      value: 'trend-analysis',
      text: 'Trend Analysis Report'
    },
    'payment-options': {
      value: 'payment-options',
      text: 'Payment options'
    },
    'access-rights': {
      value: 'access-rights',
      text: 'Access rights'
    }
  },
  states: {
    DASHBOARD: '/dashboard/dashboard',
    MY_PRODUCTS: '/dashboard/reports/my-products',
    TREND_ANALYSIS: '/dashboard/reports/trend-analysis',
    HI_IQ: '/dashboard/reports/hi-iq',
    BYE_IQ: '/dashboard/reports/bye-iq',
    RETRY_IQ: '/dashboard/reports/retry-iq',
    ECOSYSTEM: '/dashboard/ecosystem',
    BILLING: '/dashboard/billing',
    ACCESS_RIGHTS: '/dashboard/access-rights',
    MARKETPLACE: '/dashboard/marketplace',
    HELP_CENTER: '/dashboard/help-center',
    USER_PROFILE: '/dashboard/user-profile',
    LOGIN: '/login',
    // CONNECT_ESP: '/dashboard/connect-esp',  // TODO uncomment when Hi-iQ onboarding flow is implemented
    USERS: '/dashboard/reports/users',
    CUSTOMER_SUPPORT: '/dashboard/customer-support',
    ESP_OAUTH: '/dashboard/integrations'
  },
  tooltips: {
    hiIqReport: {
      ENGAGED: 'High Quality Subscribers who are actively engaging with your content.',
      SEMI_ENGAGED: 'Subscribers who have recently stopped opening emails.',
      DISENGAGED: 'Low Quality Subscribers who have not opened a single email in a couple months.',
      CONTROL: 'Subscribers in your control group who get picked up in every Hi-iQ Segment.',
      REHAB_BREAKDOWN_LIFT: 'Variances may occur on the individual rehab period breakdown due to small sample sizes. Lift is normalized if we were running at 100% Hi-iQ vs 100% Control Group.'
    },
    hiIqAccountSettings: {
      MAILING_FREQUENCY_ID: 'Number of emails sent to subscribers per week.',
      CONTROL_GROUP_PERCENTAGE: 'Percentage of users that Hi-iQ shouldn\'t be applied to.',
      RUN_START_TIME: 'The start time of Hi-iQ run.',
      POST_SEGMENT: 'Post Hi-iQ segment on ESP.'
    },
    hiIqSmoothing: {
      SEGMENT_SIZE_SMOOTHING: 'Activate/Deactivate limiting size of Hi-iQ segment.',
      CONFIGURED_SEGMENT_SIZE: 'Configured segment size limit.',
      ACTUAL_SEGMENT_SIZE: 'Actual segment size for the day.',
      CONSOLIDATION: 'Activate/Deactivate compression of all non-engaged users between rehab 1 and 10.',
      AUTO_SPREAD: 'Activate/Deactivate distribution of large returning non-engaged users.'
    },
    redirectionLinks: {
      INSTANCE_DETAILS: 'Redirect to instance details page in Admin Tool.',
      HI_IQ_ACCOUNT_DETAILS: 'Redirect to Hi-iQ account details page in Admin Tool.'
    },
    gaInactive: 'Email revenue value is not available because Google Analytics access is not yet granted.	To grant access visit integrations section of settings.',
    predictedDisclaimer: 'Predictive modeling is based on the trends of a selected time period that are used to extrapolate performance over another selected time period.',
    retryiqDisclaimer: 'Retry-iQ start date refers to a date when the product was successfully started',
    hiiqDisclaimer: 'Hi-iQ start date refers to a date when the product was successfully activated',
    byeiqDisclaimer: 'Bye-iQ start date refers to a date when the product was successfully activated',
    espIntegrationUpdateDisclaimer: 'If there are any issues with the data you can update your ESP integration or contact our Customer Success Team.',
    summedData: 'The values represented in \'Across all experiments\' only shows unique session data. At the monthly and per experiment level Ematic shows data that has been accessed by a single user multiple times.',
    campaignStats: {
      REVENUE: 'Revenue data is currently visible as it is sourced from GA more frequently than campaign data. Check back within 24 hours to see campaign data.',
      CONVERSIONS: 'Conversions data is currently visible as it is sourced from GA more frequently than campaign data. Check back within 24 hours to see campaign data.'
    },
    monthlyBilling: 'A once-per-month charge based on the highest number of subscribers in your account during a 30-day billing period. Ematic automatically adjusts your billing tier as your list grows and shrinks.',
    gaDisabled: 'This data point requires you to integrate your Google Analytics account.',
    gaNotAvailable: 'GA data are not available for \"Across all instances\" option.',
    hiIqModeledData: 'Modelled Data is adjusted Actual Data, showing as if we only ran Hi-iQ or Control Group. By adjusting, or modelling, both to be 100%, you can compare Hi-iQ and Control Group data side by side. Modelling gives a fair data set for comparison.',
    hiIqActualData: 'Actual Data represents the split between Hi-iQ and our control group, without adjustment. You can see exactly how many subscribers we quarantine from Hi-iQ AI.',
    performanceGap: 'Performance Delta is the Delta of Hi-iQ:Control Group. It shows the difference in performance as a %. If Hi-iQ hits 20% and Control Group 15%, the Performance Delta is 5%.',
    performanceLift: 'Performance Lift are percentage values that are calculated by dividing Performance Delta by Control Group',
    allCampaignsData: 'Click on the label to see more details',
    previewLink: 'Click to preview',
    metrics: {
      email: {
        opt_engaged_num: 'The total number of high-quality subscribers who are actively engaging in customers\' content (they\'re all ready for today\'s mailing).',
        opt_nonengaged_num: 'The number of low-quality subscribers who are not engaging in the customers\' content (part of them would be mailed as part of the Control group, but most of them will not be mailed today).',
        control_engaged: 'Part of the Total Engaged segment that is ready for today\'s mailing but will be mailed as part of the Control group.',
        control_nonengaged: 'Part of the Total Non Engaged segment that wouldn\'t be mailed otherwise, but will be mailed as part of the Control group.',
        control_forced_num: 'Part of the Control Non Engaged segment that wouldn\'t be mailed otherwise, but we\'re forcing them into mailing as part of the Control group.',
        ltr_temp_unsuppressed_num: 'The total number of retired subscribers who are ready for mailing today.',
        opt_total_segment_num: 'The total number of subscribers who are ready for mailing today.',
        opt_excluded_num: 'The total number of subscribers who will not be mailed today.',
        active_num: 'The total number of subscribers active on the platform list today.',
        ltr_reactivated_num: 'The total number of LTR subscribers reactivated today from the campaign.',
        ltr_reactivated_organic_num: 'The total number of LTR subscribers reactivation organically today by joining the platform list.',
        ltr_total_retired_num: 'The total number of subscribers retired as of today.',
        billable_engaged_count: 'The total number of subscribers filtered based on the billable criteria.',
        subbed_days_ago_criteria_value: 'Users will be filtered based on the value when they synced to the platform and will be known as engaged users (be part of the Hi-iQ segment).',
        opened_days_ago_criteria_value: 'Users will be filtered based on the value stated when they have opened a campaign and will be known as engaged users (be part of the Hi-iQ segment).',
        clicked_days_ago_criteria_value: 'Users will be filtered based on the value stated when they have clicked a campaign and will be known as engaged users (be part of the Hi-iQ segment).',
        calculated_open_ratio: 'Ratio will be calculated based on total unique engaged users opened : total unique non-engaged users opened.',
        calculated_click_ratio: 'Ratio will be calculated based on total unique engaged users clicked : total unique non-engaged users clicked.',
        eligibles: 'The unique number of users who are eligible to view the overlay.',
        impressions: 'The total number of times Bye iQ overlay has appeared.',
        sessions: 'The total number of website sessions.',
        byeiQSubscribers: 'New contacts collected via Bye-iQ.',
        subscribers: 'New contacts collected via Bye-iQ.',
        treatment_name: 'The different types of Bye-iQ overlay in an A/B test.',
        totalNewSubscribers: 'The total number of new subscribers who have been added to your list within a defined period (Including Subscribers captured on Bye-iQ).',
        subscriptionRate: 'The percentage of web visitors who submitted their email addresses out of the total web visitors who have viewed the overlays.',
        deliveredTotal: 'The number of emails deliver to recipients inboxes.',
        deliveredPercent: 'The percentage of emails that have been successfully delivered and have not soft/hard bounced (Total Emails Delivered/Total Emails Sent).',
        open: 'The number of recipients that opened the campaign any number of times.',
        openedPercent: 'The percentage of emails opened out of the total number of emails sent (Total Unique Opens/Total Emails Sent excluding hard bounced).',
        clickedPercent: 'The percentage of subscribers who click an email out of the total no. of emails sent (Total Unique Clicks/Total Emails Sent).',
        transactions: 'The total number of transactions made within your email channel (This is directly pulled from the Ecommerce Tracking under the Email Channel in Google Analytics).',
        transactionsPerSession: 'The percentage of recipients who made a purchase after clicking a link in an email (Conversions/Total Unique Clicks).',
        campaigns: 'The total number of email campaigns scheduled.',
        sentPerCampaign: 'The average number of email sent per campaign.',
        existing: 'The number of subscribers at the beginning of a defined period (Month/Day).',
        subscribed: 'The total number of new subscribers who have been added to your list within a defined period (Including Subscribers captured on Bye-iQ).',
        churned: 'The subscribers who opted-out of the email database or are removed as a result of a hard bounce, or multiple soft bounces (= unsubscribed + cleaned).',
        net: 'The residual subscribers after the defined churn period. Net subscribers are calculated by Subscribers who joined - Churned.',
        totalSubscribers: 'The total number of subscribers at the end of a defined period (Month or Day).',
        cleaned: 'Email addresses that have been removed from the list due to multiple soft or hard bounces.',
        unsubscribed: 'Subscribers who have unsubscribed or opted out of the email database. The data in the Trend Analysis report refers to the number of unsubscribes based on the date.',
        conversions: 'The total number of transactions made within your email channel (This is directly pulled from the Ecommerce Tracking under the Email Channel in Google Analytics).',
        conversionRate: 'The percentage of recipients who made a purchase after clicking a link in an email (Conversions/Total Unique Clicks).',
        spend: 'Enter your spend in order to calculate ROI.',
        roi: 'The metric is calculated as the email revenue divided by the spend.'
      },
      multiChannel: {
        click: 'The number of recipients that clicked the campaign any number of times.',
        sent: {
          email: 'The total number of sent emails.',
          sms: 'The total number of sent SMS messages.',
          push: 'The total number of sent push notifications.',
          webPush: 'The total number of sent web push notifications.'
        },
        bounced: {
          sms: 'The total number of SMS messages sent, but not delivered.',
          push: 'The total number of push notifications sent, but not delivered.',
          webPush: 'The total number of web push notifications sent, but not delivered.'
        },
        unsubscribed: {
          email: 'Subscribers who have unsubscribed or opted out of the email database. The data in the Trend Analysis report refers to the number of unsubscribes based on the date.',
          sms: 'Subscribers who have unsubscribed or opted out of the SMS database. The data in the Trend Analysis report refers to the number of unsubscribes based on the date.',
          push: 'Subscribers who have unsubscribed or opted out of the push database. The data in the Trend Analysis report refers to the number of unsubscribes based on the date.',
          webPush: 'Subscribers who have unsubscribed or opted out of the web push database. The data in the Trend Analysis report refers to the number of unsubscribes based on the date.'
        },
        deliveredPercent: {
          email: 'The percentage of emails that have been successfully delivered and have not soft/hard bounced (Total Emails Delivered/Total Emails Sent).',
          sms: 'The percentage of SMS messages that have been successfully delivered and have not soft/hard bounced.',
          push: 'The percentage of push notifications that have been successfully delivered and have not soft/hard bounced.',
          webPush: 'The percentage of web push notifications that have been successfully delivered and have not soft/hard bounced.'
        },
        clickedPercent: {
          email: 'The percentage of subscribers who click an email out of the total no. of emails sent (Total Unique Clicks/Total Emails Sent).',
          sms: 'The percentage of subscribers who click a SMS message out of the total no. of SMS messages sent.',
          push: 'The percentage of subscribers who click a push notification out of the total no. of push notifications sent.',
          webPush: 'The percentage of subscribers who click a web push notification out of the total no. of web push notifications sent.'
        },
        unsubscribeRate: {
          sms: 'The percentage of subscribers who have unsubscribed or opted out of the SMS database.',
          push: 'The percentage of subscribers who have unsubscribed or opted out of the push database.',
          webPush: 'The percentage of subscribers who have unsubscribed or opted out of the web push database.'
        },
        conversions: {
          email: 'The total number of transactions made within your email channel (This is directly pulled from the Ecommerce Tracking under the Email Channel in Google Analytics).',
          sms: 'The total number of transactions made within your SMS channel (This is directly pulled from the Ecommerce Tracking under the SMS Channel in Google Analytics).',
          push: 'The total number of transactions made within your push channel (This is directly pulled from the Ecommerce Tracking under the Push Channel in Google Analytics).',
          webPush: 'The total number of transactions made within your web push channel (This is directly pulled from the Ecommerce Tracking under the Web Push Channel in Google Analytics).'
        },
        conversionRate: {
          email: 'The percentage of recipients who made a purchase after clicking a link in an email (Conversions/Total Unique Clicks).',
          sms: 'The percentage of recipients who made a purchase after clicking a link in a SMS message.',
          push: 'The percentage of recipients who made a purchase after clicking a link in a push notification.',
          webPush: 'The percentage of recipients who made a purchase after clicking a link in a web push notification.'
        },
        revenue: {
          email: 'The total revenue earned as a direct result from your email channel, tracked from Google Analytics.',
          sms: 'The total revenue earned as a direct result from your SMS channel, tracked from Google Analytics.',
          push: 'The total revenue earned as a direct result from your push channel, tracked from Google Analytics.',
          webPush: 'The total revenue earned as a direct result from your web push channel, tracked from Google Analytics.'
        },
        aov: 'The average price of all completed transactions during the specified period.'
      },
      facebook: {
        add_to_cart: 'The number of add to cart events attributed to your ads.',
        app_installs: 'The number of users who installed your app.',
        cpc: 'The average cost for each link click. The metric is calculated as the total amount spent divided by link clicks (Spend/Inline Link Clicks).',
        cpm: 'The total amount spent on your ads, divided by impressions (Spend/Impressions), multiplied by 1,000.',
        ctr: 'The percentage of times people saw your ad and performed a link click (Inline Link Clicks/Impressions).',
        clicks: 'The total number of clicks which include link clicks as well as clicks on other parts of your ad.',
        complete_registration: 'The number of complete registration events attributed to your ads.',
        content_view: 'The number of view content events attributed to your ads.',
        conversions: 'The number of times a specific valuable action like purchase or add to cart was taken.',
        cost_per_add_to_cart: 'The average cost for each add to cart (Spend/Add To Cart).',
        cost_per_app_installs: 'The average cost of each app install (Spend/App Installs).',
        cost_per_complete_registration: 'The average cost of each registration completed (Spend/Complete Registration).',
        cost_per_content_view: 'The average cost of each content view (Spend/Content View).',
        cost_per_conversions: 'The average cost for each conversion. This metric is calculated as cost divided by conversions (Spend/Conversions).',
        cost_per_landing_page_view: 'The average cost for each landing page view (Spend/Landing Page View).',
        cost_per_lead_form: 'The average cost of each lead form (Spend/Lead Form).',
        cost_per_lead: 'The average cost of each lead (Spend/Leads).',
        cost_per_mobile_complete_registration: 'The average cost for each mobile app registration (Spend/Mobile Complete Registration).',
        cost_per_new_messaging_connections: 'The average cost for each new messaging connection (Spend/New Messaging Connection).',
        cost_per_page_likes: 'The average cost for each page like (Spend/Page Likes).',
        cost_per_post_comments: 'The average cost for each post comment (Spend/Post Comments).',
        cost_per_post_engagement: 'The average cost for each post engagement (Spend/Post Engagement).',
        cost_per_post_shares: 'The average cost of each post share (Spend/Post Shares).',
        cost_per_purchases: 'The average cost of each purchase (Spend/Purchases).',
        cost_per_thru_plays: 'The average cost for each ThruPlay (Spend/ThruPlays).',
        cost_per_unique_add_to_cart: 'The estimated average cost of each unique add to cart (Spend/Unique Add To Cart). ',
        cost_per_unique_inline_link_clicks: 'The estimated average cost for each unique click to inline link (Spend/Unique Inline Link Clicks).',
        cost_per_unique_mobile_complete_registration: 'The estimated average cost of each unique mobile registration completed (Spend/Unique Mobile Complete Registration).',
        cost_per_unique_conversions: 'The estimated average cost of each unique conversion (Spend/Unique Conversions).',
        cost_per_unique_content_view: 'The estimated average cost of each unique content view (Spend/Unique Content View).',
        cost_per_unique_web_complete_registration: 'The estimated average cost of each unique website registration completed (Spend/Unique Web Complete Registration).',
        cost_per_video_views: 'The estimated average cost of each video view.',
        cost_per_web_complete_registration: 'The average cost of each website registration completed (Spend/Web Complete Registration).',
        frequency: 'The average number of times each person saw your ad.',
        impressions: 'The number of times your ads were on screen.',
        landing_page_view: 'The number of times people loaded your ad\'s destination URL (landing page).',
        lead: 'The number of lead events attributed to your ads.',
        inline_link_clicks: 'The number of clicks on links within the ad that led to advertiser-specified destinations.',
        mobile_complete_registration: 'The number of registrations in your mobile app that were recorded as app events and attributed to your ads.',
        new_messaging_connections: 'The number of people your business added as messaging connections, attributed to your ads.',
        offline_purchases: 'The number of purchase events that were recorded by your offline events data and attributed to your ads.',
        offline_purchases_value: 'The total value returned from offline purchase conversions. This is based on the value that you assigned when you set up the offline event.',
        offline_roas: 'The total offline return on ad spend (Offline Purchases Value/Spend).',
        online_purchases: 'The number of online purchase events, attributed to your ads.',
        online_purchases_value: 'The total value returned from online purchase conversions.',
        online_roas: 'The total online return on ad spend (Online Purchases Value/Spend).',
        page_likes: 'The number of likes of your Facebook Page attributed to your ads.',
        percent_of_video_watch_at_25: 'The average percentage of times your video was viewed to 25% of its length.',
        percent_of_video_watch_at_50: 'The average percentage of times your video was viewed to 50% of its length.',
        percent_of_video_watch_at_75: 'The average percentage of times your video was viewed to 75% of its length.',
        percent_of_video_watch_at_100: 'The average percentage of times your video was viewed to 100% of its length.',
        post_comments: 'The number of comments that people made on your ads while they were running.',
        post_engagement: 'The number of actions people took on your ad such as likes or shares.',
        post_shares: 'The number of shares of your ads.',
        purchases: 'The number of purchase events, attributed to your ads.',
        roas: 'The total return on ad spend (Revenue/Spend).',
        reach: 'The number of unique users who saw your add.',
        revenue: 'The total revenue generated from your ads.',
        spend: 'The total amount spent on your ads.',
        thru_plays: 'The number of times your video was played to completion, or for at least 15 seconds.',
        total_video_views: 'The total number of video views.',
        unique_add_to_cart: 'The estimated number of people who added items to their cart.',
        unique_cpc: 'The estimated cost for each unique click (Spend/Unique Clicks).',
        unique_ctr: 'The estimated percentage of people who saw an ad and performed a link click (Unique Inline Link Click/Reach).',
        unique_clicks: 'The estimated number of people who performed a click on your ad.',
        unique_inline_link_clicks: 'The estimated number of people who performed a link click within your ad.',
        unique_mobile_complete_registration: 'The estimated number of people who completed registration in your mobile app.',
        unique_conversions: 'The estimated number of people who completed at least one conversion.',
        unique_content_view: 'The estimated number of people who viewed content.',
        unique_web_complete_registration: 'The estimated number of people who completed registration on your website.',
        video_watch_at_25: 'The number of times a video was played for at least 25% of its length, including plays that skipped to the 25% point.',
        video_watch_at_50: 'The number of times a video was played for at least 50% of its length, including plays that skipped to the 50% point.',
        video_watch_at_75: 'The number of times a video was played for at least 75% of its length, including plays that skipped to the 75% point.',
        video_watch_at_100: 'The number of times a video was played to its completion, including plays that skipped to the 100% point.',
        web_complete_registration: 'The number of complete registration events on your website and attributed to your ads.'
      },
      eCommerce: {
        shopify: {
          pendingOrders: 'Orders that have not yet been paid.',
          refundedOrders: 'Cancelled orders with captured payments.',
          revenue: 'The total revenue your store is driving.',
          totalCustomers: 'Total number of customers who placed orders with your store.',
          totalOrders: 'Total number of orders that were received.',
          successfulOrders: 'Paid and closed orders.',
          authorizedOrders: 'Orders with authorized payment.'
        },
        bigCommerce: {
          pendingOrders: 'Incomplete orders. Customer started the checkout process but did not complete it.',
          refundedOrders: 'Seller has used the Refund action to refund the whole order.',
          revenue: 'Returns from completed orders that happened in the given time period.',
          totalCustomers: 'Unique customers in the selected time period.',
          totalOrders: 'Total number of orders in a given time period.',
          successfulOrders: 'Order has been shipped/picked up, and receipt is confirmed; client has paid for their digital product, and their file(s) are available for download.',
          authorizedOrders: 'Orders with authorized payment.'
        },
        wooCommerce: {
          pendingOrders: 'Orders received, no payment initiated. Awaiting payments (unpaid).',
          refundedOrders: 'Orders refunded by an admin – no further action required.',
          revenue: 'The total revenue from completed orders for a selected date range.',
          totalCustomers: 'Total number of customers of your store.',
          totalOrders: 'The number of orders placed for a selected date range.',
          successfulOrders: 'Orders fulfilled and complete – require no further action.',
          authorizedOrders: 'Orders with authorized payment.'
        }
      },
      optimonk: {
        campaigns: 'The total number of campaigns.',
        conversionRate: 'The number of conversions divided by the total number of visitors, shown as a percentage.',
        conversions: 'The total number of conversions.',
        impressions: 'The total number of times your messages have been displayed to your visitors.',
        pageViews: 'The total number of times user visited one of the pages on your website. When a page is being loaded (or reloaded) on your website, it is counted as a pageview.',
        uniqueVisitors: 'The total number of distinct users who visited one of the pages on your website.',
        spend: 'The total amount spent.',
        subscribers: 'The number of total subscribers (unique visitors).',
        costPerSubscriber: 'The amount spent per subscriber. '
      },
      segment: {
        successes: 'The total number of successfully delivered events to the destination.',
        expired: 'The total number of retried events that have reached their max retry attempts and will no longer be retried by Segment.',
        discarded: 'The total number of events that were rejected by the destination and Segment will not retry.',
        retried: 'The total number of events that were rejected by a destination and Segment will retry.',
        timeToSuccessAverage: 'The average time it took for events to be successful to the destination. This includes the time it took for retried events to be successful.',
        timeToFirstAttemptAverage: 'The average time it took to attempt to send events to the destination.'
      },
      adjust: {
        clicks: 'The total number of clicks on Adjust tracker links from your campaigns.',
        installs: 'The total number of installs that have occurred.',
        clickConversionRate: 'How many clicks it takes, on average, for a user to install your app (Installs / Clicks).',
        reattributions: 'The total number of reattributions that have occurred.',
        sessions: 'The total number of sessions, including installs (first sessions), reinstalls, reattributions, and reattribution reinstalls that occurr.',
        revenueEvents: 'The total number of revenue events that have been triggered.',
        revenue: 'The total revenue your app has generated based on the tracked revenue events reported by the Adjust SDK or as recorded via server-to-server event tracking.',
        daus: 'The average number of unique daily active users (DAU) for your selected timeframe.',
        waus: 'The average number of unique weekly active users (WAU) for your selected timeframe.',
        maus: 'The average number of unique monthly active users (MAU) for your selected timeframe.'
      },
      mixpanel: {
        sessions: 'The total number of user sessions.'
      }
    },
    trendAnalysis: {
      metricDisabled: 'Metric not available.'
    }
  },
  trendAnalysis: {
    campaignsListView: 'List View will show you only those campaigns sent from your Hi-iQ connected List. All Campaigns will show you all campaigns from your account regardless of which list they are in. Test campaigns (small volume campaigns with OR > 50% or CR > 15%) will be included as well.',
    allCampaignsMissingData: 'Subscriber Report and Google Analytics data are not available when All Campaigns Data switch is on.',
    predictedLabel: 'Predicted Data',
    firstRangeLabel: 'First Range',
    secondRangeLabel: 'Second Range',
    trendlineLabel: 'Trendline Data',
    espIntegrationUpdateLabel: 'ESP integration update',
    defaultVisibleEventColumnsCount: 5
  },
  events: {
    INSTANCE_CHANGED: 'instanceChanged',
    PAYMENT_ADDED: 'paymentAdded',
    TOGGLE_LEFT_MENU: 'toggleLeftMenu',
    TOGGLE_RIGHT_MENU: 'toggleRightMenu',
    LEFT_MENU_OPENED: 'leftMenuOpened',
    PROFILE_UPDATED: 'userProfileUpdated',
    REMOVE_CONTENT_ONLY: 'removeContentOnly',
    ORGANIZATION_CHANGED: 'organizationChanged',
    ACROSS_ALL_ADDED: 'acrossAllAdded',
    CHANGE_ECOSYSTEM_PAGE_STATUS: 'changeEcosystemPageStatus',
    ACCOUNT_DELETION_ERROR: 'accountDeletionError',
    TOGGLE_NOTIFICATIONS: 'toggleNotifications',
    NOTIFICATIONS_UPDATED: 'notificationsUpdated',
    NOTIFICATIONS_SUPPRESSED: 'notificationsSuppressed',
    SHOW_NOTIFICATIONS_TAB: 'showNotificationsTab',
    ORGANIZATION_SELECTED: 'organizationSelected',
    INSTANCE_SELECTED: 'instanceSelected',
    PRODUCT_LOADED: 'productLoaded'
  },
  errors: {
    error: 'Error',
    error_occurred: 'Error occurred',
    something_went_wrong: 'Something went wrong :(',
    password_mismatch: 'Passwords must match',
    missing_email_password: 'Email and password field must be provided.',
    missing_email: 'Email must be provided.',
    session_expired: 'Your session expired. Please login again.',
    ranges_differs: 'Selected ranges don\'t have the same length in days.',
    error_loading_data: 'Error while loading data.',
    error_deleting_account: 'There was an error while deleting account.',
    payment: {
      invalid_card_number: 'The card number is not a valid credit card number.',
      invalid_expiration_date: 'Your card\'s expiration date is invalid.',
      invalid_cvc: 'Your card\'s security code is invalid.',
      empty_name: 'Cardholder name cannot be empty.',
      problem_with_add: 'There was a problem with payment method add:',
      server_error: 'There was a problem with payment method due to a server error.',
      removing_card: 'There was an error while removing payment info.',
      large_subs_number: 'Please contact our sales team at sales@ematicsolutions.com to discuss our enterprise package.',
      problem_with_add_billing_address: 'There was a problem with billing address add:',
      billing_address_server_error: 'There was a problem with adding a billing address due to a server error.'
    },
    analytics: {
      grant_error: 'There was an error while granting google analytics access.',
      empty_view: 'View data cannot be submitted empty.',
      cookies_error: 'idpiframe_initialization_failed',
      cookies_details: 'Cookies are not enabled in current environment.',
      ga_disabled: 'Actions connected to Google Services are disabled. If browsing in private mode enable third-party cookies, or open page in regular window.',
      ga_revoked: 'Google Analytics access is revoked. You will need to integrate Google Analytics again.',
      gapi_undefined: 'In order to use Google Services, please reload the page.',
      generic: 'There was an error while loading Google Services.'
    },
    retry_iq: {
      tags: 'Error occurred while fetching tags data.',
      logs: 'There was a problem loading report details'
    },
    hi_iq: {
      error_updating_account: 'There was an error while updating Hi-iQ account.'
    },
    registration: {
      unsuccessful: 'There was a problem while completing your registration.',
      geolocation_unavailable: 'Geolocation data required to complete your Ematic registration are not available. Please try again later.'
    },
    forgot_password: {
      email_invalid: 'Email is invalid.',
      sending_email: 'Error occurred while sending reset password email.'
    },
    create_account_email: 'Error occurred while sending create account email.',
    google: {
      auth_error: 'Error while authenticating with your Google account.'
    },
    oAuth: {
      missing_code: 'Missing OAuth code.',
      esp_not_found: 'ESP with the OAuth in progress not found.'
    },
    mailchimp: {
      lists_error: 'Could not get MailChimp lists for given API Key.',
      oauth_error: 'MailChimp OAuth error',
      oauth_missing_params: 'Missing MailChimp access token and data center parameters.'
    },
    campaign_monitor: {
      clients_error: 'Could not get Campaign Monitor clients for given API Key.',
      lists_error: 'Could not get Campaign Monitor lists for given API Key.',
      oauth_error: 'Campaign Monitor OAuth error',
      oauth_missing_params: 'Missing Campaign Monitor access token.'
    },
    campaign_stats_error: 'Campaign statistics data is not available.',
    onboard: {
      state_change: 'Error occurred while trying to open next step. Please try again later.',
      hi_iq_segment_post: 'Error occurred while trying to configure your Hi-iQ product. Please try again later.',
      hi_iq_segment_stop: 'Error occurred while trying to shut down your Hi-iQ product. Please try again later.'
    },
    esp: {
      unsupported: 'Unsupported ESP.'
    },
    ecosystem: {
      platforms_connection_error: 'There was an error while connecting platforms.',
      connection_exists: 'Connection already exists.',
      connections_delete_error: 'There was an error while deleting platforms.'
    },
    martechCategory: {
      not_supported: 'MarTech category not supported.'
    },
    notification: {
      loading: 'Error while loading notifications.',
      creating: 'There was an error while creating notification.',
      updating: 'There was an error while updating notification.',
      deleting: 'There was an error while deleting notification.',
      stopping: 'There was an error while stopping notification repeating.',
      suppressing: 'There was an error while suppressing notifications.',
      onboarding: {
        loading: 'Error while loading onboarding notifications.'
      }
    },
    comment: {
      creating: 'There was an error while creating comment.',
      updating: 'There was an error while updating comment.',
      deleting: 'There was an error while deleting comment.'
    },
    channel: {
      invalid_value: 'Invalid channel.'
    },
    product: {
      invalid_type: 'Invalid product type.',
      cdp: {
        no_segment_sources_and_destinations: 'Segment sources and destinations unavailable.'
      }
    },
    trendAnalysis: {
      check_hi_iq_permission: 'There was an error while checking permissions for Hi-iQ product.'
    }
  },
  info: {
    ranges_not_equal: 'Selected ranges are not equal so compare is not total.',
    email_currency_differs: 'Email revenue and AOV are not available since currency differs across instances.'
  },
  dateFormat: {
    YEAR_ONLY: 'YYYY',
    MONTH: 'YYYY/MM',
    GLOBAL: 'YYYY/MM/DD',
    GLOBAL_DASH: 'YYYY-MM-DD',
    DATETIME: 'YYYY/MM/DD HH:mm',
    CHART_AXIS: 'MM/DD h:mm a',
    PRETTY: 'MMMM Do, YYYY',
    WEEK: 'GGGG/WW',
    QUARTER: 'Q/YYYY',
    YEAR_QUARTER: 'YYYY-QQ',
    YEAR_MONTH: 'YYYY-MM',
    YEAR_WEEK: 'YYYY-WW',
    NOTIFICATION_NO_YEAR: 'D. MMM - hh:mma',
    NOTIFICATION_WITH_YEAR: 'D. MMM YYYY. - HH:mma',
    NOTIFICATIONS_TAB_DATE: 'd. MMM YYYY. - HH:mma'
  },
  datePlaceholder: 'Select date range',
  messages: {
    success: 'Success',
    password_updated: 'Password updated.',
    user_updated: 'User data updated.',
    registration_successful: 'Registration was successful.',
    password_reset_no_input: 'You must fill in both password fields.',
    password_reset: 'Password has been reset.',
    payment: {
      remove_card: 'Successfully removed payment data.',
      add_card: 'Successfully added payment data.',
      add_billing_address_success: 'Successfully added billing address.',
      add_billing_address_info: 'Payments will not be available until you add billing address.'
    },
    analytics: {
      ga_success: 'Successfully granted Google Analytics access.',
      ga_in_progress: 'Google Analytics data pull is in progress, data will appear on the dashboard when the pull is finished.',
      onboarding_no_data: 'Please be patient. Your data is being generated and will be shown within the next few minutes.',
      no_data: 'There is no data available in the date range selected.',
      generating_no_data: 'ESP data is currently generating. Please check again later.',
      no_users_data: 'There is no Users data available in the date range selected.',
      ga_update_success: 'Successfully updated Google Analytics Account.',
      ga_revoked: 'Google Analytics access is revoked. You will need to integrate Google Analytics again.'
    },
    mailchimp: {
      success: 'Successfully completed MailChimp integration via OAuth.'
    },
    contact: {
      cs: 'Message sent to Customer Success Team.'
    },
    organization_deleted: 'Organization successfully deleted.',
    hi_iq: {
      account_updated: 'Hi-iQ account successfully updated.',
      no_account_data: 'There is no Hi-iQ account data available.',
      engaged_criteria_disabled: 'Engaged Criteria fields are currently disabled because there is an active experiment running.'
    },
    ecosystem: {
      platforms_connected: 'Platforms successfully connected.',
      connections_deleted: 'Connections successfully deleted.'
    },
    notification: {
      created: 'Notification successfully created.',
      updated: 'Notification successfully updated.',
      deleted: 'Notification successfully deleted.',
      repeating_stopped: 'Notification repeating successfully stopped.',
      suppressed: 'Notifications successfully suppressed.',
      no_users_for_suppression: 'There is no users for notifications suppression.',
      confirmDelete: 'Are you sure you want to delete the notification?'
    },
    review: {
      success: 'Thank you for your review.'
    },
    no_products: 'This instance has no products available.',
    no_product: 'Product not found.'
  },
  gaAccessTitle: 'Google Analytics Access',
  gaConnectLabel: 'Connect Google Analytics',
  gaNotIntegrated: 'To see revenue data at a campaign level, connect your Google Analytics account.',
  accessRights: {
    'admin': false,
    'marketplace': false,
    'trend-analysis': false,
    'access-rights': false,
    'payment-options': false,
    'hi-iq': false,
    'bye-iq': false,
    'retry-iq': false
  },
  notAvailable: 'N\\A',
  colors: {
    success: '#12CE66',
    error: '#FF0242',

    chart_blue: '#21B6A8',
    chart_blue_opacity: '#21B6A8',
    chart_blue_dark: '#1A83D3',
    chart_blue_dark_opacity: 'rgba(26, 131, 211, .8)',
    chart_light_blue: '#AEC7E8',
    chart_orange: '#FFB001',
    chart_orange_opacity: 'rgba(255, 155, 29, .6)',
    chart_orange_dark: '#E18817',
    chart_green: '#BFE7CC',
    chart_green_opacity: 'rgba(191, 231, 204, .8)',
    chart_green_2: '#66BC48',
    chart_green_opacity_2: 'rgba(102, 188, 72, .6)',
    chart_green_dark: '#61B37A',
    chart_trendline_color: '#EACE2A',
    chart_trendline_dark: '#E7C91C',
    chart_trendline_opacity: 'rgba(234, 206, 42, .8)',
    chart_transparent: 'rgba(0, 0, 0, 0)',
    chart_red: '#FF365E',

    chart_green_color: '#06B6B5',
    chart_green_prediction_border: '#CFE0CC',

    brand_bye_iq: '#1B94F5',
    brand_bye_iq_second: '#D98318',
    brand_bye_iq_opacity: 'rgba(255, 155, 29, .15)',
    brand_hi_iq: '#FF365E',
    brand_hi_iq_second: '#E12F52',
    brand_hi_iq_opacity: 'rgba(255, 54, 94, .15)',
    brand_retry_iq: '#1FC6EA',
    brand_retry_iq_second: '#1BADCC',
    brand_retry_iq_opacity: 'rgba(31, 198, 234, .15)',
    brand_email_report: '#FF6D2F',

    hi_iq_border: 'rgba(255, 54, 94, .5)',
    hi_iq_border_second: 'rgba(225, 47, 82, .5)',

    bye_iq_border: 'rgba(255, 155, 29, .5)',
    bye_iq_border_second: 'rgba(217, 131, 24, .5)',

    retry_iq_border: 'rgba(31, 198, 234, .5)',
    retry_iq_border_second: 'rgba(27, 173, 204, .5)',

    predicted_green: '#A3EBB1',
    predicted_green_opacity: 'rgba(108, 199, 136, .8)',
    table_row_orange: '#FFEBD5',
    brand_color_hi_iq: '#FB475E',
    brand_color_hi_iq_opacity: '#FB475E',
    default_chart_color: 'rgb(102, 106, 115)',

    transparent: 'rgba(0, 0, 0, 0)',
    white_color_opacity: 'rgba(255, 255, 255, .75)'
  },
  print: {
    chart_full: 900,
    chart_half: 450,
    chart_third: 300
  },
  urls: {
    corporate_site: {
      amphoria: 'https://amphoria.io/',
      elixusagency: 'https://www.elixusagency.com',
      ematicsolutions: 'https://www.ematicsolutions.com',
      hiiq: 'https://hiiq.ai/'
    },
    magento_docs: 'https://ematicexternal.github.io/doc/ecommerce-plugins/ecommerce-plugins-magento-1-x.html',
    shopify_docs: 'https://ematicexternal.github.io/doc/ecommerce-plugins/ecommerce-plugins-shopify.html',
    woo_commerce_docs: 'https://ematicexternal.github.io/doc/ecommerce-plugins/ecommerce-plugins-woocommerce.html',
    haravan_docs: 'https://ematicexternal.github.io/doc/ecommerce-plugins/ecommerce-plugins-haravan.html',
    integrate_ga: 'https://ematicexternal.github.io/doc/new-to-ematic/integrate-google-analytics.html',
    ematic_js: 'https://ematicexternal.github.io/doc/quick-start/initializing-ematic-js.html',
    general: 'https://ematicexternal.github.io/doc/faq/general-faq.html',
    hi_iq: 'https://ematicexternal.github.io/doc/faq/hi-iq-faq.html',
    bye_iq: 'https://ematicexternal.github.io/doc/faq/bye-iq-faq.html',
    retry_iq: 'https://ematicexternal.github.io/doc/faq/retry-iq-faq.html',
    e_commerce_integrations: 'https://ematicexternal.github.io/doc/faq/e-commerce-integration-faq.html',
    esp_integrations: 'https://ematicexternal.github.io/doc/faq/esp-email-sender-provider-faq.html',
    privacy_policy: 'https://www.ematicsolutions.com/privacy-and-data-protection-policy/',
    tos: 'https://www.ematicsolutions.com/tos/',
    stripe_privacy_policy: 'https://stripe.com/privacy'
  },
  userStates: {
    active: 'active',
    invited: 'invited',
    inactive: 'inactive',
    deleted: 'deleted'
  },
  userStateActions: {
    restore: 'restore',
    disable: 'disable',
    delete: 'delete',
    resend: 'resend'
  },
  productStatus: {
    active: 'active'
  },
  contactUs: {
    label: 'Let\'s get your account sorted. Leave a message and the Customer Success Team will be in touch shortly.',
    title: 'How can we help you?',
    linkText: 'You can find our best practices, FAQ\'s and guides',
    linkValue: 'https://ematicexternal.github.io/doc/faq/general-faq.html'
  },
  onBoardStates: {
    stateOneTooltip: 'Step 1: Invite and Registration',
    stateTwoTooltip: 'Step 2: Connect your ESP',
    stateOneDrawer: [
      'You completed your registration, now you can give us access to your ESP account.',
      'We provide wide support for Email Service Providers and the number is growing.'
    ],
    stateTwoDrawer: [
      'On this page you can connect your ESP account and provide us access to it through the OAuth process or by manual input.',
      'Currently, we support OAuth for MailChimp and Campaign Monitor accounts, but other ESP\'s will be supported soon.',
      'For ESP\'s that are not listed, you can reach to us through "Other ESP" option and someone from our customer support team will contact you.'
    ],
    stateOneTitle: 'Invite and Registration',
    stateTwoTitle: 'Connect your ESP'
  },
  adminRoles: [
    'CSM',
    'CSE',
    'CSE_ADMIN',
    'SUPER_ADMIN'
  ],
  adminRolePermissions: [
    'GRANT_ALL_ORGANIZATIONS',
    'ORGANIZATION_READ',
    'INSTANCE_READ'
  ],
  automatedIntegrationEsps: {
    mailchimp: {
      text: 'MailChimp',
      type: 'mailchimp',
      imageName: 'mailchimp',
      oAuth: true,
      colClass: 'col-6',
      link: 'https://admin.mailchimp.com/referral?code=t9eJ1_47-_elMwY'
    },
    campaignMonitor: {
      text: 'Campaign Monitor',
      type: 'campaignmonitor',
      imageName: 'campaign-monitor',
      oAuth: true,
      colClass: 'col-6'
    },
    getResponse: {
      text: 'GetResponse',
      type: 'getresponse',
      imageName: 'getresponse',
      oAuth: false,
      colClass: 'col-4',
      link: 'https://www.getresponse.com/start-free-trial'
    },
    emarsys: {
      text: 'Emarsys',
      type: 'emarsys',
      imageName: 'emarsys',
      colClass: 'col-4',
      oAuth: false,
      link: 'https://emarsys.com/demo/'
    },
    mailjet: {
      text: 'Mailjet',
      type: 'mailjet',
      imageName: 'mailjet',
      oAuth: false,
      colClass: 'col-4',
      link: 'https://app.mailjet.com/signup'
    }
  },
  otherEsps: [
    {
      title: 'Oracle Responsys',
      imageUrl: 'assets/img/onboarding/other/oracle-responsys.png',
      imageUrlRetina: 'assets/img/onboarding/other/oracle-responsys-2x.png'
    },
    {
      title: 'Adobe Campaign',
      imageUrl: 'assets/img/onboarding/other/adobe-campaign.png',
      imageUrlRetina: 'assets/img/onboarding/other/adobe-campaign-2x.png'
    },
    {
      title: 'SalesForce',
      imageUrl: 'assets/img/onboarding/other/salesforce.png',
      imageUrlRetina: 'assets/img/onboarding/other/salesforce-2x.png'
    },
    {
      title: 'SendGrid',
      imageUrl: 'assets/img/onboarding/other/sendgrid.png',
      imageUrlRetina: 'assets/img/onboarding/other/sendgrid-2x.png'
    },
    {
      title: 'Iterable',
      imageUrl: 'assets/img/onboarding/other/iterable.png',
      imageUrlRetina: 'assets/img/onboarding/other/iterable-2x.png'
    },
    {
      title: 'OneSignal',
      imageUrl: 'assets/img/onboarding/other/onesignal.png',
      imageUrlRetina: 'assets/img/onboarding/other/onesignal-2x.png'
    },
    {
      title: 'Segment',
      imageUrl: 'assets/img/onboarding/other/segment.png',
      imageUrlRetina: 'assets/img/onboarding/other/segment-2x.png'
    },
    {
      title: 'Tealium',
      imageUrl: 'assets/img/onboarding/other/tealium.png',
      imageUrlRetina: 'assets/img/onboarding/other/tealium-2x.png'
    },
    {
      title: 'mParticle',
      imageUrl: 'assets/img/onboarding/other/mparticle.png',
      imageUrlRetina: 'assets/img/onboarding/other/mparticle-2x.png'
    },
    {
      title: 'Adjust',
      imageUrl: 'assets/img/onboarding/other/adjust.png',
      imageUrlRetina: 'assets/img/onboarding/other/adjust-2x.png'
    },
    {
      title: 'Branch',
      imageUrl: 'assets/img/onboarding/other/branch.png',
      imageUrlRetina: 'assets/img/onboarding/other/branch-2x.png'
    },
    {
      title: 'AppsFlyer',
      imageUrl: 'assets/img/onboarding/other/appsflyer.png',
      imageUrlRetina: 'assets/img/onboarding/other/appsflyer-2x.png'
    },
    {
      title: 'Google Analytics',
      imageUrl: 'assets/img/onboarding/other/google-analytics.png',
      imageUrlRetina: 'assets/img/onboarding/other/google-analytics-2x.png'
    },
    {
      title: 'Mixpanel',
      imageUrl: 'assets/img/onboarding/other/mixpanel.png',
      imageUrlRetina: 'assets/img/onboarding/other/mixpanel-2x.png'
    },
    {
      title: 'Amplitude',
      imageUrl: 'assets/img/onboarding/other/amplitude.png',
      imageUrlRetina: 'assets/img/onboarding/other/amplitude-2x.png'
    },
    {
      title: 'Other ESP',
      imageUrl: 'assets/img/onboarding/other/other-esp.png',
      imageUrlRetina: 'assets/img/onboarding/other/other-esp-2x.png'
    }
  ],
  onlineStore: [
    {
      title: 'Shopify',
      alt: 'Shopify',
      imageUrl: 'assets/img/roi/stores/shopify.png',
      imageUrlRetina: 'assets/img/roi/stores/shopify-2x.png'
    },
    {
      title: 'Magento',
      alt: 'Magento',
      imageUrl: 'assets/img/roi/stores/magento.png',
      imageUrlRetina: 'assets/img/roi/stores/magento-2x.png'
    },
    {
      title: 'WooCommerce',
      alt: 'WooCommerce',
      imageUrl: 'assets/img/roi/stores/woocommerce.png',
      imageUrlRetina: 'assets/img/roi/stores/woocommerce-2x.png'
    }
  ],
  push: [
    {
      title: 'One Signal',
      alt: 'One Signal',
      imageUrl: 'assets/img/roi/push/onesignal.png',
      imageUrlRetina: 'assets/img/roi/push/onesignal-2x.png'
    },
    {
      title: 'Insider',
      alt: 'Insider',
      imageUrl: 'assets/img/roi/push/insider.png',
      imageUrlRetina: 'assets/img/roi/push/insider-2x.png'
    }
  ],
  sms: [
    {
      title: 'Twilio',
      alt: 'Twilio',
      imageUrl: 'assets/img/roi/sms/twilio.png',
      imageUrlRetina: 'assets/img/roi/sms/twilio-2x.png'
    },
    {
      title: 'Messagebird',
      alt: 'Messagebird',
      imageUrl: 'assets/img/roi/sms/messagebird.png',
      imageUrlRetina: 'assets/img/roi/sms/messagebird-2x.png'
    }
  ],
  emailOAuth: [
    {
      title: 'MailChimp',
      alt: 'MailChimp',
      imageUrl: 'assets/img/roi/email/mailchimp.png',
      imageUrlRetina: 'assets/img/roi/email/mailchimp-2x.png'
    },
    {
      title: 'Campaign Monitor',
      alt: 'Campaign Monitor',
      imageUrl: 'assets/img/roi/email/campaign-monitor.png',
      imageUrlRetina: 'assets/img/roi/email/campaign-monitor-2x.png'
    }
  ],
  platform: {
    logos: {
      amphoria: {
        color: {
          imageDpr1: 'assets/img/platform/amphoria/logo/logo-color.png',
          imageDpr2: 'assets/img/platform/amphoria/logo/logo-color-2x.png'
        },
        white: {
          imageDpr1: 'assets/img/platform/amphoria/logo/logo-white.png',
          imageDpr2: 'assets/img/platform/amphoria/logo/logo-white-2x.png'
        }
      },
      elixusagency: {
        color: {
          imageDpr1: 'assets/img/platform/elixusagency/logo/logo-color.png',
          imageDpr2: 'assets/img/platform/elixusagency/logo/logo-color-2x.png'
        },
        white: {
          imageDpr1: 'assets/img/platform/elixusagency/logo/logo-white.png',
          imageDpr2: 'assets/img/platform/elixusagency/logo/logo-white-2x.png'
        }
      },
      ematicsolutions: {
        color: {
          imageDpr1: 'assets/img/platform/ematicsolutions/logo/logo-color.png',
          imageDpr2: 'assets/img/platform/ematicsolutions/logo/logo-color-2x.png'
        },
        white: {
          imageDpr1: 'assets/img/platform/ematicsolutions/logo/logo-white.png',
          imageDpr2: 'assets/img/platform/ematicsolutions/logo/logo-white-2x.png'
        }
      },
      hiiq: {
        color: {
          imageDpr1: 'assets/img/platform/hiiq/logo/logo-color.png',
          imageDpr2: 'assets/img/platform/hiiq/logo/logo-color-2x.png'
        },
        white: {
          imageDpr1: 'assets/img/platform/hiiq/logo/logo-white.png',
          imageDpr2: 'assets/img/platform/hiiq/logo/logo-white-2x.png'
        }
      }
    },
    userAvatars: {
      amphoria: {
        imageDpr1: 'assets/img/platform/amphoria/user-avatar/user-avatar.png',
        imageDpr2: 'assets/img/platform/amphoria/user-avatar/user-avatar-2px.png'
      },
      elixusagency: {
        imageDpr1: 'assets/img/platform/elixusagency/user-avatar/user-avatar.png',
        imageDpr2: 'assets/img/platform/elixusagency/user-avatar/user-avatar-2px.png'
      },
      ematicsolutions: {
        imageDpr1: 'assets/img/platform/ematicsolutions/user-avatar/user-avatar.png',
        imageDpr2: 'assets/img/platform/ematicsolutions/user-avatar/user-avatar-2px.png'
      },
      hiiq: {
        imageDpr1: 'assets/img/platform/hiiq/user-avatar/user-avatar.png',
        imageDpr2: 'assets/img/platform/hiiq/user-avatar/user-avatar-2px.png'
      }
    }
  },
  defaultAvatarPath: 'assets/img/avatar-default.png',
  googleErrorCodes: {
    idpiframeInitializationFailed: 'idpiframe_initialization_failed',
    popupClosedByUser: 'popup_closed_by_user',
    accessDenied: 'access_denied',
    immediateFailed: 'immediate_failed'
  },
  retryIqDataPeriod: {
    yearlyLabel: 'Yearly Retry-iQ Runs',
    monthlyLabel: 'Monthly Retry-iQ Runs',
    weeklyLabel: 'Weekly Retry-iQ Runs',
    dailyLabel: 'Daily Retry-iQ Runs',
    yearlyAcrossAll: 'Across all years',
    monthlyAcrossAll: 'Across all months',
    weeklyAcrossAll: 'Across all weeks',
    dailyAcrossAll: 'Across all days'
  },
  hiIqControlGroup: {
    dataType: {
      modeled: 'modeled',
      actual: 'actual'
    }
  },
  hiIqLabels: {
    rollUp: {
      stats: 'Hi-iQ Audience',
      control_stats: 'Control Group',
      lift: 'Lift'
    },
    rehabChart: {
      hiIqSubscribers: 'Hi-iQ Subscribers',
      controlGroupSubscribers: 'Control Group Subscribers'
    },
    statsChart: {
      engaged: 'Engaged',
      newlyNonEngaged: 'Newly Non-Engaged',
      highlyDisengaged: 'Highly Disengaged',
      controlGroup: 'Control Group'
    },
    hiIqVsControlGroupChart: {
      hiIq: 'Hi-iQ',
      controlGroup: 'Control Group'
    }
  },
  campaignMonitor: {
    localStorageKeys: {
      account: 'campaignMonitorAccount'
    }
  },
  hiIqCampaignsData: {
    all: {
      id: 'all',
      text: 'All'
    },
    used: {
      id: 'used',
      text: 'Hi-iQ Used'
    },
    unused: {
      id: 'unused',
      text: 'Hi-iQ Unused'
    }
  },
  dashboard: {
    localStorageKeys: {
      showNotificationsTab: 'showNotificationsTab'
    }
  },
  productDates: {
    hiIq: {
      startDate: 'Hi-iQ Start Date',
      endDate: 'Hi-iQ End Date'
    },
    byeIq: {
      startDate: 'Bye-iQ Start Date',
      endDate: 'Bye-iQ End Date'
    },
    retryIq: {
      startDate: 'Retry-iQ Start Date',
      endDate: 'Retry-iQ End Date'
    }
  },
  chart: {
    mobileDimension: 720,
    noDataMessage: 'There is no data available for this dataset.'
  },
  byeIqDbData: {
    predictedDBValueFor1year: {
      text: 'Predicted DB value for 1 year',
      icon: 'icon-money-2',
      color: '#0AD16A',
      description: 'Predicted database value for the next year.'
    },
    predictedDBValueFor3years: {
      text: 'Predicted DB value for 3 years',
      icon: 'icon-money-1',
      color: '#00C66D',
      description: 'Predicted database value for the next three years.'
    },
    presentDatabaseValue: {
      text: 'Present database value',
      icon: 'icon-db-value-1',
      color: '#F99D2E',
      description: 'Current value of Bye-iQ database.'
    },
    subscribersLifetimeValue: {
      text: 'Subscribers lifetime value',
      icon: 'icon-subs-value',
      color: '#459DF6',
      description: 'Average monthly amount expected from each subscriber and divide it by churn rate.'
    }
  },
  esps: {
    googleAnalytics: {
      type: 'googleAnalytics',
      text: 'Google Analytics'
    },
    campaignMonitor: {
      type: 'campaignmonitor',
      text: 'Campaign Monitor'
    },
    mailchimp: {
      type: 'mailchimp',
      text: 'MailChimp'
    }
  },
  noAdminPermissions: {
    label: 'If you don\'t have admin permissions let us know by clicking the button below.',
    buttonText: 'Notify Ematic Customer Success Team',
    message: 'I don\'t have admin permissions for my account so I cannot complete OAuth. Please let me know how can I connect with my account.'
  },
  integrations: {
    removeGA: 'Are you sure that you want to remove your GA integration?',
    progressMessageEmailPerformanceCreation: 'Creation of your Email Performance product is in progress, it shouldn’t take long.',
    mediumsTooltip: 'Mediums \'email\', \'mail\' and \'edm\' are selected by default.',
    gaDataSetInUse: 'GA still exists on: ',
    suggestMarTech: 'Thank you for your suggestion. Our team will review your request and get back to you shortly.',
    talkToUs: 'Your request has been submitted successfully. Our team will get back to you shortly.'
  },
  billingAndSubscriptions: {
    email: 'billing@ematicsolutions.com'
  },
  toastr: {
    timeout: 60000
  },
  gaEvent: {
    categories: {
      submit: 'Submit',
      open: 'Open'
    },
    labels: {
      onboarding: 'Onboarding',
      accessRights: 'Access Rights',
      connectESP: 'Connect ESP',
      billing: 'Billing'
    }
  },
  byeIqDataPeriod: {
    yearAcrossAll: 'Across all years',
    monthAcrossAll: 'Across all months',
    weekAcrossAll: 'Across all weeks',
    dayAcrossAll: 'Across all days'
  },
  localStorageKeys: {
    showGaModal: 'showGaModal'
  },
  hiIqMailingFrequencies: [
    'Once a week',
    'Twice a week',
    'Three times a week',
    'Four times a week',
    'Five, six or seven times a week',
    'Twice a month'
  ],
  hiIqEngagedCriteria: {
    common: [
      { name: 'subbed_days_ago', label: 'Subbed Days Ago', tooltip: 'Days within which users have subscribed.' },
      { name: 'opened_days_ago', label: 'Opened Days Ago', tooltip: 'Days within which users have opened a campaign.' },
      { name: 'clicked_days_ago', label: 'Clicked Days Ago', tooltip: 'Days within which users have clicked a campaign.' }
    ]
  },
  token: 'token',
  helpCenter: {
    general: [
      {
        header: 'What is the signup process?',
        content: 'A signup process is a manual enlisting for an Ematic plan. It entails filling out forms and signing a contract.'
      },
      {
        header: 'How do I integrate my Google Analytics data to Ematic?',
        content: 'To project a robust performance on your Ematic Dashboard, you will need to integrate Google Analytics. Learn how: Integrate Google Analytics.'
      },
      {
        header: 'Do you provide assistance in content creation?',
        content: 'Your Customer Success Managers conduct a review on campaigns that are. scheduled to be sent out, they can suggest ways to present your content, however, you must supply all content.'
      },
      {
        header: 'Do you have a 24/7 support team?',
        content: 'Your Customer Success Managers can support and accommodate all queries between 9:00 - 18:00 your local time.'
      },
      {
        header: 'How do we contact Ematic?',
        content: 'You can contact your Customer Success Manager by email using the given distribution list.'
      },
      {
        header: 'When will I start getting charged?',
        content: 'Right after the order form has been signed, an invoice is sent to indicate the agreed amount has been charged to the credit card on file.'
      },
      {
        header: 'What browsers will work for Ematic products?',
        content: 'The Performance Dashboard is a web-based application that can be supported by Safari, Google Chrome, Firefox, Internet Explorer, and Microsoft Edge.'
      },
      {
        header: 'What are the accepted modes of payments?',
        content: 'Credit cards are accepted and processed by Stripe through the Performance Dashboard.'
      },
      {
        header: 'Is it better to have a dedicated IP address for sending email campaigns instead of using the default IP address of our ESPs?',
        content: 'We can’t answer with a straight yes or a straight no, both circumstances have pros and cons. When you start using new IP addresses that ISPs are unfamiliar with, it may take them a while for the ISP to realize that it is not SPAM and that your IP address operates legitimately. This does not occur when you use the default IP address of your ESP because it is already known to ISPs. To help you decide which will be more beneficial for your organization, see Should You Send From a Dedicated IP Address? (Obtained from MailChimp on October 17, 2017 available on blog.mailchimp.com).'
      },
      {
        header: 'How much are the Ematic products?',
        content: 'Ematic product pricing depends on the number of your subscribers. The basic monthly plan for Hi-iQ starts at USD99 for customers with 0 to 25,000 subscribers. Go to Ematic Pricing to get a quote for our Hi-iQ product.'
      },
      {
        header: 'What is a Pilot phase? How long is it?',
        content: 'Pilot phase is the stage where you can try and get a feel of your preferred Ematic plan. It can take up to three months.'
      },
      {
        header: 'Does Ematic charge in SGD or USD?',
        content: 'Ematic charges in USD.'
      },
      {
        header: 'What are the e-Commerce platforms I can integrate with?',
        content: 'You can integrate with Magento, Shopify, Haravan, Big Commerce and WooCommerce. Learn more in our API Doc.'
      },
      {
        header: 'I don’t have an engineer. Does Ematic provide the setup service?',
        content: 'We do have Customer Success Engineers (CSE) who can assist you with setting up our products.'
      },
      {
        header: 'How do I cancel my plan?',
        content: 'As much as we hate seeing you leave, we will respect your decision. Please advise your Customer Success Manager and allow us 30 days to process your request.'
      }
    ],
    hiIq: [
      {
        header: 'How does Hi-iQ work?',
        content: 'Hi-iQ, Ematic’s flagship AI product, uses behavioural data to predict who is responding positively or negatively to your digital marketing campaigns. Our algorithm is tailored to your send frequency, among other variables, to ensure that you are only sending mail that’s likely to be opened. Your data is crunched daily and a new list of subscribers identified as ‘likely to open’ is posted to your ESP for you to target. Reducing sends to subscribers who are not engaging with your content is proven to maintain the health of your database longterm, and a healthy database is a prosperous database.'
      },
      {
        header: 'What is a rehabilitation (rehab) schedule and how does it work?',
        content: 'A rehab schedule is a set time where each user is put into different time-off periods based on their level of non-engagement. Any user that completes the time-off period is collected back by Hi-iQ for the next campaign. Based on the user’s interaction with this campaign, Hi-iQ identifies the user engagement level and recalculates. If a user still doesn’t engage with the campaign, they will be moved further down the schedule where they wait for a longer duration.'
      },
      {
        header: 'If I use Hi-iQ, does it mean I will not mail a portion of my users ever?',
        content: 'No, Hi-iQ does not pick the same users everyday. The users who are identified as non-engaged, serve a particular time-off period. This means that they won’t receive all campaigns in-order to make sure that we don’t spam the user with too many campaigns. In short, the user who is not picked up today, will be picked up in the next few days once they finish serving their time-off. Hence, all users will be mailed but at a different frequency.'
      },
      {
        header: 'Why is the count of Hi-iQ segment fluctuating everyday?',
        content: 'Hi-iQ picks up 2 sets of users from the database: engaged and non-engaged users that have served their time off. The engaged audience is expected to be a steady or growing number month over month. However, the number of users that finished the time-off might differ each day. This set of users lead to a fluctuating size of Hi-iQ everyday. As a whole, if the segment count goes down, you can check the Ematic Dashboard to see if the Engaged users count for the day is in line with the previous days. If that is line, there is nothing to worry about.'
      },
      {
        header: 'What is a Long Term Reactivation (LTR)? How does it work?',
        content: 'Long Term Reactivation is the process of identifying the most non-engaged percentage of users from the existing list on your ESP and moving them out in order to save cost and also improve overall performance of the customer database. There are 2 steps for Long Term Reactivation Retirement: On a monthly basis (after pilot phase) we identify the most disengaged 1% of users and unsubscribe them from the account. We move them over to a centralized Ematic account where we manage the disengaged users for all our customers. Reactivation: On a monthly basis, a reactivation campaign is chosen by the customer and the Ematic Customer Success team to send to these non-engaged users in the LTR setup. Once a user opens a campaign from this LTR setup, we move them back into the customer’s main account. The users collected for Reactivation campaign are based on the following schedule:',
        list: [
          'Retired 1 month before',
          '3 months before',
          '6 months before',
          '9 months before',
          '12 months',
          '15 months'
        ]
      }
    ],
    byeIq: [
      {
        header: 'How does Bye-iQ work?',
        content: 'Bye-iQ turns website visitors in to subscribers by collecting emails via overlays or commonly known as popups. Bye-iQ works on mobile too. Overlays are triggered by user behaviours on your website such as exit intent, page scroll, reverse page scroll and specified time on site. These triggers could be further customised to sessions, visits and even GIFs. You can dangle promotion codes too to lock in more subscribers.'
      },
      {
        header: 'What are the implementation parameters?',
        content: 'Bye-iQ overlays display when a user is not logged in and falls under the following conditions: Has never seen the Bye-iQ overlay, Has never provided any email address through the overlay. If a user fits the above criteria, the overlays are displayed when your users observe the following: Exit Intent: user tries to exit the browser on desktop (ie) moves the cursor outside the browser frame (towards the URL bar or above). This is activated on Desktop sites, Scroll: User scrolls 50% of the website. This is currently activated on mobile and tablet devices, Time on Site: User spends 180 or X seconds on the website and hasn’t closed the website, then we show the overlay. This is activated on mobile and tablet as well. The above are the default triggers that we set up. We can add the Scroll and Time on Site to desktop too but we usually start with the above triggers and slowly increase the other triggers. In addition to this, we also have the following triggers available for Desktop, Mobile and Tablet devices as well: Entry trigger: When a user lands on the website the overlay shows up, CART trigger: All of the above triggers (ie) Exit Intent, Scroll, Time on Site and Entry trigger can be triggered additionally to users if they have products in their cart, Time on page (TOP) trigger: This is similar with time on site (TOS), but the only difference is that it is limited to a single page, and not a website, Reverse scroll trigger: It is the ability to configure the % of reverse scroll trigger. This means that a Bye-iQ overlay will be displayed when the website visitor scrolls to the % (customisable) of the full height of the browser and scroll up 10% of the height of the page.'
      },
      {
        header: 'When does the overlay appear? On which pages?',
        content: 'As long as the Bye-iQ JS is implemented on all pages, the users are eligible to see the overlay anywhere on the website. Keep in mind that, if the script is not implemented on all pages, the overlay will be limited to the pages in which the script is implemented. However, in order to not spam users with too many overlays on the same page, we limit to only one overlay/trigger per page. Depending on whichever action the user performs, the appropriate overlay will show.'
      },
      {
        header: 'Where do the email addresses collected by Bye-iQ go to, and what is it used for?',
        content: 'Email address collected via Bye-iQ is stored in your ESP account. It will be used to send out marketing emails, newsletters, and other marketing-related campaigns. You can also group these email addresses based on captured triggers, meaning you can categorise email addresses based on the triggers that made your users submit their email addresses.'
      },
      {
        header: 'Can we have a copy of the email addresses collected?',
        content: 'Yes, email addresses captured via Bye-iQ is stored in YOUR ESP account therefore you can do whatever you want. However, there may be some cases where Ematic would need to have some control over certain email addresses and groups, such as those used for Hi-iQ, to maximise your ROI.'
      },
      {
        header: 'What can be customized on our Bye-iQ plan?',
        content: 'A/B testing and Control Group testing: Allows you to gauge whether your Bye-iQ strategy is the most efficient. Can appear count: The number of times the overlay can appear until the cookies are cleared either manually or automatically expired. Timer: How soon or how long it takes to trigger the overlay. It is either set at Time on Page or Time on Site. ESC key: The option to escape the overlay by hitting the Escape key. Enter key: Hitting the Enter key to submit form. Autofill: Enable autofill on the overlay. Show invalid Email alert: Alerts users when the email entered is invalid. Placeholder: text for email text box. This can be customized by color, font, and font size. Position: From where the overlay can be shown:',
        list: [
          'Center',
          'Upper Left Corner',
          'Upper Right Corner',
          'Lower Left Corner',
          'Lower Right Corner'
        ]
      }
    ],
    retryIq: [
      {
        header: 'How does Retry-iQ work?',
        content: 'Retry-iQ is Ematic’s automations suite, retargeting your subscribers based on their interactions and behaviours on your website, or mobile application. By sending templates with dynamic content based on the data we received for a subscriber’s session, Retry-iQ helps you make the most out of missed opportunities for conversion, automatically. There are various campaign types that can be configured to your requirements, with content specific to your brand. Retry-iQ has split testing capability, and the power to send a series of messages with varying content each time. Retry-iQ is designed not to spam, so you will only send an acceptable volume of mail to a single subscriber. The suppression rules are defaulted based on our data analysis, but can be configured to be more or less aggressive, depending on your preferences.'
      },
      {
        header: 'Is it possible for a user to get 2 of the same messages on the same day?',
        content: 'Ideally no. We have a configurable suppression logic that can be set to send only one of the Retry-iQ messages in a day. However, it can be done if there is a need to send more than one to the same user.'
      },
      {
        header: 'How many follow-ups can I send for each message?',
        content: 'A maximum of 10 follow-ups can be sent for each of the Retry-iQ message. However, the performance will drastically drop after each message, and there might not be much effect from messages down in the lifecycle.'
      },
      {
        header: 'How are products chosen in the AC and ABP message? Is there an order?',
        content: 'A maximum of 5 products are chosen for both AC and ABP. The logic behind choosing the 5 products is:',
        list: [
          'Abandoned Cart: Top 5 products based on descending order of unit price.',
          'Abandoned Browse: Recently browsed 5 products with the most recent at the top'
        ]
      }
    ],
    ecomm: [
      {
        header: 'What are the e-Commerce platforms Ematic can integrate with?',
        content: 'Ematic integrates well with Shopify, Magento, WooCommerce and Haravan.'
      },
      {
        header: 'How do I integrate with Ematic products?',
        content: 'You can refer to our API Document to integrate your e-Commerce store with Ematic products.'
      },
      {
        header: 'Can I still integrate with Ematic if my e-Commerce store is not hosted on any of the platforms Ematic integrates with?',
        content: 'Yes, you can install Ematic.JS or contact our Customer Success team for assistance.'
      }
    ],
    esp: [
      {
        header: 'What is an ESP?',
        content: 'ESP stands for Email Sender Provider. It is a company or a service that acts as a vessel to send out your email marketing campaigns.'
      },
      {
        header: 'Does my current ESP still send out my marketing emails in conjunction with my Ematic products?',
        content: 'Yes, your ESP will continue to be the sender of your marketing emails. That process will not change. Ematic products add an intelligence layer to your subscriber list and returns an optimised list of subscribers directly to your ESP. This will allow you to choose your target for your subsequent email campaigns.'
      },
      {
        header: 'Which ESPs can integrate with Ematic?',
        content: 'Ematic can integrate with Mailchimp, Campaign Monitor, Salesforce, GetResponse, Emarsys, and Responsys. If you are using any of the mentioned ESPs, that’s half the setup process, so let’s plug and play.'
      },
      {
        header: 'Why can I use only one MailChimp account?',
        content: 'Ematic insists on using only one ESP account for the following reasons:',
        list: [
          'Cost: 1 subscriber that exists in 2 lists, is charged twice.',
          'Compliant Unsubscription: an unsubscription should be final, not specific to a single list. You run the risk of being reported for abuse or marked as Spam.',
          'Ease of administration: A MailChimp list has grouping and segmentation features, that more than satisfy your need to segment your subscribers.'
        ]
      },
      {
        header: 'Is there a security layer when using ESPs?',
        content: 'We integrate and communicate with ESPs through secure API calls using HTPPS. Data stored is encrypted.'
      },
      {
        header: 'Will Ematic change our mailing process?',
        content: 'No, you will still have control over how you set up campaigns the same way you usually do with your ESPs. However, another list that will allow you to choose the targeting for your campaigns will be available when Hi-iQ is implemented.'
      }
    ]
  },
  martech: {
    ecosystem_categories: {
      customerDataPlatform: {
        key: 'cdp',
        value: 'Customer Data Platform'
      },
      messaging: {
        key: 'messaging',
        value: 'Messaging'
      },
      attribution: {
        key: 'attribution',
        value: 'Attribution'
      },
      analytics: {
        key: 'analytics',
        value: 'Analytics'
      }
    }
  },
  multiChart: {
    placeholder: {
      selectProperty: 'Select Chart Property...',
      multiplePropertiesSelected: 'Multiple Properties Selected.'
    }
  },
  martechConnectionTable: {
    filterNames: {
      firstMartechName: 'firstMartechName',
      secondMartechName: 'secondMartechName',
      status: 'status'
    }
  },
  adminTool: {
    text: 'Admin Tool'
  },
  notification: {
    types: {
      info: {
        text: 'Info',
        icon: 'info'
      },
      warning: {
        text: 'Warning',
        icon: 'warning'
      },
      error: {
        text: 'Error',
        icon: 'error'
      },
      general: {
        text: 'General',
        icon: 'notifications'
      }
    },
    noNotificationsMessage: 'There are no notifications available.',
    text: 'Notifications',
    customTags: [
      'Hi-iQ',
      'Bye-iQ',
      'Retry-iQ',
      'MarTech',
      'Other'
    ],
    groups: [
      'Inbox',
      'Suppressed'
    ]
  },
  trendAnalysisChartMetric: 'trendAnalysisChartMetric',
  trendAnalysisProducts: {
    primary: { // primary products are ordered by priority, should not be changed
      emailPerformance: 'email-performance',
      hiIq: 'hi-iq',
      byeIq: 'bye-iq',
      retryIq: 'retry-iq'
    },
    nonPrimary: {
      smsPerformance: 'sms-performance',
      pushPerformance: 'push-performance',
      webPushPerformance: 'web-push-performance',
      historic: 'historic',
      facebookAd: 'facebook-ad',
      eCommerce: 'e-commerce',
      optimonk: 'capture',
      segment: 'cdp',
      adjust: 'attribution',
      mixpanel: 'analytics'
    },
    derived: {
      runStats: 'run-stats'
    }
  },
  facebookDateRangeLimit: 3, // 3 years from now
  roi: {
    products: {
      email: {
        name: 'Email',
        type: 'primary'
      },
      facebook: {
        name: 'Facebook',
        type: 'facebook-ad',
        logo: 'assets/img/roi/facebook-logo.png',
        icon: 'assets/img/roi/icons/facebook.png'
      },
      capture: {
        name: 'Capture',
        type: 'capture',
        logo: 'assets/img/roi/capture/optimonk.png',
        icon: 'assets/img/roi/icons/optimonk.png'
      },
      hiIq: {
        name: 'Hi-iQ',
        type: 'hi-iq',
        logo: 'assets/img/roi/hi-iq.png',
        icon: 'assets/img/roi/icons/hi-iq.png'
      }
    },
    productNotSupported: 'Product OAuth not supported.',
    dataInfo: 'Data displayed are based on last 30 days performance.',
    productIconsPath: 'assets/img/roi/icons/'
  },
  myProducts: {
    metrics: {
      primary: [
        'emailRevenue',
        'totalSubscribers',
        'subscribed',
        'unsubscribed',
        'sent',
        'opened'
      ],
      multiChannel: [
        'clicks',
        'sent',
        'bounced',
        'unsubscribed'
      ],
      facebookAd: [
        'roas',
        'revenue',
        'spend',
        'ctr',
        'landing_page_view',
        'purchases'
      ],
      eCommerce: {
        shopify: [
          'revenue',
          'totalCustomers',
          'totalOrders',
          'successfulOrders',
          'pendingOrders',
          'refundedOrders'
        ],
        bigCommerce: [
          'revenue',
          'totalCustomers',
          'totalOrders',
          'successfulOrders',
          'pendingOrders',
          'refundedOrders'
        ],
        wooCommerce: [
          'revenue',
          'totalCustomers',
          'totalOrders',
          'successfulOrders',
          'pendingOrders',
          'refundedOrders'
        ]
      },
      optimonk: [
        'Impressions',
        'Conversions',
        'ConversionRate',
        'pageViews',
        'uniqueVisitors'
      ],
      segment: [
        'successes',
        'expired',
        'discarded',
        'retried',
        'timeToSuccessAverage',
        'timeToFirstAttemptAverage'
      ],
      adjust: [
        'revenue',
        'sessions',
        'installs',
        'maus',
        'clicks',
        'clickConversionRate'
      ],
      mixpanel: [
        'sessions'
      ]
    },
    maxEventColumnsCount: 5,
    negativeMetricIndicators: [
      'cost',
      'spend',
      'spent',
      'unsub',
      'discard'
    ]
  },
  channels: {
    email: {
      value: 'email',
      type: 'email',
      text: 'Email'
    },
    sms: {
      value: 'sms',
      type: 'sms',
      text: 'SMS'
    },
    push: {
      value: 'push',
      type: 'push',
      text: 'Push'
    },
    webPush: {
      value: 'webPush',
      type: 'web_push',
      text: 'Web Push'
    }
  },
  multiCahnnelEsps: [
    'iterable',
    'klaviyo',
    'oneSignal'
  ]
};
