import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

let script;

// Google Analytics load
script = document.createElement('script');
script.innerHTML =
  `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
  ga('create', '${ environment.googleAnalyticsId }', 'auto', {'allowLinker': true});
  ga('require', 'linker');
  ga('linker:autoLink', ['ematicsolutions.com', 'elixusagency.com', 'amphoria.io', 'hiiq.ai']);
  ga('require', '${ environment.googleOptimizeId }');`;
document.head.appendChild(script);

// Google Tag Manager load
script = document.createElement('script');
script.innerHTML =
  `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer', '${ environment.googleTagManagerId }');`;
document.head.appendChild(script);

const noscript = document.createElement('noscript');
noscript.innerHTML =
  `<iframe src="https://www.googletagmanager.com/ns.html?id=${ environment.googleTagManagerId }"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

document.body.appendChild(noscript);

platformBrowserDynamic().bootstrapModule(AppModule);
