import { Injectable } from '@angular/core';

import { SharedEventsService } from '../../../util/shared-events.service';

import { constants } from '../../../strings/constants';

@Injectable()
export class HeaderService {

  constructor(private sharedEventsService: SharedEventsService) { }

  toggleLeftMenu(rightMenuOpened: boolean) {
    this.sharedEventsService.broadcast(constants.events.TOGGLE_LEFT_MENU);
    if (rightMenuOpened) {
      this.sharedEventsService.broadcast(constants.events.TOGGLE_RIGHT_MENU);
    }
  }

  toggleRightMenu(leftMenuOpened: boolean) {
    this.sharedEventsService.broadcast(constants.events.TOGGLE_RIGHT_MENU);
    if (leftMenuOpened) {
      this.sharedEventsService.broadcast(constants.events.TOGGLE_LEFT_MENU);
    }
  }
}
