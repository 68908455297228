<app-external>
  <ng-container content-container>
    <div class="content-form">
      <a class="logo-link" [attr.href]="corporateUrl" target="_blank">
        <img class="ematic-logo"
             [srcset]="platformLogo.imageDpr1 + ' 1x, ' + platformLogo.imageDpr2 + ' 2x'"
             [alt]="platformText">
      </a>
      <div class="content-header">
        <span class="content-title">
          Sign into your account
        </span>
      </div>
      <p *ngIf="redirectedFromCM">{{cmRedirectMessage}}</p>
      <form class="form">
        <input type="text"
               name="username"
               placeholder="Email"
               autocomplete="username"
               [(ngModel)]="username"/>
        <input type="password"
               placeholder="Password"
               autocomplete="current-password"
               name="password"
               [(ngModel)]="password"
               (keyup.enter)="onLogin('regular')" />
        <a class="link forgot-password-link" routerLink="/forgot-password">Forgot your password?</a>
        <es-button
          class="btn-block"
          text="Sign in"
          [type]="'primary'"
          [loading]="loading"
          [isRounded]="true"
          (clicked)="onLogin('regular')">
        </es-button>

        <p class="corporate-link" *ngIf="!redirectedFromCM">Don't have an account?
          <a class="sign-up-link" routerLink="/create-account">Sign Up Now</a>
        </p>
        <p class="form-devider">Or</p>
        <div class="google-button" id="google-button" [style.opacity]="gapiLoaded ? 1.0 : 0.5">
          <img class="icon" src="assets/img/login/google-logo.png" alt="Google Logo">
          <span class="text">Sign in with Google</span>
        </div>
      </form>
    </div>
    <div class="content-bg"></div>
  </ng-container>
</app-external>
