import { Injectable } from '@angular/core';

import { firstValueFrom } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from '../auth/auth.service';
import { GapiService } from './gapi.service';
import { IntegrationsService } from '../integrations/integrations.service';
import { UtilService } from '../../util/util.service';
import { IUser } from '../../models/user';
import { constants } from '../../strings/constants';

declare let ga: Function;
declare var gapi: any;

export interface ISubmitGAParams {
  accountInfo: {
      username: any;
      propertyName: any;
      viewId: any;
      accountId: any;
      propertyId: any;
  };
  instanceId: string;
}

export interface IGAStatus {
  isActive: boolean;
  isGAPILoaded: boolean;
}

@Injectable()
export class GoogleAnalyticsService {
  private _auth2: any;
  private _isGAPILoaded: boolean;
  private _isGAActive: boolean;

  get gaStatus(): IGAStatus {
    return {
      isActive: this._isGAActive,
      isGAPILoaded: this._isGAPILoaded
    };
  }

  constructor(
    private authService: AuthService,
    private gapiService: GapiService,
    private integrationsService: IntegrationsService,
    private utilService: UtilService,
    public toastr: ToastrService
  ) {
    this._isGAPILoaded = false;
    this._isGAActive = false;
  }

  public sendEvent(
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null) {
    ga('send', 'event', {
      eventCategory: eventCategory,
      eventAction: eventAction,
      eventLabel: eventLabel,
      eventValue: eventValue
    });
  }

  private async loadGAAPI() {
    try {
      await this.gapiService.loadGapi(() => {
        this._auth2 = gapi.auth2.getAuthInstance();
        this._isGAPILoaded = true;
      });
    } catch (error) {
      if (error.error === constants.errors.analytics.cookies_error
          && error.details === constants.errors.analytics.cookies_details) {
        this.integrationsService.toastr.warning(constants.errors.analytics.ga_disabled);
      } else {
        this.integrationsService.toastr.error(constants.errors.analytics.generic, constants.errors.error);
      }
      this._isGAPILoaded = false;
    }
  }

  private async checkGAStatus(user: IUser, isCustomer: boolean) {
    try {
      const instance = this.utilService.getCurrentInstance(user);
      const gaResult = await this.integrationsService.getGaStatusAsync(instance);
      this._isGAActive = isCustomer ? gaResult.isActive : !gaResult.canIntegrate;
      if (this._isGAActive && gaResult.generating) {
        this.integrationsService.toastr.warning(constants.messages.analytics.ga_in_progress);
      }
    } catch (error) {
      if (error.name !== 'NotFoundError' && error.message) {
        this.integrationsService.toastr.error(error.message, constants.errors.error);
      }
      this._isGAActive = false;
    }
  }

  async loadGAData(user: IUser, isCustomer: boolean) {
    await this.loadGAAPI();
    await this.checkGAStatus(user, isCustomer);
  }

  async connectGA(): Promise<any> {
    try {
      return await this._auth2.grantOfflineAccess({
        redirect_uri: 'postmessage',
        scope: 'https://www.googleapis.com/auth/analytics.readonly'
      });
    } catch (error) {
      throw error;
    }
  }

  async storeAuthCode(authResult: any, user: IUser): Promise<any> {
    try {
      const { _id } = this.utilService.getCurrentInstance(user);
      return await firstValueFrom(this.integrationsService.storeAuthCode({ authResult, instanceId: _id.toString() }));
    } catch (storeAuthCodeError) {
      this._auth2.disconnect();
      const errorMessage = (storeAuthCodeError.message) ? storeAuthCodeError.message : constants.errors.analytics.grant_error;
      this.integrationsService.toastr.error(errorMessage, constants.errors.error);
    }
  }

  async removeGA(user: IUser, isCustomer: boolean) {
    try {
      const { _id } = this.utilService.getCurrentInstance(user);
      const result = await this.integrationsService.postAsync({ instanceId: _id.toString() }, '/remove-ga');
      if (result && result.gaDataSetInUse && result.instancesShareGADataSet && isCustomer) {
        const gaDataSetInUse = `${constants.integrations.gaDataSetInUse} ${result.instancesShareGADataSet.join(', ')}`;
        this.integrationsService.toastr.info(gaDataSetInUse);
      }

      this._isGAActive = false;
      await this.authService.updateUser(u => {
        u.gaStatus = false;
      });

      return true;
    } catch (error) {
      this.integrationsService.toastr.error(error.message, constants.errors.error);
    }
  }

  async submitGA(params: ISubmitGAParams) {
    try {
      await this.integrationsService.updateAccountInfo(params);
      this.integrationsService.toastr.success(constants.messages.analytics.ga_success, constants.messages.success);
      this._isGAActive = true;
      await this.authService.updateUser(user => {
        user.gaStatus = true;
      });
    } catch (error) {
      this.integrationsService.toastr.error(error.message || constants.errors.something_went_wrong, constants.errors.error);
    }
  }
}
