import { Injectable } from '@angular/core';

import { IInstance } from '../models/instance';
import { IAccessRight } from '../models/access-right';
import { IUser } from '../models/user';
import { IProduct } from '../models/product';
import { constants } from '../strings/constants';
import { IEspChannel } from '../models/esp';

export type InstanceSelector = (arg: any) => IInstance;

@Injectable({
  providedIn: 'root'
})
export class ProductUtilService {

  checkInstancesForProduct(instanceContainer: any[], productType: string, getInstance: InstanceSelector): boolean {
    return instanceContainer.reduce((acc: boolean, tally: any) => {
      if (getInstance(tally)._id !== 'all') {
        acc = acc || this.instanceHasProduct(getInstance(tally), productType);
      }

      return acc;
    }, false);
  }

  instanceHasProduct(instance: IInstance, productType: string): boolean {
    return instance.products.some((p: IProduct) => productType.includes(p.type));
  }

  anyInstanceHasProduct(user: IUser, productType: string): boolean {
    return user.customer
      ? this.checkInstancesForProduct(user.customer.accessRights, productType, (ar: IAccessRight) => ar.instance)
      : this.checkInstancesForProduct(user.state.dashboard.admin.instance.instances, productType, (i: IInstance) => i);
  }

  getEmailProduct(instance: IInstance): IProduct {
    return this.getPrimaryProduct(instance) ?? instance.products.find(
      p => p.type === constants.trendAnalysisProducts.nonPrimary.historic
    );
  }

  getPrimaryProduct(instance: IInstance): IProduct {
    // primary product is a product with the highest priority among instance products where the following types are considered,
    // ordered by priority:
    // Email Performance
    // Hi-iQ
    // Bye-iQ
    // Retry-iQ
    for (const pt of this.getPrimaryProductTypes()) {
      const primaryProduct = instance.products.find(p => p.type === pt);
      if (primaryProduct) {
        return primaryProduct;
      }
    }
    return null;
  }

  getPrimaryProductTypes(): string[] {
    return Object.values(constants.trendAnalysisProducts.primary);
  }

  getNonPrimaryProductTypes(): string[] {
    return Object.values(constants.trendAnalysisProducts.nonPrimary);
  }

  getProductName(product: IProduct, showEspName: boolean): string {
    switch (product.type) {
      case constants.trendAnalysisProducts.primary.emailPerformance:
      case constants.trendAnalysisProducts.primary.hiIq:
      case constants.trendAnalysisProducts.primary.byeIq:
      case constants.trendAnalysisProducts.primary.retryIq:
      case constants.trendAnalysisProducts.nonPrimary.historic:
        return showEspName ? product.espData.text : constants.products.EMAIL_PERFORMANCE.text;
      case constants.trendAnalysisProducts.nonPrimary.smsPerformance:
        return showEspName ? product.espData.text : constants.products.SMS_PERFORMANCE.text;
      case constants.trendAnalysisProducts.nonPrimary.pushPerformance:
        return showEspName ? product.espData.text : constants.products.PUSH_PERFORMANCE.text;
      case constants.trendAnalysisProducts.nonPrimary.webPushPerformance:
        return showEspName ? product.espData.text : constants.products.WEB_PUSH_PERFORMANCE.text;
      case constants.trendAnalysisProducts.nonPrimary.facebookAd:
        return constants.products.FACEBOOK_AD.text;
      case constants.trendAnalysisProducts.derived.runStats:
        return constants.derivedProducts.RUN_STATS.text;
      default:
        return product.espData.text;
    }
  }

  getRelativeProductPath(product: IProduct): string {
    let productPathPart;
    switch (product.type) {
      case constants.trendAnalysisProducts.nonPrimary.facebookAd:
        productPathPart = constants.products.FACEBOOK_AD.value;
        break;
      default:
        productPathPart = product.espData.type.toLowerCase();
    }

    return `${ constants.states.MY_PRODUCTS }/${ productPathPart }`;
  }

  getProductIcon(product: IProduct): string {
    let iconName;
    switch (product.type) {
      case constants.trendAnalysisProducts.nonPrimary.facebookAd:
        iconName = constants.products.FACEBOOK_AD.value;
        break;
      default:
        iconName = product.espData.type.toLowerCase();
    }
    return `icon-${ iconName }`;
  }

  getProductChannel(product: IProduct): IEspChannel {
    switch (product.type) {
      case constants.products.EMAIL_PERFORMANCE.value:
        return constants.channels.email;
      case constants.products.SMS_PERFORMANCE.value:
        return constants.channels.sms;
      case constants.products.PUSH_PERFORMANCE.value:
        return constants.channels.push;
      case constants.products.WEB_PUSH_PERFORMANCE.value:
        return constants.channels.webPush;
      default:
        return this.mapProductMediumToChannel(product);
    }
  }

  mapProductMediumToChannel(product: IProduct): IEspChannel {
    const mediumType = product.espData.fields.find(field => field.key === 'medium')?.value?.type;
    const channels = Object.values(constants.channels);
    const channel = channels.find(c => c.type === mediumType);
    return channel ?? constants.channels.email;
  }
}
