import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { Observable, ReplaySubject } from 'rxjs';
import { delay, map, share } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { AsyncApi } from '../../ematic-core-ui/api/async-api';
import { UIProject } from '../../ematic-core-ui/models/ui-project';
import { AuthService } from '../auth/auth.service';
import { UtilService } from '../../util/util.service';
import { UserService } from '../user/user.service';
import { IMartech } from '../../models/martech';
import { IMartechConnection } from '../../models/martech-connection';
import { IUser } from '../../models/user';
import { PlatformService } from '../../ematic-core-ui/services/platform/platform.service';

@Injectable()
export class MartechService extends AsyncApi {
  private martechsCache: Observable<IMartech[]>;
  private channelsCache: Observable<string[]>;

  constructor(
    router: Router,
    http: HttpClient,
    toastr: ToastrService,
    platformService: PlatformService,
    private authService: AuthService,
    private utilService: UtilService) {
    super(http, router, toastr, '/martech', platformService, UIProject.PLATFORM_DASHBOARD);
  }

  queryMartechs(): Observable<IMartech[]> {
    return this.get();
  }

  async insertConnection(instanceId: string, martechIds: string[]): Promise<IMartechConnection[]> {
    return await this.postAsync({ instanceId, martechIds }, '/connection');
  }

  async deleteConnections(instanceId: string, connections: IMartechConnection[]): Promise<IMartechConnection[]> {
    return await this.putAsync({ instanceId, connections }, '/connection');
  }

  queryChannels(): Observable<string[]> {
    return this.get(null, '/channel');
  }

  queryCachedMartechsExtended(category: string, channel?: string): Observable<IMartech[]> {
    if (!this.martechsCache) {
      this.martechsCache = this.queryMartechs().pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: false,
        }),
        delay(20)
      );
    }

    return this.martechsCache.pipe(
      map(response => response.filter(martech =>
        martech.category === category && (!channel || martech.subCategory === channel)
        ).map(martech => {
          martech.abbr = this.utilService.getMartechAbbreviation(martech.name);
          return martech;
        }).concat(this.getDefaultMartech())
      )
    );
  }

  queryCachedChannels(): Observable<string[]> {
    if (!this.channelsCache) {
      this.channelsCache = this.queryChannels().pipe(
        share({
          connector: () => new ReplaySubject(1),
          resetOnError: false,
          resetOnComplete: false,
          resetOnRefCountZero: false,
        }),
        delay(20)
      );
    }

    return this.channelsCache;
  }

  getDefaultMartech(): IMartech {
    return <IMartech>{
      name: 'None'
    };
  }

  async updateInstanceConnections(currentUser: IUser,
                                  currentInstanceId: string,
                                  instanceConnections: IMartechConnection[]): Promise<void> {
    await this.authService.updateUser(user => {
      if (UserService.isCustomer(currentUser)) {
        user.customer.accessRights.find(accessRight =>
          accessRight.instance._id === currentInstanceId
        ).instance.connections = instanceConnections;
      } else {
        user.state.dashboard.admin.instance.currentInstance.connections = instanceConnections;
        user.state.dashboard.admin.instance.instances.find(instance =>
          instance._id === currentInstanceId
        ).connections = instanceConnections;
      }
    });
  }

  clearCache(): void {
    this.martechsCache = this.channelsCache = null;
  }
}
