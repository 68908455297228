export function getQueryStringValue(key, location = window.location): string | null {
  const query = {};
  const search = location.search;

  const pairs = search
    ? (search[0] === '?' ? search.substr(1) : search).split('&')
    : [];

  for (const pair of pairs) {
    const [k, v] = pair.split('=');
    if (k) {
      query[k] = v;
    }
  }

  return query[key] ?? null;
}
