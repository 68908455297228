import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AsyncApi } from '../../ematic-core-ui/api/async-api';
import { ToastrService } from 'ngx-toastr';
import { GenerateUrlService } from '../generate-url/generate-url.service';

@Injectable()
export class OrganizationService {

  get baseUrl(): string {
    return this.generateUrlService.baseUrl;
  }

  constructor(
    private http: HttpClient,
    public toastr: ToastrService,
    private generateUrlService: GenerateUrlService) {
  }

  deleteOrganization(id: string, onSuccess: () => void, onError: () => void): any {
    this.http.delete(`${ this.baseUrl.replace('dashboard', 'admin') }/customers/organizations/delete-organization/${ id }`,
      AsyncApi.getRequestOptions(null, false))
      .subscribe({
        next: () => {
          onSuccess();
        },
        error: () => {
          onError();
        }
      });
  }
}
