import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { AsyncApi } from '../../ematic-core-ui/api/async-api';
import { UIProject } from '../../ematic-core-ui/models/ui-project';
import { IUser } from '../../models/user';
import { UserPermission } from '../../components/user-permissions/user-permission';
import { PlatformService } from '../../ematic-core-ui/services/platform/platform.service';

@Injectable()
export class PermissionTypesService extends AsyncApi {
  permissions: any[];

  constructor(
    router: Router,
    http: HttpClient,
    toastr: ToastrService,
    platformService: PlatformService) {
    super(http, router, toastr, '/permission-types', platformService, UIProject.PLATFORM_DASHBOARD);
  }

  async getPermissionsAsync(): Promise<any[]> {
    if (this.permissions) {
      return this.permissions;
    }
    this.permissions = await this.getAsync(null, '');
    return this.permissions;
  }

  async updateUsers(users: UserPermission[], currentInstanceId: string): Promise<IUser[]> {
    return await this.putAsync({users, currentInstanceId});
  }
}
