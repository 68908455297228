import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { ToastrService } from 'ngx-toastr';

import { UIProject } from '../../ematic-core-ui/models/ui-project';
import { AsyncApi } from '../../ematic-core-ui/api/async-api';
import {
  IAverageTotalEngagedResponse,
  IEngagedCriteriaResponse,
  IHiIqAccountEditableSettings,
  IHiIqAccountSettings
} from '../../models/hi-iq-account-properties';
import { IProduct } from '../../models/product';
import { IReportParams } from 'app/models/report-params';
import { PlatformService } from '../../ematic-core-ui/services/platform/platform.service';

@Injectable()
export class CSAdminService extends AsyncApi {
  hiIqAccountSettings: IHiIqAccountSettings;

  constructor(
    router: Router,
    http: HttpClient,
    toastr: ToastrService,
    platformService: PlatformService) {
    super(http, router, toastr, '/cs-admin', platformService, UIProject.PLATFORM_DASHBOARD);
  }

  async getHiIqAccountSettings(ematicAPIKey: string) {
    return await this.getAsync(null, `/hi-iq/account-settings/${ ematicAPIKey }`);
  }

  async updateHiIqAccountSettings(hiIqAccountEditableSettings: IHiIqAccountEditableSettings, ematicAPIKey: string) {
    await this.putAsync(hiIqAccountEditableSettings, `/hi-iq/account-settings/${ ematicAPIKey }`);
  }

  async setHiIqAccountSettings(hiIqProduct: IProduct) {
    this.hiIqAccountSettings = null;

    if (hiIqProduct && hiIqProduct.key && !hiIqProduct.unavailable) {

      const result: IHiIqAccountSettings = await this.getHiIqAccountSettings(hiIqProduct.key);

      // this condition should never be true, but we check it just in case
      if (!result
        || !result.account
        || !result.config_params
        || !result.engaged_criteria_common
        || !result.engaged_criteria_rare
        || !result.last_run_stats
        || !result.winnerExperimentStatus) {
        throw new Error();
      }
      this.hiIqAccountSettings = result;
    }
  }

  async getTotalEngagedAverageValue(configurationParams: IReportParams): Promise<IAverageTotalEngagedResponse> {
    return await this.getAsync(configurationParams, '/hi-iq/average-total-engaged');
  }

  async getHiIqEngagedCriteria(configurationParams: IReportParams): Promise<IEngagedCriteriaResponse> {
    return await this.getAsync(configurationParams, '/hi-iq/engaged-criteria');
  }
}
