<es-field class="full-width"
  [showErrorMessage]="showErrorMessage"
  [errorMessage]="errorMessage"
  [showClearIcon]="false"
  [showCopyIcon]="showCopyIcon"
  [isRounded]="isRounded"
  (copyValue)="onCopyValue()">
  <textarea #inputField
            class="es-input-field"
            [class.resize-vertically]="verticallyResizable"
            [class.rounded]="isRounded"
            tabindex="-1"
            [maxlength]="maxLength"
            [rows]="rows"
            [cols]="cols"
            [name]="name"
            [disabled]="isEsDisabled"
            [placeholder]="placeholder"
            [(ngModel)]="innerValue"
            (focus)="onInnerInputFocus()"
            (blur)="onInnerInputBlur()"
            (keypress)="onInnerInputKeyPress($event)"
            (input)="onInput()">
  </textarea>
</es-field>
