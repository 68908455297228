import { switchMap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { IImage } from '../../../ematic-core-ui/models/image';
import { AuthService } from '../../../services/auth/auth.service';
import { LogoService } from '../../../services/logo/logo.service';
import { GenerateUrlService } from '../../../services/generate-url/generate-url.service';
import { constants } from '../../../strings/constants';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  newPassword: string;
  confirmPassword: string;
  loading: boolean;
  loginState: string = constants.states.LOGIN;

  siteKey: string = environment.reCaptchaSiteKey;
  reCaptchaEnabled: boolean = environment.reCaptchaEnabled;
  captchaResponse = '';

  get platformLogo(): IImage {
    return this.logoService.platformLogo('color');
  }

  get platformText(): string {
    return this.logoService.platformText;
  }

  get corporateUrl(): string {
    return this.generateUrlService.corporateUrl;
  }

  constructor(private route: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private logoService: LogoService,
              private generateUrlService: GenerateUrlService) {
  }

  ngOnInit() {
    this.loading = false;
    this.captchaResponse = this.reCaptchaEnabled ? '' : constants.notAvailable;
  }

  onResetPassword() {
    if (!this.newPassword || !this.confirmPassword) {
      this.authService.toastr.error(constants.messages.password_reset_no_input, constants.errors.error);
      return;
    }

    if (this.newPassword === this.confirmPassword) {
      this.loading = true;

      this.route.params.pipe(
        switchMap(params =>
          this.authService.changePasswordToken({
            token: params['resetToken'],
            password: this.newPassword,
            captchaResponse: this.captchaResponse
          }))
      ).subscribe({
        next: () => {
          this.authService.toastr.success(constants.messages.password_reset, constants.messages.success);
          this.loading = false;
          this.router.navigate(['/login']);
        },
        error: error => {
          this.authService.toastr.error(error.message || constants.errors.error_occurred, constants.errors.error);
          this.loading = false;
          this.captchaResponse = '';
        }
      });
    }
  }

  handleCaptcha(response: string): void {
    this.captchaResponse = response || '';
  }
}
