import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment-timezone-tsc';

import { UserPermission } from '../../components/user-permissions/user-permission';
import { UtilService } from '../../util/util.service';
import { AsyncCRUD } from '../../ematic-core-ui/api/async-crud';
import { UIProject } from '../../ematic-core-ui/models/ui-project';
import { AuthService } from '../auth/auth.service';
import { PermissionTypesService } from '../permission-types/permission-types.service';
import { IUser } from '../../models/user';
import { IInstance } from '../../models/instance';
import { IProduct } from '../../models/product';
import { ICountry } from '../../models/country';
import { IOnboardingState } from '../../models/onboarding-state';
import { PlatformService } from '../../ematic-core-ui/services/platform/platform.service';

export interface IProductType {
  value: string;
  text: string;
}

@Injectable()
export class UserService extends AsyncCRUD<IUser> {

  authService: AuthService;
  permissionTypesService: PermissionTypesService;
  utilService: UtilService;

  constructor(
    router: Router,
    http: HttpClient,
    toastr: ToastrService,
    platformService: PlatformService,
    authService: AuthService,
    permissionTypesService: PermissionTypesService,
    utilService: UtilService
  ) {
    super(http, router, toastr, '/users', platformService, UIProject.PLATFORM_DASHBOARD);
    this.authService = authService;
    this.permissionTypesService = permissionTypesService;
    this.utilService = utilService;
  }

  static isCustomer(user: IUser): boolean {
    return user && !!user.customer;
  }

  static isSuperAdminOrCS(user: IUser): boolean {
    return user && (user.isSuperAdmin || !!user.roles.find(role => role.type.startsWith('CS')));
  }

  async changeStatus(user: UserPermission): Promise<void> {
    try {
      await this.putAsync({ userId: user.userId, status: user.status }, '/status');
    } catch (error) {
      this.handleError(error);
    }
  }

  async queryByOrganizationAndInstance(organizationId: string, instanceId: string): Promise<IUser[]> {
    return await this.getAsync(null, `/filtered/by-organization-and-instance/${ organizationId }/${ instanceId || '' }`);
  }

  queryCustomersByInstance(organizationId: string, instanceId: string): Promise<IUser[]> {
    return this.getAsync(null, `/customers/filtered/by-instance/${ organizationId }/${ instanceId }`);
  }

  async queryCountries(): Promise<ICountry[]> {
    try {
      return await this.getAsync(null, '/countries');
    } catch (error) {
      throw error;
    }
  }

  async updateOnboardingState(params: IOnboardingState) {
    return await this.putAsync(params, '/onboarding-state');
  }

  async queryIpData(): Promise<any> {
    return await this.getAsync(null, '/ip-data');
  }

  async queryUserData(): Promise<IUser> {
    const { user } = this.authService.getUser();
    const currentOrganizationId = this.utilService.getCurrentOrganization(user)._id;
    const currentInstanceId = this.utilService.getCurrentInstance(user)._id;

    return await this.getAsync(null, `/user-data/${ currentOrganizationId }/${ currentInstanceId }`);
  }

  async getOnboardUser(): Promise<IUser> {
    const { user } = this.authService.getUser();
    const currentOrganizationId = this.utilService.getCurrentOrganization(user)._id;
    const currentInstanceId = this.utilService.getCurrentInstance(user)._id;

    const result = await this.getAsync(null, `/onboard-user/${ currentOrganizationId }/${ currentInstanceId }`);
    return result.onboardUser;
  }

  async getProductByTypeForInstances(user: IUser, product: IProductType): Promise<IProduct[]> {
    return await Promise.all(
      this.utilService.getAllAvailableInstanceObjects(user).reduce((acc: Promise<IProduct>[], inst: IInstance) => {
        if (inst._id !== 'all') {
          acc.push(this.getProductByType(inst.products, product));
        }

        return acc;
      }, [])
    );
  }

  async getProductByType(products: IProduct[], productType: IProductType, checkPermission?: string): Promise<IProduct> {
    try {
      checkPermission = checkPermission || productType.value;

      const { user } = this.authService.getUser();
      if (!user) {
        return;
      }

      const allPermissions = await this.permissionTypesService.getPermissionsAsync();
      const accessRights = user.customer && user.customer.accessRights;
      const currentInstance = user.state.dashboard.currentInstance;
      const isPermitted = user.customer
        ? this.authService.checkProductPermission(accessRights, currentInstance, checkPermission, allPermissions)
        : user.isSuperAdmin;

      return products.find(product => (
        Object.keys(product).length !== 0 && isPermitted && product.type.toLowerCase() === productType.value
      )) || <IProduct>{ type: productType.text, unavailable: true };
    } catch (error) {
      throw error;
    }
  }

  getTimezoneDiff(): number {
    const { user } = this.authService.getUser();
    const browserOffset = moment().utcOffset();
    const userOffset = (user && user.customer && user.customer.timezone) ? moment.tz(moment(), user.customer.timezone).utcOffset() : 0;
    return (browserOffset - userOffset) / 60;
  }
}
