import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../../services/auth/auth.service';
import { constants } from '../../../strings/constants';
import { RedirectionHandlerService } from '../../../ematic-core-ui/services/redirection-handler/redirection-handler.service';
import { IAccount } from '../../../models/account';

@Component({
  selector: 'unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit, OnDestroy {

  constructor(private authService: AuthService, private router: Router, private redirectionHandlerService: RedirectionHandlerService) {
  }

  ngOnInit(): void {
    this.redirectionHandlerService.listenToRedirectionQueryParams();
  }

  ngOnDestroy(): void {
    this.redirectionHandlerService.stopListening();
  }

  logout() {
    this.authService.setUser(<IAccount>{});
    this.router.navigate([constants.states.LOGIN]);
  }
}
