import { Injectable } from '@angular/core';

import { PlatformService } from '../../ematic-core-ui/services/platform/platform.service';
import { IImage } from '../../ematic-core-ui/models/image';
import { LogoType } from '../../models/logo';
import { constants } from '../../strings/constants';

@Injectable({
  providedIn: 'root'
})
export class LogoService {
  platformLogos = constants.platform.logos;

  get platformText(): string {
    return `${ this.platformService.platformName } Logo`;
  }

  constructor(private platformService: PlatformService) {
  }

  platformLogo(type: LogoType): IImage {
    for (const platform in this.platformLogos) {
      if (this.platformLogos.hasOwnProperty(platform)) {
        if (platform === this.platformService.platformOriginId) {
          return this.platformLogos[platform][type];
        }
      }
    }
  }
}
