import { Component } from '@angular/core';
import { SupportEmailService } from '../../services/support-email/support-email.service';

@Component({
  selector: 'terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent {

  get supportTeamEmailDisplayText() {
    return this.supportEmailService.supportTeamEmailDisplayText;
  }

  constructor(private supportEmailService: SupportEmailService) {
  }
}
