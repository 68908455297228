import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { from, Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, finalize, map, mergeMap } from 'rxjs/operators';

import { AsyncApi } from '../../ematic-core-ui/api/async-api';
import { UIProject } from '../../ematic-core-ui/models/ui-project';
import { IUser } from '../../models/user';
import { IAccount } from '../../models/account';
import { IAccessRight } from '../../models/access-right';
import { constants } from '../../strings/constants';
import { PermissionTypesService } from '../permission-types/permission-types.service';
import { GenerateUrlService } from '../generate-url/generate-url.service';
import { PlatformService } from '../../ematic-core-ui/services/platform/platform.service';
import { IRole } from '../../models/role';
import { IInviteUserData, IReinviteUserData } from '../../models/auth-params';
import { PlatformOriginId } from '../../ematic-core-ui/models/platform';
import { CommonUtil } from '../../ematic-core-ui/util/common-util';
import { IPermission } from '../../models/permission';
import { USER_KEY } from '../../ematic-core-ui/strings/local-storage-keys';

export interface IChangePasswordParams {
  token: string;
  password: string;
  captchaResponse: string;
}

@Injectable()
export class AuthService extends AsyncApi {

  private permissionTypesService: any;

  get baseUrl(): string {
    return this.generateUrlService.baseUrl;
  }

  constructor(
    router: Router,
    http: HttpClient,
    toastr: ToastrService,
    permissionTypesService: PermissionTypesService,
    platformService: PlatformService,
    private generateUrlService: GenerateUrlService) {
    super(http, router, toastr, '/auth', platformService, UIProject.PLATFORM_DASHBOARD);

    this.permissionTypesService = permissionTypesService;
  }

  getUser(): IAccount {
    const account = JSON.parse(localStorage.getItem(USER_KEY) || '{}');
    return <IAccount>account;
  }

  loginByToken(token: string): Observable<boolean> {
    return this.post({ token }, '/by-token')
      .pipe(
        mergeMap(acc => this.setUser(acc)),
        map(() => true),
        catchError(error => {
          this.displayError(error);
          return from(this.router.navigate(['/login']));
        }),
        finalize(() => localStorage.removeItem(constants.token))
      );
  }

  validate(token: string): Observable<IAccount> {
    return this.post({ token }, '/validate');
  }

  async invite(params: IInviteUserData): Promise<IUser> {
    return await this.postAsync(params, '/invite');
  }

  async reinvite(params: IReinviteUserData): Promise<void> {
    await this.postAsync(params, `/reinvite`);
  }

  async setUser({ user, accessToken }: IAccount): Promise<IAccount> {
    try {
      if (user && user.customer) {
        const allPermissions = await this.permissionTypesService.getPermissionsAsync();
        const accessRights = user.customer.accessRights;
        const currentInstanceId = user.state.dashboard.currentInstanceId;

        user.state.dashboard.currentInstance = accessRights.find(ar => ar.instance._id === currentInstanceId);
        user.isAdmin = this.checkIsAdmin(user.state.dashboard.currentInstance, allPermissions);
        user.hasAccessRightPermission = this.checkPermission(user.state.dashboard.currentInstance, 'access-rights');
        user.hasMarketplacePermission = this.checkPermission(user.state.dashboard.currentInstance, 'marketplace');
        user.hasPaymentOptionsPermission = this.checkPermission(user.state.dashboard.currentInstance, 'payment-options');

        user.isBillingOnly = this.checkIsBillingOnly(accessRights, currentInstanceId);
        user.hasTrendAnalysisPermission = this.checkProductPermission(
          accessRights,
          user.state.dashboard.currentInstance,
          constants.permissions['trend-analysis'].value,
          allPermissions
        );
      }

      if (user && user.customer && user.customer.avatar.indexOf('default.png') >= 0) {
        user.customer.avatar = constants.defaultAvatarPath;
      }

      if (user && !user.customer) {
        user.isSuperAdmin = this.checkIsSuperAdmin(user);
        user.hasTrendAnalysisPermission = true;
        user.avatar = constants.defaultAvatarPath;

        user.state.dashboard.admin.instance.currentInstance = user.state.dashboard.admin.instance.instances.find((inst) => {
          return inst._id === user.state.dashboard.admin.instance.currentInstanceId;
        });
        user.state.dashboard.admin.organization.currentOrganization = user.state.dashboard.admin.organization.organizations.find((org) => {
          return org._id === user.state.dashboard.admin.organization.currentOrganizationId;
        });
      }

      const token = this.getUser()['accessToken'];
      if (!accessToken && token) {
        accessToken = token;
      }

      if (user && accessToken) {
        this.setAccount(user, accessToken);
      } else {
        localStorage.removeItem(USER_KEY);
      }

      return <IAccount>{
        user,
        accessToken: accessToken
      };
    } catch (error) {
      throw error;
    }
  }

  setAccount(user: IUser, accessToken: string) {
    localStorage.setItem(USER_KEY, JSON.stringify({ user, accessToken }));
  }

  async updateUser(update: (user: IUser) => void) {
    try {
      const oldUser = this.getUser().user;
      update(oldUser);
      await this.setUser({ user: oldUser, accessToken: null });
    } catch (error) {
      throw error;
    }
  }

  logout(): void {
    this.post(null, '/logout')
      .subscribe(() => {
        localStorage.removeItem(constants.token);
        localStorage.removeItem(constants.trendAnalysisChartMetric);
        this.setUser(<IAccount>{});
      });
  }

  submitRegistration(user: IUser, token: string, company: string, platformOriginId: PlatformOriginId) {
    const headers = Object.assign({
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': `Bearer ${ token }`
    });

    let route = '/auth/register';
    const params = Object.assign(user);
    params.platformOriginId = platformOriginId;

    if (company) {
      params.company = company.trim();
      route = '/auth/register-onboard';
    }

    return this.http.post(this.baseUrl + route, params, { headers: new HttpHeaders(headers) })
      .pipe(catchError((error) => observableThrowError(error)));
  }

  changePasswordToken(params: IChangePasswordParams): Observable<any> {
    return this.put(params, '/change-password/token');
  }

  checkIsAdmin(currentInstance: IAccessRight, allPermissions: Array<string>) {
    return currentInstance.permissions.length === allPermissions.length;
  }

  checkPermission(currentInstance: IAccessRight, permissionType: string) {
    return currentInstance.permissions.some(perm => perm.type === permissionType);
  }

  // add has permission
  checkIsBillingOnly(accessRights: IAccessRight[], currentInstanceId: string) {
    return accessRights.some(accessRight => {
      if (accessRight.instance._id === currentInstanceId) {
        if (accessRight.permissions.length !== 1) {
          return false;
        }
        return accessRight.permissions.some(permission => {
          return permission.type === constants.permissions['payment-options'].value;
        });
      }
    });
  }

  checkProductPermission(accessRights: IAccessRight[], currentInstance: IAccessRight, product: string, allPermissions: Array<string>) {
    if (!accessRights || !currentInstance) {
      return false;
    }
    if (this.checkIsAdmin(currentInstance, allPermissions)) {
      return true;
    }
    return this.checkPermission(currentInstance, product);
  }

  /**
   *
   * Check any of the Role permissions
   *
   * @param {string[]} permissions Role permissions
   * @param {IUser} user
   * @returns {boolean}
   *
   * @memberof Provider
   */
  hasAnyPermission(permissions: string[], user: IUser) {
    user.roles = user.roles || <IRole[]>[];
    const permDict = CommonUtil.toDictionary(this.getAllPermissions(user) || [], 'type');

    for (let i = 0; i < permissions.length; i++) {
      if (permDict[permissions[i]]) {
        return true;
      }
    }

    return false;
  }

  hasAllPermissions(permissions: string[], user: IUser) {
    if (!user.roles || this.getAllPermissions(user).length) {
      return false;
    }

    const permDict = CommonUtil.toDictionary(this.getAllPermissions(user), 'type');

    for (let i = 0; i < permissions.length; i++) {
      if (!permDict[permissions[i]]) {
        return false;
      }
    }

    return true;
  }

  checkIsSuperAdmin(user: IUser) {
    return constants.adminRoles.some(adminRole => user.roles.find(role => role.type === adminRole))
      || this.hasAnyPermission(constants.adminRolePermissions, user);
  }

  isOnboarding() {
    return false;
    // TODO uncomment and adjust when the Hi-iQ onboarding flow is implemented
    // onboarding in progress
    // const user = this.getUser().user;
    // return user && user.customer && user.customer.onboard && user.customer.onboard.started
    //   && !user.customer.onboard.completed && !!user.customer.onboard.currentStateId;
  }

  isOnboardUser() {
    // user created through onboarding process
    const user = this.getUser().user;
    return user && user.customer && user.customer.onboard && user.customer.onboard.started
      && !!user.customer.onboard.currentStateId;
  }

  getAllPermissions = (user: IUser) => user.roles
    .reduce((prevPermissions, currRole) => prevPermissions.concat(currRole.permissions), <IPermission[]>[])
}
