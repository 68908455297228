import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { ToastrService } from 'ngx-toastr';

import { AsyncApi } from '../../ematic-core-ui/api/async-api';
import { UIProject } from '../../ematic-core-ui/models/ui-project';
import { IComment } from '../../models/comment';
import { PlatformService } from '../../ematic-core-ui/services/platform/platform.service';

@Injectable({
  providedIn: 'root'
})
export class CommentService extends AsyncApi {

  constructor(
    router: Router,
    http: HttpClient,
    toastr: ToastrService,
    platformService: PlatformService) {
    super(http, router, toastr, '/comments', platformService, UIProject.PLATFORM_DASHBOARD);
  }

  async insertComment(comment: IComment, notificationId: string): Promise<IComment> {
    return await this.postAsync({comment, notificationId}, '');
  }

  async updateComment(comment: IComment): Promise<IComment> {
    return await this.putAsync(comment, `/${comment._id}`);
  }

  async deleteComment(commentId: string): Promise<void> {
    await this.deleteAsync(`/${commentId}`);
  }
}
