import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import * as moment from 'moment-timezone-tsc';
import { Observable, of, range } from 'rxjs';
import { delay, toArray } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';

import { IUser } from '../models/user';
import { IInstance } from '../models/instance';
import { IAccessRight } from '../models/access-right';
import { IProduct } from '../models/product';
import { DropdownAlignment } from '../ematic-core-ui/directives/es-dropdown/es-dropdown.directive';
import { AuthService } from '../services/auth/auth.service';
import { SharedEventsService } from './shared-events.service';
import { ProductUtilService } from './product-util.service';

import { constants } from '../strings/constants';
import { environment } from '../../environments/environment';
import { constants as coreConstants } from '../ematic-core-ui/strings/constants';

export interface INavigationMenuProductItem {
  type: string;
  text: string;
  path: string;
  icon: string;
}

@Injectable()
export class UtilService {

  constructor(
    private authService: AuthService,
    private router: Router,
    private sharedEventsService: SharedEventsService,
    private productUtilService: ProductUtilService
  ) {
  }

  getRequestTimestamp(date: any, isStart: boolean) {
    date = (isStart) ? moment(date).startOf('day') : moment(date).endOf('day');
    return this.addBrowserOffset(date).unix();
  }

  addBrowserOffset(date) {
    return moment(date).add(moment(date).utcOffset(), 'minutes');
  }

  formatDateTime(date) {
    return this.addBrowserOffset(date).format(constants.dateFormat.DATETIME);
  }

  formatNotificationsDateTime(date): string {
    const isCurrentYear = moment(date).year() === moment().year();
    return moment(date).format(isCurrentYear
      ? constants.dateFormat.NOTIFICATION_NO_YEAR
      : constants.dateFormat.NOTIFICATION_WITH_YEAR
    );
  }

  findByProp(array, prop, value) {
    const result = array.filter((object) => {
      return object[prop] == value; // tslint:disable-line
    });
    return result[0] || null;
  }

  crunchStat(originalStat, label?) {
    const stat = Object.assign({}, originalStat);
    stat.label = label ? label.split('-').join('/') : '';
    stat.deliveredTotal = stat.send ? stat.send - stat.hard_bounce - stat.soft_bounce : 0;
    stat.deliveredPercent = stat.send ? Number((stat.deliveredTotal / stat.send) * 100).toFixed(2) + '%' : '0';
    stat.openedPercent = stat.send ? Number((stat.open / stat.send) * 100).toFixed(2) + '%' : '0';
    stat.clickedPercent = stat.send ? Number((stat.click / stat.send) * 100).toFixed(2) + '%' : '0';
    stat.transactionsPerSession = stat.transactionsPerSession ? (Number(stat.transactionsPerSession) * 100).toFixed(
      2) + '%' : '0';
    return stat;
  }

  makePositive(number) {
    return number < 0 ? 0 : number;
  }

  getTimezoneMessage(diff: number, user: IUser): string {
    if (diff === 0 || !user || user.isSuperAdmin) {
      return '';
    }
    const label1 = (diff < 0) ? 'ahead' : 'behind';
    const label2 = (diff < 0) ? 'behind' : 'ahead';
    return 'Your selected timezone is ' + Math.abs(diff) + ' hours ' + label1 + ' of your browser/system timezone, '
      + 'so the dates on the reports are ' + Math.abs(diff) + ' hours ' + label2 + ' selected timezone.';
  }

  getEmaticJs(apiKey: string) {
    return `
<script>
  (function(i,s,o,g,r,a,m){i['EmaticsObject']=r;i[r]=i[r]||function(){
  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  })(window,document,'script','//api.ematicsolutions.com/v1/ematic.min.js','ematics');
  var ematicApikey = ${ apiKey };
  //initialize
  ematics("create", ematicApikey, null);
</script>`;
  }

  getAccessRights() {
    return Object.keys(constants.accessRights).reduce((acc, key) => {
      const obj = {};
      obj['id'] = key;
      obj['text'] = constants.permissions[key].text;
      acc.push(obj);
      return acc;
    }, []);
  }

  getDateFromPeriod(period, isQuarter?) {
    const dateArray = period ? period.split('-') : null;
    return dateArray
      ? (isQuarter ? `Q${ dateArray[1].substring(1) }/${ dateArray[0] }` : `${ dateArray[0] }/${ dateArray[1] }`)
      : '';
  }

  setExportResult(prop, item, result) {
    result[prop.headerText.replace(' ', '_')] = (prop.formatter) ? prop.formatter({ [prop.field]: item[prop.field] }) : item[prop.field];
  }

  getCurrentOrganization(user: IUser) {
    return (user.customer && user.roles.find(role => role.type === 'CUSTOMER'))
      ? user.customer.organization
      : user.state.dashboard.admin.organization.currentOrganization;
  }

  getCurrentInstance(user: IUser): IInstance {
    return (user.customer && user.roles.find(role => role.type === 'CUSTOMER'))
      ? user.state.dashboard.currentInstance.instance
      : user.state.dashboard.admin.instance.currentInstance || user.state.dashboard.admin.instance.currentInstanceId;
  }

  // Set value for css variable (pseudo-element:after for property width)
  getProgressLine(stateId: number) {
    document.documentElement.style.setProperty('--progress-width', `${ stateId * 50 }%`);
  }

  getAllAvailableInstanceObjects(user): IInstance[] {
    return user.customer
      ? user.customer.accessRights.map((ar: IAccessRight) => ar.instance)
      : user.state.dashboard.admin.instance.instances;
  }

  getAllInstancesObject(user) {
    return {
      _id: 'all',
      name: 'Across all instances',
      ematicInstanceIds: user.customer
        ? user.customer.accessRights.map((ar: IAccessRight) => ar.instance.ematicInstanceId)
        : user.state.dashboard.admin.instance.instances.map((inst: IInstance) => inst.ematicInstanceId)
    };
  }

  async addAllInstancesOption(user: any) {
    let acrossAllAdded = false;
    await this.authService.updateUser(updateUser => {
      if (user.customer && user.customer.accessRights.length > 1
        && !user.customer.accessRights.find(item => item.instance._id === 'all')) {
        const permissions = this.getAllInstancesPermissions(user.customer.accessRights, [
          constants.permissions['trend-analysis'].value,
          constants.permissions['bye-iq'].value,
          constants.permissions['retry-iq'].value,
          constants.permissions['hi-iq'].value
        ]);

        if (permissions) {
          user.customer.accessRights.push({
            instance: this.getAllInstancesObject(user),
            permissions: permissions
          });
          updateUser.customer.accessRights = user.customer.accessRights;
          acrossAllAdded = true;
        }
      } else if (!user.customer && user.state.dashboard.admin.instance.instances.length > 1
        && !user.state.dashboard.admin.instance.instances.find(item => item._id === 'all')) {
        user.state.dashboard.admin.instance.instances.push(this.getAllInstancesObject(user));
        updateUser.state.dashboard.admin.instance.instances = user.state.dashboard.admin.instance.instances;
        acrossAllAdded = true;
      }
    });
    if (acrossAllAdded) {
      this.sharedEventsService.broadcast(constants.events.ACROSS_ALL_ADDED);
    }
  }

  shouldRedirectOnAcrossAll(user, url) {
    if (url === constants.states.BYE_IQ) {
      return !this.productUtilService.anyInstanceHasProduct(user, constants.products.BYE_IQ.value);
    }

    if (url === constants.states.HI_IQ) {
      return !this.productUtilService.anyInstanceHasProduct(user, constants.products.HI_IQ.value);
    }
  }

  async onAllInstancesSelected() {
    await this.authService.updateUser((oldUserData: IUser) => {
      oldUserData.allInstancesActive = true;
      if (oldUserData.customer) {
        oldUserData.state.dashboard.currentInstanceId = 'all';
      } else {
        oldUserData.state.dashboard.admin.instance.currentInstanceId = 'all';
      }
    });

    this.sharedEventsService.broadcast(constants.events.INSTANCE_CHANGED);
    const url = this.router.url;
    const { user } = this.authService.getUser();

    if (this.shouldRedirectOnAcrossAll(user, url)) {
      this.router.navigate([constants.states.DASHBOARD]);
    }
  }

  getAllInstancesPermissions(accessRights, pagePermissions: string[]) {
    let hasPagePermission = true;  // each instance needs to have page permission
    accessRights.forEach(item => {
      hasPagePermission = item.permissions.find(p => pagePermissions.indexOf(p.type) !== -1);
    });
    return hasPagePermission ? pagePermissions.map((permission: string) => ({ type: permission })) : null;
  }

  capitalizeString(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  getLength(array: any[]): number {
    return array && array.length ? array.length - 1 : 0;
  }

  isSubstring(text: string, target: string, caseInsensitive = false): boolean {
    if (caseInsensitive) {
      text = text.toLowerCase();
      target = target.toLowerCase();
    }
    return text.indexOf(target) !== -1;
  }

  getCardType(cardNumber) {
    let re = new RegExp('^(4026|417500|4508|4844|491(3|7))');
    if (cardNumber.match(re) != null) {
      return 'visa-electron';
    }

    re = new RegExp('^4');
    if (cardNumber.match(re) != null) {
      return 'visa';
    }

    re = new RegExp(
      '^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$');
    if (cardNumber.match(re) != null) {
      return 'mastercard';
    }

    re = new RegExp('^3[47]');
    if (cardNumber.match(re) != null) {
      return 'american-express';
    }

    return '';
  }

  formatCreditCardNumber(cardNumber) {
    return cardNumber.match(new RegExp('.{1,4}', 'g')).join(' ');
  }

  getPredictedDataKey(dataPeriod: string): string {
    if (dataPeriod === 'month') {
      return 'nextMonths';
    } else if (dataPeriod === 'year') {
      return 'nextYears';
    } else {
      return `next${ this.capitalizeString(dataPeriod) }`;
    }
  }

  queryMailingFrequencyIds(): Observable<number[]> {
    return range(1, 6).pipe(toArray(), delay(10));
  }

  // Active = 1, Inactive = 0
  queryStatusesByNumber(): Observable<number[]> {
    return range(0, 2).pipe(toArray(), delay(10));
  }

  queryRunStartTimes(): Observable<string[]> {
    const runStartTimes = [];

    for (let i = 0; i <= 23; i++) {
      const runStartTime = i < 10 ? `0${ i }:00:00` : `${ i }:00:00`;
      runStartTimes.push(runStartTime);
    }

    return of(runStartTimes).pipe(delay(10));
  }

  getAdminToolBaseURL() {
    return environment.adminTool.baseUrl + (environment.useHash ? '/#' : '');
  }

  getDropdownAlignment(): DropdownAlignment {
    const width = window.innerWidth;
    if (width > coreConstants.MEDIA_POINTS.TABLET_BREAKPOINT) {
      return 'right';
    } else {
      return 'left';
    }
  }

  makeFormAsPristineAndUntouched(form: NgForm): void {
    if (form) {
      form.form.markAsPristine();
      form.form.markAsUntouched();
    }
  }

  getMartechAbbreviation(name: string): string {
    switch (name) {
      case 'Adjust':
        return 'adjust';
      case 'Adobe':
        return 'adobec';
      case 'Amplitude':
        return 'amplitude';
      case 'AppsFlyer':
        return 'appsflyer';
      case 'Branch':
        return 'branch';
      case 'Braze':
        return 'braze';
      case 'Campaign Monitor':
        return 'cm';
      case 'DotDigital':
        return 'dd';
      case 'Emarsys':
        return 'emarsys';
      case 'GetResponse':
        return 'gr';
      case 'Google Analytics':
        return 'ga';
      case 'Insider':
        return 'ins';
      case 'Iterable':
        return 'iter';
      case 'MailChimp':
        return 'mc';
      case 'Mailjet':
        return 'mjet';
      case 'Mandrill':
        return 'mand';
      case 'Mixpanel':
        return 'mpanel';
      case 'MoEngage':
        return 'me';
      case 'mParticle':
        return 'mparticle';
      case 'OneSignal':
        return 'onesignal';
      case 'Responsys':
        return 'respon';
      case 'Salesforce':
        return 'slf';
      case 'Segment':
        return 'segm';
      case 'Sendgrid':
        return 'sendg';
      case 'Sendy':
        return 'sendy';
      case 'Tealium':
        return 'teal';
      default:
        return null;
    }
  }

  getAcrossAllInstancesProducts(user: IUser): IProduct[] {
    let allInstances = this.getAllAvailableInstanceObjects(user).filter(instance => instance._id !== 'all');
    if (user.customer) {
      allInstances = allInstances.filter(instance =>
        user.customer.accessRights.some(ar =>
          ar.instance._id === instance._id && ar.permissions.some(p =>
          p.type === constants.permissions['trend-analysis'].value)
        )
      );
    }

    for (const instance of allInstances) {
      const emailProduct = this.productUtilService.getEmailProduct(instance);
      if (emailProduct) {
        return [emailProduct]; // RS expects at least one valid ematicProductId
      }
    }

    return [];
  }

  getProductsByInstance(instance: IInstance): IProduct[] {
    const products = [];
    // currently, we can get report for only primary product among Email Performance, Hi-iQ, Bye-iQ and Retry-iQ products
    const emailProduct = this.productUtilService.getEmailProduct(instance);
    if (emailProduct) {
      products.push(emailProduct);
    }
    products.push(...cloneDeep(instance.products).filter(p =>
      p.type !== constants.trendAnalysisProducts.nonPrimary.historic &&
      this.productUtilService.getNonPrimaryProductTypes().includes(p.type)));

    return products;
  }

  getMyProducts(user: IUser, espType: string): IProduct[] {
    if (user.allInstancesActive) {
      return [];
    }

    const products = this.getProductsByInstance(this.getCurrentInstance(user)).filter(p => p.espData.type.toLowerCase() === espType);
    const clonedProducts = cloneDeep(products).map((p: IProduct) => {
      p.displayName = this.productUtilService.getProductName(p, true);
      p.medium = this.productUtilService.getProductChannel(p).text;
      return p;
    });

    return [...new Map(clonedProducts.map(p => [p.medium, p])).values()];
  }

  getNavigationMenuProductItems(user: IUser): INavigationMenuProductItem[] {
    if (user.allInstancesActive) {
      return [];
    }

    const products = this.getProductsByInstance(this.getCurrentInstance(user));

    return [...new Map(products.map(p => [p.espData.type, p])).values()].map(p => {
      return {
        type: p.espData.type,
        text: this.productUtilService.getProductName(p, true),
        path: this.productUtilService.getRelativeProductPath(p),
        icon: this.productUtilService.getProductIcon(p)
      };
    });
  }

  getTrendAnalysisProducts(user: IUser, addRunStats: boolean): IProduct[] {
    const currentInstance = this.getCurrentInstance(user);
    const products = user.allInstancesActive
      ? this.getAcrossAllInstancesProducts(user)
      : this.getProductsByInstance(currentInstance);

    if (addRunStats) {
      products.push(<IProduct>{
        _id: constants.derivedProducts.RUN_STATS.value,
        type: constants.derivedProducts.RUN_STATS.value
      });
    }

    return cloneDeep(products).map((p: IProduct) => {
      p.displayName = this.productUtilService.getProductName(p, false);
      return p;
    });
  }
}
