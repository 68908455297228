import { IEnvironment } from '../app/models/environment';

export const environment: IEnvironment = {
  production: false,
  baseUrl: {
    amphoria: 'https://platform-api-development.amphoria.io/api/dashboard',
    elixusagency: 'https://platform-api-development.elixusagency.com/api/dashboard',
    ematicsolutions: 'https://platform-api-development.ematicsolutions.com/api/dashboard',
    hiiq: 'https://platform-api-development.hiiq.ai/api/dashboard'
  },
  stripePublishableKey: 'pk_test_KzBct0KUlKc5t7HeUgctuwRs',
  googleClientId: '936203960371-ma6bul6fpapoh8q76a66u8sra84rg9uj.apps.googleusercontent.com',
  reCaptchaSiteKey: '6Lf2U1AUAAAAAF9CxILLWocUDY0hmk3p9jNaEqdp',
  reCaptchaEnabled: false,

  redirectURI: 'https://platform-development.ematicsolutions.com/oauth.html',
  mailchimp: {
    authorizeURI: 'https://login.mailchimp.com/oauth2/authorize',
    clientId: '544189261971'
  },
  campaignMonitor: {
    authorizeURI: 'https://api.createsend.com/oauth',
    clientId: '120248',
    loginURL: 'https://ematicsolutions12.createsend.com/login'
  },
  getResponse: {
    authorizeURI: 'https://app.getresponse.com/oauth2_authorize.html',
    clientId: '4e29674e-6839-11ea-bcf1-00163e0b45b1'
  },
  facebook: {
    authorizeURI: 'https://www.facebook.com/v9.0/dialog/oauth',
    clientId: '450219266031581'
  },

  intercom: {
    appId: 'rhrdllc1'
  },
  adminTool: {
    baseUrl: 'https://platform-admin-development.ematicsolutions.com'
  },
  useHash: true,
  devEnvironment: true,
  googleAnalyticsId: 'UA-145772696-2',
  googleOptimizeId: 'GTM-5W38QLP',
  googleTagManagerId: 'GTM-T8CZ6TB',
  emails: {
    amphoria: {
      supportTeam: 'support_test@ematicsolutions.com',
      inboundAccounts: 'inbound_accounts_test@ematicsolutions.com'
    },
    elixusagency: {
      supportTeam: 'support_test@ematicsolutions.com',
      inboundAccounts: 'inbound_accounts_test@ematicsolutions.com'
    },
    ematicsolutions: {
      supportTeam: 'support_test@ematicsolutions.com',
      inboundAccounts: 'inbound_accounts_test@ematicsolutions.com'
    },
    hiiq: {
      supportTeam: 'support_test@ematicsolutions.com',
      inboundAccounts: 'inbound_accounts_test@ematicsolutions.com'
    }
  },
  googleStorage: {
    marketplaceUrl: 'https://storage.googleapis.com/ematic-marketplace-development-public'
  }
};
