import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { IUser } from '../../models/user';
import { IAccessRight } from '../../models/access-right';
import { OnboardService } from '../onboard/onboard.service';
import { UserService } from '../user/user.service';
import { ProductUtilService } from '../../util/product-util.service';
import { StaticService } from '../static/static.service';

import { constants } from '../../strings/constants';
import { constants as coreConstants } from '../../ematic-core-ui/strings/constants';

@Injectable()
export class PermissionsService {

  user: IUser;
  permissions: IAccessRight[];
  permissionsMap: any;
  superAdminUnAllowedStates: string[];

  constructor(
    private authService: AuthService,
    private onboardService: OnboardService,
    private productUtilService: ProductUtilService,
    private staticService: StaticService
  ) {
    this.loadPermissionsMap();
    this.loadSuperAdminUnAllowedStates();
  }

  shouldForbidAccessWithoutAnyProducts(url: string, state: string, product: string): boolean {
    return url === state && !this.productUtilService.anyInstanceHasProduct(this.user, product);
  }

  shouldForbidAccessWithAllInstances(url: string): boolean {
    return this.user.allInstancesActive && (
      url === constants.states.BILLING ||
      url === constants.states.MARKETPLACE ||
      url === constants.states.ESP_OAUTH ||
      url === constants.states.USERS ||
      url === constants.states.ECOSYSTEM ||
      url === constants.states.ACCESS_RIGHTS ||
      url.includes(constants.states.MY_PRODUCTS) ||
      this.shouldForbidAccessWithoutAnyProducts(url, constants.states.HI_IQ, constants.products.HI_IQ.value) ||
      this.shouldForbidAccessWithoutAnyProducts(url, constants.states.RETRY_IQ, constants.products.RETRY_IQ.value) ||
      this.shouldForbidAccessWithoutAnyProducts(url, constants.states.BYE_IQ, constants.products.BYE_IQ.value)
    );
  }

  hasPermission(url: string): boolean {
    this.user = this.authService.getUser().user;

    if (!this.user) {
      return false;
    }

    if (this.shouldForbidAccessWithAllInstances(url)) {
      return false;
    }

    // TODO uncomment when ready
    if (/*UserService.isCustomer(this.user) && */url === constants.states.USERS) {
      return false;
    }

    if (UserService.isCustomer(this.user) && url === constants.states.CUSTOMER_SUPPORT) {
      return false;
    }

    // TODO uncomment when ready
    if (/*UserService.isCustomer(this.user) && */url === constants.states.ECOSYSTEM) {
      return false;
    }

    // TODO uncomment when Hi-iQ onboarding flow is implemented
    // if ((!this.authService.isOnboardUser() || !this.authService.isOnboarding()) && url === constants.states.CONNECT_ESP) {
    //   // if user is not onboard or onboarding is completed user shouldn't be allowed to access connect-esp
    //   return false;
    // }

    if (url === constants.states.ESP_OAUTH && !this.staticService.isOAuthInProgress()) {
      return false;
    }

    if (this.documentWidth < coreConstants.MEDIA_POINTS.TABLET_BREAKPOINT && url === constants.states.ECOSYSTEM) {
      return false;
    }

    if (url.indexOf(constants.states.ESP_OAUTH) !== -1 && url.indexOf('?code') !== -1) {
      return true;
    }

    if (this.user.isSuperAdmin) {
      return (this.superAdminUnAllowedStates.indexOf(url) === -1);
    }

    // If user has access to marketplace page s/he has access to it's children
    if (url.includes(constants.states.MARKETPLACE)) {
      url = constants.states.MARKETPLACE;
    }

    if (url.includes(constants.states.MY_PRODUCTS)) {
      url = constants.states.MY_PRODUCTS;
    }

    if (!UserService.isCustomer(this.user)) {
      return true;
    }

    const currentInstanceId = this.user.state.dashboard.currentInstanceId;
    this.permissions = this.user.customer.accessRights;

    return this.permissions.some(permission => {
      if (permission.instance._id === currentInstanceId) {
        return permission.permissions.some(permissionType => {
          return this.permissionsMap[url][permissionType.type];
        });
      }
    });
  }

  getRouteForPermission(user: IUser): string {
    if (!user.roles.find(role => role.type === 'CUSTOMER')) {
      return constants.states.DASHBOARD;
    }

    if (user.isBillingOnly) {
      return constants.states.BILLING;
    }

    if (this.authService.isOnboarding()) {
      this.onboardService.loadCurrentState();
      return this.onboardService.getState().route;
    }

    const permissions = user.state.dashboard.currentInstance.permissions;
    const states = constants.states;
    const permMap = this.permissionsMap;
    const result = Object.keys(states).find(state => {
      if (permissions.length === 1) {
        return permMap[states[state]][permissions[0].type];
      } else {
        return permissions.some(perm => permMap[states[state]][perm.type]);
      }
    });
    return states[result];
  }

  loadSuperAdminUnAllowedStates(): void {
    if (!this.superAdminUnAllowedStates) {
      this.superAdminUnAllowedStates = [constants.states.USER_PROFILE];
    }
  }

  loadPermissionsMap(): void {
    if (!this.permissionsMap) {
      this.permissionsMap = {
        [constants.states.DASHBOARD]: {
          'trend-analysis': true,
          'payment-options': false,
          'hi-iq': true,
          'bye-iq': true,
          'retry-iq': true,
          'marketplace': false,
          'access-rights': false
        },
        [constants.states.MY_PRODUCTS]: {
          'trend-analysis': true,
          'payment-options': false,
          'hi-iq': false,
          'bye-iq': false,
          'retry-iq': false,
          'marketplace': false,
          'access-rights': false
        },
        [constants.states.TREND_ANALYSIS]: {
          'trend-analysis': true,
          'payment-options': false,
          'hi-iq': false,
          'bye-iq': false,
          'retry-iq': false,
          'marketplace': false,
          'access-rights': false
        },
        [constants.states.HI_IQ]: {
          'trend-analysis': false,
          'payment-options': false,
          'hi-iq': true,
          'bye-iq': false,
          'retry-iq': false,
          'marketplace': false,
          'access-rights': false
        },
        [constants.states.BYE_IQ]: {
          'trend-analysis': false,
          'payment-options': false,
          'hi-iq': false,
          'bye-iq': true,
          'retry-iq': false,
          'marketplace': false,
          'access-rights': false
        },
        [constants.states.RETRY_IQ]: {
          'trend-analysis': false,
          'payment-options': false,
          'hi-iq': false,
          'bye-iq': false,
          'retry-iq': true,
          'marketplace': false,
          'access-rights': false
        },
        [constants.states.ECOSYSTEM]: {
          'trend-analysis': false,
          'payment-options': false,
          'hi-iq': false,
          'bye-iq': false,
          'retry-iq': false,
          'marketplace': false, // TODO set to true again when ready
          'access-rights': false
        },
        [constants.states.ACCESS_RIGHTS]: {
          'trend-analysis': false,
          'payment-options': false,
          'hi-iq': false,
          'bye-iq': false,
          'retry-iq': false,
          'marketplace': false,
          'access-rights': true
        },
        [constants.states.MARKETPLACE]: {
          'trend-analysis': false,
          'payment-options': false,
          'hi-iq': false,
          'bye-iq': false,
          'retry-iq': false,
          'marketplace': true,
          'access-rights': false
        },
        [constants.states.BILLING]: {
          'trend-analysis': false,
          'payment-options': true,
          'hi-iq': false,
          'bye-iq': false,
          'retry-iq': false,
          'marketplace': false,
          'access-rights': false
        },
        [constants.states.HELP_CENTER]: {
          'trend-analysis': true,
          'payment-options': true,
          'hi-iq': true,
          'bye-iq': true,
          'retry-iq': true,
          'marketplace': true,
          'access-rights': true
        },
        [constants.states.USER_PROFILE]: {
          'trend-analysis': true,
          'payment-options': true,
          'hi-iq': true,
          'bye-iq': true,
          'retry-iq': true,
          'marketplace': true,
          'access-rights': true
        },
        // TODO uncomment when Hi-iQ onboarding flow is implemented
        // [constants.states.CONNECT_ESP]: {
        //   'trend-analysis': true,
        //   'payment-options': true,
        //   'hi-iq': true,
        //   'bye-iq': true,
        //   'retry-iq': true,
        //   'marketplace': true,
        //   'access-rights': true
        // },
        [constants.states.USERS]: {
          'trend-analysis': true,
          'payment-options': false,
          'hi-iq': false,
          'bye-iq': false,
          'retry-iq': false,
          'marketplace': false,
          'access-rights': false
        },
        [constants.states.ESP_OAUTH]: {
          'trend-analysis': true,
          'payment-options': true,
          'hi-iq': true,
          'bye-iq': true,
          'retry-iq': true,
          'marketplace': true,
          'access-rights': true
        }
      };
    }
  }

  get documentWidth(): number {
    return window.innerWidth;
  }
}
