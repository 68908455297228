import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthService } from './auth.service';
import { constants } from '../../strings/constants';

@Injectable()
export class AuthGuard implements CanLoad, CanActivate, CanActivateChild {

  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.checkLogin();
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.checkLogin();
  }

  canLoad(route: Route): Observable<boolean> | boolean {
    return this.checkLogin();
  }

  checkLogin(): Observable<boolean> | boolean {
    const { user, accessToken } = this.authService.getUser();
    const token = localStorage.getItem(constants.token);

    if (user && accessToken) {
      return true;
    }

    if (token) {
      return token !== accessToken ? this.authService.loginByToken(token) : true;
    }

    this.router.navigate([constants.states.LOGIN]);
    return false;
  }
}
