import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Input, Optional, Renderer2, Self } from '@angular/core';
import { FormGroupDirective, NgControl, NgForm } from '@angular/forms';

import { EsInputField } from '../base/es-input-field';
import { EsFormFieldsetComponent } from '../es-form-fieldset/es-form-fieldset.component';
import { EsFormFieldComponent } from '../es-form-field/es-form-field.component';
import { CommonUtil } from '../../util/common-util';

@Component({
  selector: 'es-text-area',
  templateUrl: './es-text-area.component.html',
  styleUrls: ['./es-text-area.component.scss']
})
export class EsTextAreaComponent extends EsInputField<string> implements AfterViewChecked {
  @Input() copyable: boolean;
  @Input() verticallyResizable: boolean;
  @Input() maxLength: number;
  @Input() rows: number;
  @Input() cols: number;
  @Input() isAutoResizable: boolean;

  public get showCopyIcon(): boolean {
    return this.copyable && this.innerValue != null;
  }

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    @Optional() esFormFieldset: EsFormFieldsetComponent,
    @Optional() esFormField: EsFormFieldComponent,
    @Optional() parentForm: NgForm,
    @Optional() parentFormGroup: FormGroupDirective,
    @Self() @Optional() control: NgControl) {
    super(element, renderer, changeDetectorRef, esFormFieldset, esFormField, parentForm, parentFormGroup, control);

    this.copyable = false;
    this.verticallyResizable = false;
    this.maxLength = 524288;
    this.rows = 2;
    this.cols = 20;
    this.isAutoResizable = false;
  }

  ngAfterViewChecked(): void {
    this.autoResize();
  }

  // override from EsControlValueAccessor
  writeValue(value: string): void {
    if (value && typeof value === 'string') {
      value = value.trim();
    }

    super.writeValue(value);
  }

  public onCopyValue(): void {
    CommonUtil.copyToClipboard(this.innerValue ? this.innerValue : '');
  }

  onInput(): void {
    if (this.isAutoResizable) {
      this.autoResize();
    }
  }

  // override from EsInputField
  onInnerInputKeyPress(event: KeyboardEvent) {
    if (event.code === 'Enter' && event.ctrlKey && !this.isInvalid) {
      this.submit.emit();
    }
  }

  autoResize(): void {
    this.inputFieldElement.style.height = 'auto';
    this.inputFieldElement.style.height = this.inputFieldElement.scrollHeight + 'px';
  }
}
