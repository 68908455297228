// core modules
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

// app modules
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { TermsAndConditionsModule } from './components/terms-and-conditions/terms-and-conditions.module';
import { EmaticCoreUIModule } from './ematic-core-ui/ematic-core-ui.module';

// components
import { ExternalComponent } from './views/external/external.component';
import { LoginComponent } from './views/external/login/login.component';
import { UnauthorizedComponent } from './views/external/unauthorized/unauthorized.component';
import { PageNotFoundComponent } from './views/external/page-not-found/page-not-found.component';
import { ForgotPasswordComponent } from './views/external/forgot-password/forgot-password.component';
import { RegisterComponent } from './views/external/register/register.component';
import { ResetPasswordComponent } from './views/external/reset-password/reset-password.component';
import { CreateAccountComponent } from './views/external/create-account/create-account.component';

// services
import { ProductService } from './services/product/product.service';
import { ReportService } from './services/report/report.service';
import { UserService } from './services/user/user.service';
import { UtilService } from './util/util.service';
import { SharedEventsService } from './util/shared-events.service';
import { AuthService } from './services/auth/auth.service';
import { PaymentService } from './services/payment/payment.service';
import { IntegrationsService } from './services/integrations/integrations.service';
import { PermissionsService } from './services/auth/permissions.service';
import { PermissionsGuard } from './services/auth/permissions-guard.service';
import { PermissionTypesService } from './services/permission-types/permission-types.service';
import { GapiService } from './services/google/gapi.service';
import { GoogleAnalyticsService } from './services/google/google-analytics.service';
import { MailChimpService } from './services/mailchimp/mailchimp.service';
import { CampaignMonitorService } from './services/campaign-monitor/campaign-monitor.service';
import { GetResponseService } from './services/get-response/get-response.service';
import { ContactService } from './services/contact/contact.service';
import { ReportCacheService } from './services/report/report-cache.service';
import { ReportUtil } from './services/report/report.util';
import { OnboardService } from './services/onboard/onboard.service';
import { InstanceSelectService } from './services/instance-select/instance-select.service';
import { HeaderService } from './views/internal/header/header.service';
import { OrganizationService } from './services/organization/organization.service';
import { StaticService } from './services/static/static.service';
import { CSAdminService } from './services/cs-admin/cs-admin.service';
import { MartechService } from './services/martech/martech.service';
import { EcosystemService } from './services/ecosystem/ecosystem.service';
import { InternalService } from './services/internal/internal.service';
import { FacebookOAuthService } from './services/facebook-oauth/facebook-oauth.service';
import { CommentService } from './services/comment/comment.service';
import { OptimonkService } from './services/optimonk/optimonk.service';

// 3rd party modules
import { RecaptchaModule } from 'ng-recaptcha';

@NgModule({
  declarations: [
    AppComponent,
    ExternalComponent,
    LoginComponent,
    UnauthorizedComponent,
    PageNotFoundComponent,
    ForgotPasswordComponent,
    RegisterComponent,
    ResetPasswordComponent,
    CreateAccountComponent
  ],
  imports: [
    BrowserModule,
    EmaticCoreUIModule,
    TermsAndConditionsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      preventDuplicates: true
    }),
    RecaptchaModule
  ],
  providers: [
    AuthService,
    ProductService,
    ReportService,
    UtilService,
    SharedEventsService,
    UserService,
    PaymentService,
    IntegrationsService,
    PermissionsService,
    PermissionsGuard,
    PermissionTypesService,
    GapiService,
    GoogleAnalyticsService,
    MailChimpService,
    CampaignMonitorService,
    GetResponseService,
    ContactService,
    OnboardService,
    ReportCacheService,
    ReportUtil,
    InstanceSelectService,
    HeaderService,
    OrganizationService,
    StaticService,
    CSAdminService,
    MartechService,
    EcosystemService,
    InternalService,
    FacebookOAuthService,
    CommentService,
    OptimonkService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
