<app-external>
  <ng-container content-container>
    <div class="content-form">
      <a class="logo-link" [attr.href]="corporateUrl" target="_blank">
        <img class="ematic-logo"
             [srcset]="platformLogo.imageDpr1 + ' 1x, ' + platformLogo.imageDpr2 + ' 2x'"
             [alt]="platformText">
      </a>

      <div class="content-header" *ngIf="!sent">
        <span class="content-title">
          Get started with {{ platformName }}
        </span>
        <p class="content-subtitle">
          Enter your email address below and we will send you a confirmation email
          to get you kickstarted with {{ platformName }}.</p>
      </div>

      <form #createAccountForm="ngForm" class="form" *ngIf="!sent">
        <input type="text"
               class="email-input"
               placeholder="Email *"
               name="email"
               [(ngModel)]="email"
               (keyup.enter)="sendInvite()"
               emailValidator
               required />
        <re-captcha *ngIf="reCaptchaEnabled"
                    (resolved)="handleCaptcha($event)"
                    size="compact"
                    class="recaptcha-mobile"
                    [siteKey]="siteKey">
        </re-captcha>
        <re-captcha *ngIf="reCaptchaEnabled"
                    (resolved)="handleCaptcha($event)"
                    size="normal"
                    class="recaptcha-desktop"
                    [siteKey]="siteKey">
        </re-captcha>
        <es-button
          class="btn-block"
          text="Get Started"
          [type]="'primary'"
          [loading]="loading"
          [disabled]="!createAccountForm.form.valid || !createAccountForm.form.dirty || !captchaResponse"
          [isRounded]="true"
          (clicked)="sendInvite()">
        </es-button>
        <p class="corporate-link">Already using {{ platformName }}?
          <a class="sign-up-link" routerLink="{{loginState}}">Login here</a>
        </p>
      </form>
      <form *ngIf="sent">
        <h4 class="title-external">Check your inbox.</h4>
        <p>We've emailed you a link to verify your email and complete account registration.
          It should arrive within a few minutes.
        </p>
      </form>
    </div>
    <div class="content-bg"></div>
  </ng-container>
</app-external>
