<div class="container">
  <div class="body">
    Last Revised: January 10, 2019 <br><br>
    Terms of Service <br><br>
    PLEASE READ THESE TERMS OF SERVICE CAREFULLY. <br><br>

    These Terms of Service constitute an agreement (this “Agreement”) by and between Ematic Solutions Pte. Ltd., a company incorporated in Singapore with a principal place of business located at 29A Cuppage Road, #02-00, Cuppage Terrace, Singapore 229456, on behalf of itself, and subsidiaries and affiliates (“Ematic”) and the company, partnership, sole proprietorship, or other business entity executing this Agreement (“Customer”). This Agreement is effective as of the date Customer clicks “Accepted and Agreed To” or executes an Order form with Ematic (the “Effective Date”). Use of the Platform by Customer, including Customer’s Users, and Ematic’s provision of Ematic’s Platform (all as defined below in Section 1.6) are governed by this Agreement and our
    <a href="https://vault.pactsafe.io/s/0b8647ba-8384-458a-9855-757fd8cbfde4/legal.html#privacy-and-data-protection-policy" target="_blank">Privacy Policy.</a>
    <br><br>
    EACH PARTY ACKNOWLEDGES THAT IT HAS READ THIS AGREEMENT, UNDERSTANDS IT, AND AGREES TO BE BOUND BY ITS TERMS, AND THAT THE PERSON SIGNING ON ITS BEHALF HAS BEEN AUTHORIZED TO DO SO. THE PERSON EXECUTING THIS AGREEMENT ON CUSTOMER’S BEHALF REPRESENTS THAT HE OR SHE HAS THE AUTHORITY TO BIND CUSTOMER TO THESE TERMS AND CONDITIONS
    <br><br>

    1. DEFINITIONS. The following capitalized terms shall have the following meanings whenever used in this Agreement.
    <br><br>
    1.1 “Customer Data” means data in electronic form input or collected through the Platform by or from Customer, including without limitation by Customer’s Users or from individuals or business entities doing business with Customer or that Customer has invited to use the Platform.
    <br>
    1.2 “Documentation” means any written documentation provided to Customer by Ematic to facilitate Customer’s use of the Platform under the terms of this Agreement. .
    <br>
    1.3 “Order” means an order for access to the Platform created either electronically through the Platform when Customer opens an Ematic account or in the form of a document signed by both Ematic and Customer, including by electronic signature
    <br>
    1.4 “Privacy Policy” means Ematic’s privacy policy, which you can view
    <a href="https://vault.pactsafe.io/s/0b8647ba-8384-458a-9855-757fd8cbfde4/legal.html#privacy-and-data-protection-policy" target="_blank">here.</a>
    <br>
    1.5 “Platform” means Ematic’s Email Intelligence Cloud Platform which includes Bye-IQ, Hi-IQ, and Retry-IQ.
    <br>
    1.6 “Term” is defined in Section 11.1 below.
    <br>
    1.7 “User” means any individual who uses the Platform on Customer’s behalf or through Customer’s account or passwords, whether authorized or not
    <br><br>

    2. THE PLATFORM.<br><br>

    2.1 Use of the Platform. During the Term, Customer may access and use the Platform pursuant to: (a) the terms of any outstanding Order, including such features and functions as the Order requires; and (b) Ematic’s policies posted on its Website at www.ematicsolutions.com, as such policies may be updated from time to time.
    <br>
    2.2 Documentation: Customer may reproduce and use the Documentation solely as necessary to support Users’ use of the Platform.
    <br>
    2.3 Platform Revisions. Ematic may revise Platform features and functions at any time, including without limitation by removing such features and functions. If any such revision to the Platform materially reduces features or functionality provided pursuant to an Order, Customer may within 30 days of notice of the revision terminate such Order, without cause, or terminate this Agreement without cause if such Order is the only one outstanding.
    <br><br>

    3. PLATFORM FEES, BILLING & PAYMENT.<br><br>

    3.1 Subscription Fees. Customer shall pay Ematic the fee set forth in each Order (the “Subscription Fee”) for each Term. Ematic will not be required to refund the Subscription Fee under any circumstances.
    <br>
    3.2 Payment. Customer will make payment only by credit card, demand draft or wire transfer (the “Payment Method”). Customer authorizes Ematic to transmit Customer’s Payment Method information to Ematic’s third-party payment processing vendor, which may store such information in order to facilitate payments. Customer shall promptly notify Ematic in writing of any change in Customer’s invoicing address or changes related to the Payment Method, which will be effective 5 days after Ematic’s receipt of Customer’s notification. Customer authorizes Ematic or its payment processing vendor to bill Customer, through Customer’s authorized Payment Method, for the applicable subscription fees, along with any applicable taxes and any other charges Customer may incur in connection with its use of the Platform. Customer acknowledges that it is solely responsible for any and all fees charged to Customer by its credit card issuer, bank, or other financial institution.
    <br>
    3.3 Invoices. Ematic may: (a) send invoices generated through the Platform, via email, or such other means as it may select from time to time; and (b) invoice Customer through Customer’s Payment Method at the beginning of each Billing Period. Customer may request an additional invoice or an invoice in a different format by emailing {{ supportTeamEmailDisplayText }}. Increases in Subscription Fees based on increases in customer database size will be effective and invoiced at the time of the increase, on a pro-rated basis if the change is made during a Billing Period.  Decreases in paid subscription plan tiers will be effective upon renewal of the next Billing Period. If Customer does not notify Ematic in writing of a billing problem or discrepancy within 20 days of the relevant invoice date, Customer waives any right or dispute related to such problem or discrepancy.
    <br>
    3.4 Taxes. All fees are exclusive of taxes, levies, and duties, and Customer will be responsible for payment of all such taxes, levies, and duties, including value-added tax (VAT), withholding, or similar taxes. Ematic may calculate taxes based on the billing information Customer provides.
    <br>
    3.5 Effect of Nonpayment. Ematic may suspend Customer’s access to the Platform without advanced notice if Customer fails to pay any invoice in full when due. In addition to such other rights as Ematic may have, including without limitation pursuant to Section 11.2 (Termination without Cause) and Section 11.3(Termination for Cause) below, Customer shall continue to pay fees during any suspension. Customer will reimburse any costs or expenses (including, but not limited to, reasonable legal fees) incurred by Ematic to collect any overdue amount. Ematic may accept any check or payment in any amount without prejudice to its right to recover the balance or to pursue any other right or remedy. Customer may not withhold amounts due to Ematic under this Agreement for any reason or offset them against amounts that Customer asserts are owed to Customer by Ematic
    <br><br>

    4. CUSTOMER DATA & PRIVACY. <br><br>

    4.1 Use of Customer Data. Ematic requires all its employees to sign strict confidentiality agreements as part of their employment agreement. Unless it receives Customer’s prior written consent, Ematic: (a) shall not access, process, or otherwise use Customer Data other than as necessary to facilitate the Platform, to prevent or address service or technical problems, or at Customer’s request in order to provide Support; and (b) shall not intentionally grant any third party access to Customer Data, including without limitation Ematic’s other customers, except subcontractors that are subject to a reasonable nondisclosure agreement. Notwithstanding the foregoing, Ematic may disclose Customer Data as required by applicable law or by proper legal or governmental authority. Ematic shall give Customer prompt notice of any such legal or governmental demand and reasonably cooperate with Customer in any effort to seek a protective order or otherwise to contest such required disclosure, at Customer’s expense.
    <br>
    4.2 Customer Responsible for Customer Data. Customer represents and warrants to Ematic that Customer has obtained all permissions, releases, rights or licenses required to collect the Customer Data and/or to input Customer Data into the Platform.
    <br>
    4.3 Privacy Policy. The Privacy Policy applies only to the Platform and does not apply to any third party website or service linked to the Platform or recommended or referred to through the Platform or by Ematic’s staff.
    <br>
    4.4 Risk of Exposure. Customer recognizes and agrees that hosting data online involves risks of unauthorized disclosure or exposure and that, in accessing and using the Platform, Customer assumes such risks. Ematic offers no representation, warranty, or guarantee that Customer Data will not be exposed or disclosed through errors or the actions of third parties.
    <br>
    4.5 Data Accuracy. Ematic shall have no responsibility or liability for the accuracy of data uploaded to the Platform by Customer, including without limitation Customer Data and any other data uploaded by Users.
    <br>
    4.6 Treatment of Customer Data Following Termination. Within 30 days following termination of this Agreement, upon Customer’s request and provided that Customer has paid all amounts due to Ematic, Ematic will provide Customer with a copy of the Customer Data in a format chosen by Ematic. Customer further agrees that Ematic will not be liable to Customer or to any third party for any deletion of Customer Data after such 30-day period.
    <br>
    4.7 Aggregate & Anonymized Data. Notwithstanding the provisions above of this Article 4, in order to maintain, provide and improve the Platform, Ematic may use, reproduce or analyze Aggregate Data for the improvement of the Platform. (“Aggregate Data” refers to Customer Data with the following removed: personally identifiable information of individuals, the names and addresses of Customer and any of its Users or other parties with which it is doing business, and the identity of any such party linked to specific contract terms or other business information).
    <br><br>

    5. CUSTOMER’S RESPONSIBILITIES & RESTRICTIONS. <br><br>

    5.1 Acceptable Use. Customer shall not: (a) use the Platform for service bureau or time-sharing purposes or in any other way allow third parties to exploit the Platform; (b) provide Platform passwords or other log-in information to any third party; (c) share non-public Platform features or content with any third party; or (d) access the Platform in order to build a competitive product or service, to build a product using similar ideas, features, functions or graphics of the Platform, or to copy any ideas, features, functions or graphics of the Platform. In the event that it suspects any breach of the requirements of this Section 5.1, including without limitation by Users, Ematic may suspend Customer’s access to the Platform without advanced notice, in addition to such other remedies as Ematic may have. This Agreement does not require that Ematic take any action against Customer or any User or other third party for violating this Section 5.1, or this Agreement, but Ematic is free to take any such action it sees fit.
    <br>
    5.2 Unauthorized Access. Customer shall take reasonable steps to prevent unauthorized access to the Platform, including without limitation by protecting its passwords and other log-in information. Customer shall notify Ematic immediately of any known or suspected unauthorized use of the Platform or breach of its security and shall use best efforts to stop said breach.
    <br>
    5.3 Compliance with Laws. In its use of the Platform, Customer shall comply with all applicable laws, including without limitation laws governing the protection of personally identifiable information and other laws applicable to the protection of Customer Data.
    <br>
    5.4 Users & Platform Access. Customer is responsible and liable for: (a) Users’ use of the Platform, including without limitation unauthorized User conduct and any User conduct that would violate the requirements of this Agreement applicable to Customer; (b) any use of the Platform through Customer’s account, whether authorized or unauthorized; and (c) obtaining all necessary permission or consent from the Users.
    <br> <br>

    6. IP & FEEDBACK <br><br>
    6.1 IP Rights to the Platform. Ematic retains all right, title, and interest in and to the Platform, including without limitation all software used to provide the Platform and all graphics, user interfaces, logos, and trademarks reproduced through the Platform. This Agreement does not grant Customer any intellectual property license or rights in or to the Platform or any of its components. Customer recognizes that the Platform and its components are protected by copyright and other laws.
    <br>
    6.2 Feedback. Ematic has not agreed to and does not agree to treat as confidential any Feedback (as defined below) Customer or Users provide to Ematic, and nothing in this Agreement or in the parties’ dealings arising out of or related to this Agreement will restrict Ematic’s right to use, profit from, disclose, publish, keep secret, or otherwise exploit Feedback, without compensating or crediting Customer or the User in question. (“Feedback” refers to any suggestion or idea for improving or otherwise modifying any of Ematic’s products or services.)
    <br><br>

    7. CONFIDENTIAL INFORMATION. “Confidential Information” refers to the following items that one party to this Agreement (“Discloser”) discloses to the other party (“Recipient”): (a) any document marked as “Confidential”; (b) any information Discloser orally designates as “Confidential” at the time of disclosure, provided Discloser confirms such designation in writing within 5 business days; (c) the Documentation, whether or not marked or designated confidential; (d) any technology incorporated into or used by the Platform, as well as the Platform’s non-public features, for which Ematic will be Discloser; and (e) any other nonpublic, sensitive information disclosed by Discloser, whether or not marked or designated “Confidential.” Notwithstanding the foregoing, Confidential Information does not include information that: (i) is in Recipient’s possession at the time of disclosure; (ii) is independently developed by Recipient without use of or reference to Confidential Information; (iii) becomes known publicly, before or after disclosure, other than as a result of Recipient’s improper action or inaction; or (iv) is approved for release in writing by Discloser.
    <br><br>
    7.1 Nondisclosure. Recipient shall not use Confidential Information for any purpose other than to fulfill Recipient’s obligations or exercise its rights pursuant to this Agreement (the “Purpose”). Recipient: (a) shall not disclose Confidential Information to any employee or contractor of Recipient unless such person needs access in order to facilitate the Purpose and executes a nondisclosure agreement with Recipient with terms no less restrictive than those of this Article 7; and (b) shall not disclose Confidential Information to any other third party without Discloser’s prior written consent. Without limiting the generality of the foregoing, Recipient shall protect Confidential Information with the same degree of care it uses to protect its own confidential information of similar nature and importance, but with no less than reasonable care. Recipient shall promptly notify Discloser of any misuse or misappropriation of Confidential Information that comes to Recipient’s attention. Notwithstanding the foregoing, Recipient may disclose Confidential Information as required by applicable law or by proper legal or governmental authority. Recipient shall give Discloser prompt notice of any such legal or governmental demand and reasonably cooperate with Discloser in any effort to seek a protective order or otherwise to contest such required disclosure, at Discloser’s expense.
    <br>
    7.2 Injunction. Recipient agrees that breach of this Article 7 would cause Discloser irreparable injury, for which monetary damages would not provide adequate compensation, and that in addition to any other remedy, Discloser will be entitled to injunctive relief against such breach or threatened breach, without proving actual damage or posting a bond or other security.
    <br>
    7.3 Termination & Return. With respect to each item of Confidential Information, the obligations of Section 7.1 above (Nondisclosure) will terminate 2 years after the date of disclosure; provided that such obligations related to Confidential Information constituting Ematic’s trade secrets shall continue so long as such information remains subject to trade secret protection pursuant to applicable law. Upon termination of this Agreement, Recipient shall return all copies of Confidential Information to Discloser or certify, in writing, the destruction thereof.
    <br>
    7.4 Retention of Rights. This Agreement does not transfer ownership of Confidential Information or grant a license thereto. Discloser will retain all right, title, and interest in and to all Confidential Information.
    <br><br>

    8. REPRESENTATIONS & WARRANTIES. <br><br>
    8.1 From Ematic. Ematic represents and warrants that it is the owner of the Platform and of each and every component thereof, or the recipient of a valid license thereto, and that it has and will maintain the full power and authority to grant the rights granted in this Agreement without the further consent of any third party. Ematic’s representations and warranties in the preceding sentence do not apply to use of the Platform in combination with hardware or software not provided by Ematic. In the event of a breach of the warranty in this Section 8.1, Ematic, at its own expense, will promptly take the following actions: (a) secure for Customer the right to continue using the Platform; (b) replace or modify the Platform to make it noninfringing; or (c) terminate the infringing features of the Service and refund to Customer any prepaid fees for such features, in proportion to the portion of the Term left after such termination. In conjunction with Customer’s right to terminate for breach where applicable, the preceding sentence states Ematic’s sole obligation and liability, and Customer’s sole remedy, for breach of the warranty in this Section 8.1 and for potential or actual intellectual property infringement by the Platform.
    <br>
    8.2 From Customer. Customer represents and warrants that: (a) it has the full right and authority to enter into, execute, and perform its obligations under this Agreement and that no pending or threatened claim or litigation known to it would have a material adverse impact on its ability to perform as required by this Agreement; (b) it has accurately identified itself and it has not provided any inaccurate information about itself to or through the Platform; and (c) it is a company, the sole proprietorship of an individual 18 years or older, or another entity authorized to do business pursuant to applicable law.
    <br>
    8.3 Warranty Disclaimers. Except to the extent set forth in Section 8.1 above, CUSTOMER ACCEPTS THE PLATFORM “AS IS” AND AS AVAILABLE, WITH NO REPRESENTATION OR WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS, OR ANY IMPLIED WARRANTY ARISING FROM STATUTE, COURSE OF DEALING, COURSE OF PERFORMANCE, OR USAGE OF TRADE. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING: (a) EMATIC HAS NO OBLIGATION TO INDEMNIFY OR DEFEND CUSTOMER OR USERS AGAINST CLAIMS RELATED TO INFRINGEMENT OF INTELLECTUAL PROPERTY; (b) EMATIC DOES NOT REPRESENT OR WARRANT THAT THE PLATFORM WILL PERFORM WITHOUT INTERRUPTION OR ERROR; AND (c) EMATIC DOES NOT REPRESENT OR WARRANT THAT THE PLATFORM IS SECURE FROM HACKING OR OTHER UNAUTHORIZED INTRUSION OR THAT CUSTOMER DATA WILL REMAIN PRIVATE OR SECURE.
    <br><br>

    9. INDEMNIFICATION. Customer shall defend, indemnify, and hold harmless Ematic and the Ematic Associates (as defined below) against any “Indemnified Claim,” meaning any third party claim, suit, or proceeding arising out of or related to Customer's alleged or actual use of, misuse of, or failure to use the Platform, including without limitation: (a) claims by Users or by Customer's employees, as well as by Customer’s own customers; (b) claims related to unauthorized disclosure or exposure of personally identifiable information or other private information, including Customer Data; (c) claims related to infringement or violation of a copyright, trademark, trade secret, or privacy or confidentiality right by written material, images, logos or other content uploaded to the Platform through Customer’s account, including without limitation by Customer Data; and (d) claims that use of the Platform through Customer’s account harasses, defames, or defrauds a third party or violates anti-spamming laws or any other law or restriction on electronic advertising. Indemnified Claims include, without limitation, claims arising out of or related to Ematic’s negligence. Customer’s obligations set forth in this Article 9 include retention and payment of lawyers and payment of court costs, as well as settlement at Customer’s expense and payment of judgments. Ematic will have the right, not to be exercised unreasonably, to reject any settlement or compromise that requires that it admit wrongdoing or liability or subjects it to any ongoing affirmative obligations. (The “Ematic Associates” are Ematic’s officers, directors, shareholders, parents, subsidiaries, agents, successors, and assigns.)
    <br><br>

    10. LIMITATION OF LIABILITY.<br><br>

    10.1 Dollar Cap. EMATIC’S LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT WILL NOT EXCEED ALL AMOUNTS CUSTOMER PAID TO EMATIC IN THE 3 MONTHS PRECEDING THE CLAIM THAT GAVE RISE TO THE LIABILITY.
    <br>
    10.2 Exclusion of Consequential Damages. IN NO EVENT WILL EMATIC BE LIABLE TO CUSTOMER FOR ANY CONSEQUENTIAL, INDIRECT, SPECIAL, INCIDENTAL, OR PUNITIVE DAMAGES ARISING OUT OF OR RELATED TO THIS AGREEMENT.
    <br>
    10.3 Clarifications & Disclaimers. THE LIABILITIES LIMITED BY THIS ARTICLE 10 APPLY: (a) TO LIABILITY FOR NEGLIGENCE; (b) REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT, STRICT PRODUCT LIABILITY, OR OTHERWISE; (c) EVEN IF EMATIC IS ADVISED IN ADVANCE OF THE POSSIBILITY OF THE DAMAGES IN QUESTION AND EVEN IF SUCH DAMAGES WERE FORESEEABLE; AND (d) EVEN IF CUSTOMER’S REMEDIES FAIL OF THEIR ESSENTIAL PURPOSE. If applicable law limits the application of the provisions of this Article 10, Ematic’s liability will be limited to the maximum extent permissible. For the avoidance of doubt, Ematic’s liability limits and other rights set forth in this Article 10 apply likewise to Ematic’s affiliates, licensors, suppliers, advertisers, agents, sponsors, directors, officers, employees, consultants, and other representatives.
    <br><br>

    11. Term & Termination. <br><br>

    11.1 Term. This term of this Agreement (the “Term”) shall begin on the Effective Date and continue for any Billing Period designated in the Order, including any renewals, or if no Billing Period is designated in the Order, until terminated by one of the parties. If a Billing Period is designated in the Order, the Term will renew automatically on the last day of each Billing Period for an additional such period.
    <br>
    11.2 Termination for Cause. Ematic may terminate this Agreement (including without limitation Customer’s access to the Platform) without advanced notice if Customer fails to pay applicable fees when due. Either party may terminate this Agreement for any other material breach by the other party via written notice, effective in 30 days unless the other party first cures such breach.
    <br>
    11.3 Termination without Cause. Either party may terminate this Agreement without cause by providing notice of termination at least thirty (30) days prior to the end of the then-current term.
    <br>
    11.4 Effects of Termination. Upon termination of this Agreement, Customer shall cease all use of the Platform and delete, destroy, or return all copies of the Documentation in its possession or control. The following provisions will survive termination or expiration of this Agreement: (a) any obligation of Customer to pay fees incurred before termination; (b) Articles and Sections 6 (IP & Feedback), 7 (Confidential Information), 8.3 (Warranty Disclaimers), 9 (Indemnification), and 10 (Limitation of Liability); and (c) any other provision of this Agreement that must survive to fulfill its essential purpose.
    <br><br>

    12. MISCELLANEOUS. <br><br>

    12.1 Independent Contractors. The parties are independent contractors and will so represent themselves in all regards. Neither party is the agent of the other, and neither may make commitments on the other’s behalf. The parties agree that no Ematic employee or contractor will be an employee of Customer.
    <br>
    12.2 Notices. Ematic may send notices pursuant to this Agreement to Customer’s email contact points provided by Customer, and such notices will be deemed received 24 hours after they are sent. Customer may send notices pursuant to this Agreement to {{ supportTeamEmailDisplayText }}, and such notices will be deemed received 24 hours after they are sent.
    <br>
    12.3 Force Majeure. No delay, failure, or default, other than a failure to pay fees when due, will constitute a breach of this Agreement to the extent caused by acts of war, terrorism, hurricanes, earthquakes, other acts of God or of nature, strikes or other labor disputes, riots or other acts of civil disorder, embargoes, or other causes beyond the performing party’s reasonable control.
    <br>
    12.4 Assignment & Successors. Customer may not assign this Agreement or any of its rights or obligations hereunder without Ematic’s express written consent. Except to the extent forbidden in this Section 12.4, this Agreement will be binding upon and inure to the benefit of the parties’ respective successors and assigns.
    <br>
    12.5 Severability. To the extent permitted by applicable law, the parties hereby waive any provision of law that would render any clause of this Agreement invalid or otherwise unenforceable in any respect. In the event that a provision of this Agreement is held to be invalid or otherwise unenforceable, such provision will be interpreted to fulfill its intended purpose to the maximum extent permitted by applicable law, and the remaining provisions of this Agreement will continue in full force and effect.
    <br>
    12.6 No Waiver. Neither party will be deemed to have waived any of its rights under this Agreement by lapse of time or by any statement or representation other than by an authorized representative in an explicit written waiver. No waiver of a breach of this Agreement will constitute a waiver of any other breach of this Agreement.
    <br>
    12.7 Choice of Law & Jurisdiction: This Agreement will be governed solely by the laws of Singapore. Any claim, dispute or controversy of whatever nature arising out of or relating to this Agreement shall be referred to and finally resolved by arbitration in Singapore administered by the Singapore International Arbitration Centre (SIAC) in accordance with the Arbitration Rules of the Singapore International Arbitration Centre ("SIAC Rules") for the time being in force, which rules are deemed to be incorporated by reference in this clause.  This Section 12.7 governs all claims arising out of or related to this Agreement, including without limitation tort claims.
    <br>
    12.8 Conflicts. In the event of any conflict between this Agreement and any Ematic policy posted online, including without limitation the Privacy Policy, the terms of this Agreement will govern.
    <br>
    12.9 Construction. The parties agree that the terms of this Agreement result from negotiations between them. This Agreement will not be construed in favor of or against either party by reason of authorship.
    <br>
    12.10 Entire Agreement. This Agreement sets forth the entire agreement of the parties and supersedes all prior or contemporaneous writings, negotiations, and discussions with respect to its subject matter. Neither party has relied upon any such prior or contemporaneous communications.
    <br>
    12.11 Amendment. Ematic may amend this Agreement from time to time by posting an amended version at its Website and sending Customer written notice thereof. Such amendment will be deemed accepted and become effective 30 days after such notice (the “Proposed Amendment Date”) unless Customer first gives Ematic written notice of rejection of the amendment. In the event of such rejection, this Agreement will continue under its original provisions, and the amendment will become effective at the start of Customer’s next Term following the Proposed Amendment Date (unless Customer first terminates this Agreement pursuant to Article 11, Term & Termination). Customer’s continued use of the Service following the effective date of an amendment will confirm Customer’s consent thereto. This Agreement may not be amended in any other way except through a written agreement by authorized representatives of each party. Notwithstanding the foregoing provisions of this Section 12.11, Ematic may revise the Privacy Policy at any time by posting a new version of either at the Website, and such new version will become effective on the date it is posted.
    <br><br>
  </div>
</div>
