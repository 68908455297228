import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

import { AsyncApi } from '../../ematic-core-ui/api/async-api';
import { UIProject } from '../../ematic-core-ui/models/ui-project';
import { IContactCsParams } from '../../models/contact-cs';
import { IConnectEspClickedEvent, IMartechSuggestion } from '../../models/martech-customer-engagement';
import { PlatformService } from '../../ematic-core-ui/services/platform/platform.service';

@Injectable()
export class ContactService extends AsyncApi {

  constructor(
    router: Router,
    http: HttpClient,
    toastr: ToastrService,
    platformService: PlatformService) {
    super(http, router, toastr, '/contact', platformService, UIProject.PLATFORM_DASHBOARD);
  }

  contactCS(params: IContactCsParams): Observable<any> {
    return this.post(params, '/contact-cs');
  }

  async sendConnectEspClickedEvent(params: IConnectEspClickedEvent): Promise<void> {
    await this.postAsync(params, '/connect-esp-clicked');
  }

  async sendMarTechSuggestion(params: IMartechSuggestion): Promise<void> {
    await this.postAsync(params, '/suggest-martech');
  }

  async sendTalkToUsRequest(params): Promise<void> {
    await this.postAsync(params, '/talk-to-us');
  }
}

