import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { AuthService } from '../../../services/auth/auth.service';
import { GoogleAnalyticsService } from '../../../services/google/google-analytics.service';
import { LogoService } from '../../../services/logo/logo.service';
import { GenerateUrlService } from '../../../services/generate-url/generate-url.service';
import { PlatformService } from '../../../ematic-core-ui/services/platform/platform.service';
import { PlatformOriginId } from '../../../ematic-core-ui/models/platform';
import { IImage } from '../../../ematic-core-ui/models/image';
import { constants } from '../../../strings/constants';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {
  @ViewChild('createAccountForm') form: NgForm;

  email: string;
  loading: boolean;
  sent: boolean;
  loginState: string = constants.states.LOGIN;
  siteKey: string = environment.reCaptchaSiteKey;
  reCaptchaEnabled: boolean = environment.reCaptchaEnabled;
  captchaResponse = '';

  get platformName(): string {
    return this.platformService.platformName;
  }

  get platformOriginId(): PlatformOriginId {
    return this.platformService.platformOriginId;
  }

  get platformLogo(): IImage {
    return this.logoService.platformLogo('color');
  }

  get platformText(): string {
    return this.logoService.platformText;
  }

  get corporateUrl(): string {
    return this.generateUrlService.corporateUrl;
  }

  constructor(
    private authService: AuthService,
    private gaService: GoogleAnalyticsService,
    private logoService: LogoService,
    private generateUrlService: GenerateUrlService,
    private platformService: PlatformService) {
  }

  ngOnInit() {
    this.loading = false;
    this.sent = false;
    this.captchaResponse = this.reCaptchaEnabled ? '' : constants.notAvailable;
  }

  sendInvite() {
    if (this.form.form.invalid || this.form.form.get('email').invalid) {
      this.authService.toastr.error(constants.errors.missing_email, constants.errors.error);
    } else {
      this.loading = true;
      this.authService.post({ email: this.email, captchaResponse: this.captchaResponse, platformOriginId: this.platformOriginId }, '/onboard').subscribe({
        next: () => {
          this.loading = false;
          this.sent = true;
          this.gaService.sendEvent(constants.gaEvent.categories.submit, 'Get Started submitted',
            constants.gaEvent.labels.onboarding);
        },
        error: error => {
          this.authService.toastr.error(error.message || constants.errors.create_account_email,
            constants.errors.error);
          this.loading = false;
          this.sent = false;
        }
      });
    }
  }

  resetForm() {
    this.email = null;
    this.captchaResponse = null;
    this.sent = false;
    setTimeout(() => this.form.reset());
  }

  handleCaptcha(response: string) {
    this.captchaResponse = response || '';
  }
}
