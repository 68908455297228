import { Injectable } from '@angular/core';

import { ICompleteOAuthParams, IEspAuthParams } from '../../models/esp-params';
import { IntegrationsService } from '../integrations/integrations.service';
import { IEmailPerformanceSubmitData } from '../../models/esp-auth';
import { IMCListModel } from '../../models/mailchimp';
import { environment } from 'environments/environment';
import { constants } from '../../strings/constants';
import { PlatformService } from '../../ematic-core-ui/services/platform/platform.service';
import { PlatformOriginId } from '../../ematic-core-ui/models/platform';

@Injectable()
export class MailChimpService {
  private _oAuthCode = '';
  isModalOpened = false;
  lists: any = [];
  espKey: string;

  listModel: IMCListModel;

  get platformOriginId(): PlatformOriginId {
    return this.platformService.platformOriginId;
  }

  constructor(
    private integrationsService: IntegrationsService,
    private platformService: PlatformService) {
  }

  setOAuthCode(code: string): void {
    this._oAuthCode = code;
  }

  getOAuthCode(): string {
    const result = this._oAuthCode;
    this.removeOAuthCode();
    return result;
  }

  isOAuthInProgress(): boolean {
    return !!this._oAuthCode;
  }

  removeOAuthCode(): void {
    this._oAuthCode = '';
  }

  connect(): void {
    const mc = environment.mailchimp;
    const redirect = environment.redirectURI;
    window.location.href = mc.authorizeURI +
      `?response_type=code&client_id=${ mc.clientId }&redirect_uri=${ redirect }&state=mailchimp|${ this.platformOriginId }`;
  }

  getCompleteOAuthParams(code: string): ICompleteOAuthParams {
    this.espKey = null;
    const mc = environment.mailchimp;

    return <ICompleteOAuthParams>{
      espType: constants.esps.mailchimp.type,
      clientId: mc.clientId,
      redirectURI: environment.redirectURI,
      code
    };
  }

  getListsParams(oAuth: boolean): IEspAuthParams {
    return <IEspAuthParams>{
      espType: constants.esps.mailchimp.type,
      espKey: this.espKey,
      oAuth
    };
  }

  getSubmitOAuthParams(instanceId: string): IEmailPerformanceSubmitData {
    return this.integrationsService.getSubmitOAuthESPParams(
      instanceId,
      constants.esps.mailchimp.type,
      this.espKey,
      this.listModel.id,
      null,
      true,
      false);
  }
}
