import { Injectable } from '@angular/core';

import { IInstance } from '../../models/instance';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../user/user.service';
import { UtilService } from '../../util/util.service';
import { IUser } from '../../models/user';
import { constants } from '../../ematic-core-ui/strings/constants';

@Injectable()
export class InstanceSelectService {
  public instances: IInstance[];
  public currentInstanceIndex: number;
  public isInstanceLoading: boolean;
  user: IUser;

  constructor(private authService: AuthService,
    private userService: UserService,
    private utilService: UtilService
  ) {
    this.currentInstanceIndex = 0;
    this.isInstanceLoading = false;
    this.user = this.authService.getUser().user;

    if (this.user) {
      this.updateCurrentInstanceIndex();
    }
  }

  public get documentWidth(): number {
    const rect = document.body.getBoundingClientRect();
    return rect.width;
  }

  isMobile() {
    return this.documentWidth < constants.MEDIA_POINTS.TABLET_BREAKPOINT;
  }

  async updateUser(selectedInstanceId: string) {
    if (UserService.isCustomer(this.user)) {
      const updatedUser = await this.userService.putAsync({ id: selectedInstanceId }, '/instance');
      await this.authService.updateUser(user => {
        user.state.dashboard.currentInstanceId = updatedUser.state.dashboard.currentInstanceId;
        user.state.dashboard.currentInstance = user.customer.accessRights
          .find(ar => ar.instance._id.toString() === updatedUser.state.dashboard.currentInstanceId);
        user.gaStatus = updatedUser.gaStatus;
        user.allInstancesActive = false;
      });
    } else {
      await this.authService.updateUser(user => {
        user.state.dashboard.admin.instance.currentInstance = user.state.dashboard.admin.instance.instances
          .find(inst => inst._id.toString() === selectedInstanceId);
        user.state.dashboard.admin.instance.currentInstanceId = user.state.dashboard.admin.instance.currentInstance._id.toString();
        user.allInstancesActive = false;
      });
    }
  }

  updateInstances() {
    this.user = this.authService.getUser().user;
    if (this.user.customer) {
      this.instances = this.user.customer.accessRights.map(permission => permission.instance);
    } else {
      this.instances = this.user.state.dashboard.admin.instance.instances;
    }
  }

  updateCurrentInstanceIndex() {
    this.updateInstances();
    const instanceId = this.utilService.getCurrentInstance(this.user)._id;
    this.currentInstanceIndex = this.instances.findIndex(instance => instance._id === instanceId);
  }

  onInstanceLoading(isLoading: boolean) {
    this.isInstanceLoading = isLoading;
  }
}
