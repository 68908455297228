<app-external>
  <ng-container content-container>
    <a class="logo-link" [attr.href]="corporateUrl" target="_blank">
      <img class="ematic-logo"
           [srcset]="platformLogo.imageDpr1 + ' 1x, ' + platformLogo.imageDpr2 + ' 2x'"
           [alt]="platformText">
    </a>
    <form #form="ngForm" class="form">
      <h4 class="title-external">Set new password</h4>
      <div class="input-field">
        <input
          type="password"
          [(ngModel)]="newPassword"
          name="newPasswordControl"
          placeholder="New Password *"
          #newPasswordControl="ngModel"
          passwordValidator
          validateEqual="confirmPasswordControl"
          reverse="true"
          autocomplete="off"
          required/>
        <small [hidden]="newPasswordControl.valid || (newPasswordControl.pristine && !form.submitted)"
               class="text-danger">
          Password must contain lower case, upper case, number and minimum 8 characters.
        </small>
      </div>

      <div class="input-field">
        <input
          type="password"
          [(ngModel)]="confirmPassword"
          name="confirmPasswordControl"
          placeholder="Confirm New Password *"
          #confirmPasswordControl="ngModel"
          validateEqual="newPasswordControl"
          (keyup.enter)="onResetPassword()"
          autocomplete="off"
          required/>
        <small [hidden]="confirmPasswordControl.valid || (confirmPasswordControl.pristine && !form.submitted)"
               class="text-danger">
          Password mismatch
        </small>
      </div>
      <re-captcha
        *ngIf="reCaptchaEnabled"
        (resolved)="handleCaptcha($event)"
        size="compact"
        class="recaptcha-mobile"
        [siteKey]="siteKey">
      </re-captcha>
      <re-captcha
        *ngIf="reCaptchaEnabled"
        (resolved)="handleCaptcha($event)"
        size="normal"
        class="recaptcha-desktop"
        [siteKey]="siteKey">
      </re-captcha>
      <es-button
        class="send btn-block"
        text="Submit"
        [loading]="loading"
        [disabled]="!form.form.valid || !form.form.dirty || loading || !captchaResponse"
        [isRounded]="true"
        (clicked)="onResetPassword()">
      </es-button>
      <p class="text-center">Return to <a routerLink="{{loginState}}" routerLinkActive="active">Sign in</a>.</p>
    </form>
  </ng-container>
</app-external>
