<app-external class="register-form">
  <ng-container content-container>
    <div class="auth-container">
      <div class="local-loader" *ngIf="tokenLoading">
        <es-loader [isRelative]="false"></es-loader>
      </div>
      <div *ngIf="!tokenLoading" [@fadeIn]="true">
        <div class="auth-form">
          <a class="logo-link" [attr.href]="corporateUrl" target="_blank">
            <img class="ematic-logo"
                [srcset]="platformLogo.imageDpr1 + ' 1x, ' + platformLogo.imageDpr2 + ' 2x'"
                [alt]="platformText">
          </a>

          <div class="content-header">
            <span class="content-title">
              Complete Registration
            </span>
            <p class="content-subtitle">
              Enter your data to complete registration.</p>
          </div>

          <form #form="ngForm" class="form">
            <div class="row">
              <div class="half">
                <input type="text"
                      [(ngModel)]="user.firstName"
                      placeholder="First Name *"
                      autocomplete="first-name"
                      name="firstName"
                      required/>
              </div>
              <div class="half">
                <input type="text"
                      [(ngModel)]="user.lastName"
                      placeholder="Last Name *"
                      autocomplete="last-name"
                      name="lastName"
                      required/>
              </div>

              <div class="half">
                <div class="input-field">
                  <input type="password"
                        [(ngModel)]="user.password"
                        placeholder="Password *"
                        autocomplete="new-password"
                        name="password"
                        #password="ngModel"
                        passwordValidator
                        validateEqual="confirmPassword"
                        reverse="true"
                        required/>
                  <small [hidden]="password.valid || (password.pristine && !form.submitted)" class="text-danger">
                    {{ passwordInvalidMessage }}
                  </small>
                </div>
              </div>
              <div class="half">
                <div class="input-field">
                  <input type="password"
                        [(ngModel)]="user.confirm"
                        placeholder="Confirm Password *"
                        autocomplete="new-password"
                        name="confirmPassword"
                        #confirmPassword="ngModel"
                        validateEqual="password"
                        required/>
                  <small [hidden]="confirmPassword.valid || (confirmPassword.pristine && !form.submitted)" class="text-danger">
                    Password mismatch
                  </small>
                </div>
              </div>

              <div class="full" *ngIf="onboarding">
                <input type="text" class="last"
                      [(ngModel)]="company"
                      placeholder="Company Name *"
                      name="company"
                      required/>
              </div>

              <div class="full form-control">
                <label class="form-label">Timezone *</label>
                <es-lookup
                  placeholder="Select timezone"
                  name="selectedTimezone"
                  [isRounded]="true"
                  [query]="onTimezonesQuery"
                  [(ngModel)]="timezone"
                  required>
                </es-lookup>
              </div>

              <div class="full form-control" *ngIf="onboarding">
                <label class="form-label">Country code *</label>
                <es-lookup
                  placeholder="Select country"
                  name="country"
                  [displayWith]="countryDisplayWith"
                  [isRounded]="true"
                  [query]="onCountriesQuery"
                  [(ngModel)]="country"
                  displayField="name"
                  required>
                </es-lookup>
              </div>

              <div class="full" *ngIf="onboarding">
                <input type="text" class="bottom"
                      [(ngModel)]="phone"
                      placeholder="Phone Number"
                      name="phone"
                      maxlength="16"
                      esOnlyNumber
                      [ignoreDecimalSeparator]="true"/>
              </div>

              <div class="full">
                <div class="terms-checkbox">
                  <label class="c-checkbox">
                    <input type="checkbox" [(ngModel)]="terms" name="terms"/>I agree to
                    <div class="c_indicator"></div>
                  </label>
                  <a (click)="termsModal.open()">Terms and Conditions</a>
                </div>
              </div>

              <div class="full">
                <es-button
                  [type]="'primary'"
                  class="btn-block"
                  text="Complete Registration"
                  [loading]="loading"
                  [isRounded]="true"
                  [disabled]="!form.form.valid || !form.form.dirty || !terms"
                  (clicked)="register()">
                </es-button>
              </div>
            </div>

            <p class="corporate-link">Already using {{ platformName }}?
              <a class="sign-up-link" routerLink="{{loginState}}">Login here</a>
            </p>
          </form>
        </div>
        <es-modal
          #termsModal
          titleText="Web Site Terms and Conditions of Use">
          <div content class="terms-modal">
            <terms-and-conditions></terms-and-conditions>
          </div>
          <div footer>
            <es-button
              [type]="'cancel'"
              [text]="'Close'"
              [isRounded]="true"
              (clicked)="termsModal.close()">
            </es-button>
          </div>
        </es-modal>
        <div class="content-bg"></div>
      </div>
    </div>
  </ng-container>
</app-external>
